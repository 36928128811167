export const CARGO_OPEN_POLICY_ROUTES_DATA_LIST_CHANGE = "CARGO_OPEN_POLICY_ROUTES_DATA_LIST_CHANGE";
export const CARGO_OPEN_POLICY_ROUTES_DATA_VALIDATE_ERROR = "CARGO_OPEN_POLICY_ROUTES_DATA_VALIDATE_ERROR";

export const validateOpenPolicyRoutesDataErrorMessage = (error) => {
  return {
    type: CARGO_OPEN_POLICY_ROUTES_DATA_VALIDATE_ERROR,
    error: error
  }
};

export const changeCargoOpenPolicyRoutesDataEntity = (act, config) => {
  return {
    type: CARGO_OPEN_POLICY_ROUTES_DATA_LIST_CHANGE,
    act: act,
    config: config
  }
};