export const CARGO_OPEN_POLICY_HISTORIC_CHANGE = "CARGO_OPEN_POLICY_HISTORIC_CHANGE";
export const CARGO_OPEN_POLICY_HISTORIC_CUSTOM_CHANGE = "CARGO_OPEN_POLICY_HISTORIC_CUSTOM_CHANGE";
export const CARGO_OPEN_POLICY_HISTORIC_VALIDATE_ERROR = "CARGO_OPEN_POLICY_HISTORIC_VALIDATE_ERROR";

export const validateOpenPolicyHistoricErrorMessage = (error) => {
  return {
    type: CARGO_OPEN_POLICY_HISTORIC_VALIDATE_ERROR,
    error: error
  }
};

export const changeCargoOpenPolicyHistoric = (attr, value) => {
  return {
    type: CARGO_OPEN_POLICY_HISTORIC_CHANGE,
    attr: attr,
    value: value
  }
};

export const changeCargoOpenPolicyHistoricCustom = (act, config) => {
  return {
    type: CARGO_OPEN_POLICY_HISTORIC_CUSTOM_CHANGE,
    act: act,
    config: config
  }
};