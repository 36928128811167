//Libs
import produce from "immer"
//Action Types
import {
  INITIALIZE_OPEN_POLICY_DATA,
  CARGO_OPEN_POLICY_UPDATE_API,
  CARGO_OPEN_POLICY_GENERAL_DATA_CHANGE, 
  CARGO_OPEN_POLICY_GENERAL_DATA_VALIDATE_ERROR,
} from "../../../actions";
//Custom helpers
import { actionTypes, formatActionType, refreshState } from "../../helpers";
//Constants
const { SUCCESS, ERROR } = actionTypes;

const initialState = {
  updatingPartially: false,
  errors: {},
  data: {
    modals: [],
    companyActivity: null,
    averageAmountPerBoarding: null,
    maximumAmountPerBoarding: null,
    monthlyShipmentQuantity: null,
  }
};
//Reducer Function
const GeneralData = produce((draft, action) => {
  switch (action.type) {
    case INITIALIZE_OPEN_POLICY_DATA:
    case formatActionType(CARGO_OPEN_POLICY_UPDATE_API, SUCCESS): {
      refreshState("general", draft, action.data)
      break;
    }

    case formatActionType(CARGO_OPEN_POLICY_UPDATE_API, ERROR): {
      draft.errors = action.errors;
      break;
    }

    case CARGO_OPEN_POLICY_GENERAL_DATA_VALIDATE_ERROR: {
      delete draft.errors[action.error];
      break;
    }

    case CARGO_OPEN_POLICY_GENERAL_DATA_CHANGE:
      const {attr, value} = action;
      draft.data[attr] = value;
      break;
    default:
      return;
  }
}, initialState);

export default GeneralData;
