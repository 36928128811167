//Helpers
import { formatDocInput } from "helpers";
//Constants
import { documentTypes } from "constants/index";

export const openPolicyGeneralData = data => {
    return {
        averageAmountPerBoarding: data.averageAmountPerBoarding ? Number(data.averageAmountPerBoarding) : null,
        maximumAmountPerBoarding: data.maximumAmountPerBoarding ? Number(data.maximumAmountPerBoarding) : null,
        monthlyShipmentQuantity: data.monthlyShipmentQuantity ? Number(data.monthlyShipmentQuantity) : null,
        companyActivity: data.companyActivity,
        modals: data.modals
    };
}

export const openPolicyRoutesData = (data, notEmpty) => {
    const { routes, modals } = data;
    let result = [];

    routes.map((route) => {
        if(notEmpty && (!route.origin || !route.destination || !route.frequency)){
            return null;
        }
        
        if (modals.length === 1) {
            result.push(Object.assign({}, route, { modal: modals[0] }));
        } else {
            result.push(route);
        }
    });

    return {
        routes: result || null
    };
}

export const openPolicyCoveragesData = data => {
    return {
        accident: true,
        damage: data.damage,
        theft: data.theft,
        documentNumber: data.documentNumber ? formatDocInput(data.documentNumber,documentTypes.COMPANY) : '',
        lifeInsurance: data.lifeInsurance,
        fluvialRoute:data.fluvialRoute,
        environmentalLiabilityRisk: data.environmentalLiabilityRisk,
        other: data.other,
        otherDescription: data.otherDescription || '',
        trackCleaning: data.trackCleaning,
        loadingUnloading:data.loadingUnloading,
        loadingUnloadingLifting:data.loadingUnloadingLifting,
        freight:data.freight,
        type: 'COMPANY'
    }
}

export const operationalData = data => {
    return data;
}

export const historicData = data => {
    return data;
}

export const openPolicyWsRequest = values => {
    const { code, data, type } = values;
    let filteredData = data;
    let result;
    if(type === 'routes'){
        filteredData = openPolicyRoutesData(data, true)
        if(filteredData){
            result = {
                code: code,
                [type]: filteredData
            }
        }
    } else if(type){
        result = {
            code: code,
            [type]: data
        }
    }

    return result;
}