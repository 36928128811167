import { dateApiFormat } from "components/AppConfig/index";
import moment from "moment";
//Helpers
import { formatDocInput, formatMaskInput, normalizeCurrency } from "helpers";

export const termData = data => {
    const {
        type,
        contractAmount,
        processAmount,
        suretyAmount,
        finalTermAt,
        initialTermAt,
        suretyPercentage,
        daysBetweenTerm,
        contractType,
        labourCoverage,
        penaltyCoverage,
        liabilityInsurance,
        engineeringRiskInsurance,
        groupLifeInsurance,
        lawsuitType,
        appealType
    } = data;
    const formattedData = {
        type: type,
        suretyAmount: Number(normalizeCurrency(suretyAmount)),
        finalTermAt: finalTermAt ? moment(finalTermAt).format(dateApiFormat) : '',
        initialTermAt: initialTermAt ? moment(initialTermAt).format(dateApiFormat) : '',
        daysBetweenTerm: daysBetweenTerm ? Number(daysBetweenTerm) : null
    }
    switch (type) {
        case 'LABOUR_APPEAL_FILLING_BOND':
            formattedData.processAmount = Number(normalizeCurrency(processAmount))
            formattedData.depositAppeal = true
            formattedData.lawsuitType = lawsuitType || "Ação trabalhista";
            formattedData.appealType = appealType;
            break;
        case 'CIVIL_COURT_BOND':
        case 'TAX_CREDITS_BOND':
        case 'TAX_ENFORCEMENT_BOND':
        case 'COURT_OTHERS_BOND':
        case 'LABOUR_SURETY':
            formattedData.processAmount = Number(normalizeCurrency(processAmount));
            break;
        case 'GENERAL_BOND':
        case 'CONSTRUCTION_BOND':
        case 'BUSINESS_SERVICES_BOND':
        case 'SUPPLY_BOND':
        case 'WORK_COMPLETION_BOND':
        case 'OIL_GAS_SECTOR_BOND':
        case 'CORRECTIVE_MAINTENANCE_BOND':
        case 'DEALER_PERFORMER_BOND':
        case 'ENERGY_SECTOR_BOND':
        case 'MAINTENANCE_BOND':
        case 'REAL_ESTATE_BOND':
        case 'NAVY_SECTOR_BOND':
        case 'CUSTOMS_BOND':
            formattedData.contractType = contractType;
            formattedData.contractAmount = contractAmount ? Number(normalizeCurrency(contractAmount)) : null;
            formattedData.suretyPercentage = suretyPercentage ? Number(suretyPercentage) : null;
            formattedData.labourCoverage = labourCoverage;
            formattedData.penaltyCoverage = penaltyCoverage;
            formattedData.liabilityInsurance = liabilityInsurance;
            formattedData.engineeringRiskInsurance = engineeringRiskInsurance
            formattedData.groupLifeInsurance = groupLifeInsurance
            break;
        case 'BIDDING_BOND':
        case "PAYMENT_RETENTION_BOND":
            formattedData.contractType = contractType;
            formattedData.contractAmount = contractAmount ? Number(normalizeCurrency(contractAmount)) : null;
            formattedData.suretyPercentage = suretyPercentage ? Number(suretyPercentage) : null;
            break;
        case 'ADVANCED_PAYMENT_BOND':
            formattedData.contractType = contractType;
            formattedData.contractAmount = contractAmount ? Number(normalizeCurrency(contractAmount)) : null;
            formattedData.suretyPercentage = suretyPercentage ? Number(suretyPercentage) : null;
            formattedData.groupLifeInsurance = groupLifeInsurance
            break;
        default:
            break;
    }

    return formattedData
}

export const suretyDetailsData = data => {
    const {
        type,
        additionalData,
        receiveDraftContract,
        courtProceedingPlace,
        processNumber,
        contactName,
        contactType,
        contactDocumentNumber,
        contractDate,
        contractNumber,        
        obligation,
        installment,
        bidDate,
        bidNumber,
        lawsuitType,
        courtProceedingCity,
        courtProceedingState,
        court,
        tribunal
    } = data;
    const formattedData = {
        type: type,
        additionalData: additionalData,
        receiveDraftContract: receiveDraftContract
    }
    switch (type) {
        case 'LABOUR_APPEAL_FILLING_BOND':
        case 'CIVIL_COURT_BOND':
        case 'TAX_CREDITS_BOND':
        case 'TAX_ENFORCEMENT_BOND':
        case 'LABOUR_SURETY':
        case 'COURT_OTHERS_BOND':
            formattedData.court = court
            formattedData.tribunal = tribunal
            formattedData.courtProceedingPlace = courtProceedingPlace;
            formattedData.courtProceedingCity = courtProceedingCity;
            formattedData.courtProceedingState = courtProceedingState;
            formattedData.processNumber = formatMaskInput(processNumber);
            formattedData.contactName = contactName;
            formattedData.contactType = contactType;
            formattedData.contactDocumentNumber = formatDocInput(contactDocumentNumber, contactType);
            formattedData.lawsuitType = lawsuitType;
            break;
        case 'GENERAL_BOND':
        case 'CONSTRUCTION_BOND':
        case 'BUSINESS_SERVICES_BOND':
        case 'SUPPLY_BOND':
        case 'WORK_COMPLETION_BOND':
        case 'OIL_GAS_SECTOR_BOND':
        case 'CORRECTIVE_MAINTENANCE_BOND':
        case 'DEALER_PERFORMER_BOND':
        case 'ENERGY_SECTOR_BOND':
        case 'MAINTENANCE_BOND':
        case 'REAL_ESTATE_BOND':
        case 'NAVY_SECTOR_BOND':
        case 'CUSTOMS_BOND':
            formattedData.contractDate = contractDate ? moment(contractDate).format(dateApiFormat) : null;
            formattedData.contractNumber = contractNumber;
            formattedData.obligation = obligation;
            break;
        case 'ADVANCED_PAYMENT_BOND':
        case 'PAYMENT_RETENTION_BOND':
            formattedData.contractNumber = contractNumber;
            formattedData.obligation = obligation;
            formattedData.installment = installment;
            break;
        case 'BIDDING_BOND':
            formattedData.obligation = obligation;
            formattedData.bidNumber = bidNumber;
            formattedData.bidDate = bidDate ? moment(bidDate).format(dateApiFormat) : null;
            break;
        default:
            break;

    }
    return formattedData;
}

export const minuteData = data => {
    const { proposalAccepted } = data;

    return {
        proposalAccepted: proposalAccepted
    };
}

export const paymentData = data => {
    const { installment } = data;

    return {
        installment: installment
    };
}

export const suretyWsRequest = data => {
    const { code, productData, documentNumber } = data;
    let initialData, generalData, normalizedDocumentNumber;
    initialData = productData.initial.data;
    generalData = productData.term.data;
    normalizedDocumentNumber = documentNumber
        ? formatMaskInput(documentNumber)
        : initialData.documentNumber
            ? formatMaskInput(initialData.documentNumber)
            : null;
    if (
        normalizedDocumentNumber &&
        generalData.type &&
        generalData.suretyAmount &&
        generalData.daysBetweenTerm
    ) {
        return {
            code: code,
            documentNumber: normalizedDocumentNumber,
            bond: generalData.type,
            amount: generalData.suretyAmount,
            duration: Number(generalData.daysBetweenTerm)
        };
    } else {
        return null;
    }
}