import React from "react";
//Components
import { Form, Tooltip } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import './styles.less'
const FormField = (props) => {
  const { error, label, children, htmlFor, tooltip } = props;
  const hasError = Boolean(error);
  return (
    <Form.Item
      hasFeedback={hasError}
      validateStatus={hasError ? "error" : null}
      help={error}
      htmlFor={htmlFor ? htmlFor : children.props ? children.props.id : null}
      className={`formField ${!label && tooltip && "tooltip-labelless"}`}
      label={
        label ? (
          <span>
            {label}
            {tooltip ? (
              <Tooltip title={tooltip}>
                <FontAwesomeIcon icon={["far", "question-circle"]} />
              </Tooltip>
            ) : null}
          </span>
        ) : (
          tooltip && (
            <span className="tooltip-labelless">
              <Tooltip title={tooltip}>
                <FontAwesomeIcon icon={["far", "question-circle"]} />
              </Tooltip>
            </span>
          )
        )
      }
    >
      {children}
    </Form.Item>
  );
};

export default FormField;
