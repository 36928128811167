//Redux Bindings
import {combineReducers} from 'redux'
//Reducers
import initialData from "./initialData";
import general from "./general";
import obligee from "./obligee";
import quotations from "./quotations";
import details from "./details";
import minute from "./minute";
import hasMinute from "./hasMinute";
import payment from "./payment";
import final from "./final";

//Merge Reducers
export default combineReducers({
  initial: initialData,
  term: general,
  type: general,
  obligee: obligee,
  quotation: quotations,
  detail: details,
  minute: minute,
  hasMinute: hasMinute,
  payment: payment,
  final: final
})
