export const CARGO_OPEN_POLICY_INITIAL_DATA_CHANGE = "CARGO_OPEN_POLICY_INITIAL_DATA_CHANGE";
export const CARGO_OPEN_POLICY_INITIAL_DATA_VALIDATE_ERROR = "CARGO_OPEN_POLICY_INITIAL_DATA_VALIDATE_ERROR";
export const CARGO_OPEN_POLICY_INITIAL_DATA_LOCAL_VALIDATION_ERROR = "CARGO_OPEN_POLICY_INITIAL_DATA_LOCAL_VALIDATION_ERROR";

export const changeCargoOpenPolicyInitialData = (attr, value) => {
  return {
    type: CARGO_OPEN_POLICY_INITIAL_DATA_CHANGE,
    attr: attr,
    value: value
  }
};

export const validateOpenPolicyInitialDataErrorMessage = (error) => {
  return {
    type: CARGO_OPEN_POLICY_INITIAL_DATA_VALIDATE_ERROR,
    error: error
  }
};

export const localValidationOpenPolicyInitialDataErrorMessage = (errors) => {
  return {
    type: CARGO_OPEN_POLICY_INITIAL_DATA_LOCAL_VALIDATION_ERROR,
    errors: errors
  }
};