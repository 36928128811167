//Libs
import produce from "immer"
//Action Types
import {
  INITIALIZE_LIFE_DATA,
  LIFE_UPDATE_API,
  LIFE_INSURED_LIVES_CHANGE,
  LIFE_INSURED_LIVES_VALIDATE_ERROR,
  LIFE_INSURED_LIVES_LOCAL_VALIDATION_ERROR,
} from "../../../actions";
//Custom helpers
import { actionTypes, formatActionType, refreshState } from "../../helpers";
//Constants
const { SUCCESS, ERROR } = actionTypes;

const initialState = {
  updatingPartially: false,
  errors: {},
  files: null,
  data: {
    additionalInformation: null,
    insuredLives: []
  }
};

//Reducer Function
const InitialData = produce((draft, action) => {
  switch (action.type) {
    case INITIALIZE_LIFE_DATA:
    case formatActionType(LIFE_UPDATE_API, SUCCESS): {
      refreshState("insured", draft, action.data)
      if(action.data.files)
        draft.files = action.data.files
      break;
    }

    case LIFE_INSURED_LIVES_VALIDATE_ERROR: {
      delete draft.errors[action.error];
      break;
    }

    case formatActionType(LIFE_UPDATE_API, ERROR):
    case LIFE_INSURED_LIVES_LOCAL_VALIDATION_ERROR: {
      draft.errors = action.errors;
      break;
    }

    case LIFE_INSURED_LIVES_CHANGE: {
      const { attr, value } = action;
      draft.data[attr] = value;
      break;
    }

    default:
      return;
  }
}, initialState);

export default InitialData;
