//Utils
import asyncComponent from "util/asyncComponent";
//Constants
import { publicPaths, privatePaths } from "constants/index"
import { openPolicyWsRequest } from "helpers/requestsFormatter";
//RequestsFormatter
import {
	initialData, 
	insuredData,
	openPolicyGeneralData,
	openPolicyRoutesData,
	openPolicyCoveragesData,
	operationalData,
	historicData,
	goodsData
} from "helpers/requestsFormatter";

export const openPolicy = {
	routes: [
	  {
	    exact: true,
	    title: "Seus dados",
	    apiIdentifier: "initial",
	    urlIdentifier: "initial-data",
	    initialPath: publicPaths.openPolicy + privatePaths.initial,
	    path: publicPaths.openPolicy + privatePaths.initial + ":id",
        socket: {
          initialize: true,
        },
	    formatResponse:  (data) => initialData(data),
	    component: asyncComponent(() => import('containers/Products/cargoOpenPolicy/InitialData'))
	  },
	  {
	    exact: true,
	    title: "Dados gerais",
	    apiIdentifier: "general",
	    urlIdentifier: "general-data",
	    initialPath: publicPaths.openPolicy + privatePaths.general,
	    path: publicPaths.openPolicy + privatePaths.general + ":id",
        socket: {
          send: true,
        },
	    formatResponse: (data) => openPolicyGeneralData(data),
	    component: asyncComponent(() => import('containers/Products/cargoOpenPolicy/GeneralData'))
	  },
	  {
	    exact: true,
	    title: "Coberturas",
	    apiIdentifier: "coverages",
	    urlIdentifier: "coverages-data",
	    initialPath: publicPaths.openPolicy + privatePaths.coverages,
	    path: publicPaths.openPolicy + privatePaths.coverages + ":id",
        socket: {
          send: true,
        },
	    formatResponse: (data) => openPolicyCoveragesData(data),
	    component: asyncComponent(() => import('containers/Products/cargoOpenPolicy/Coverages'))
	  },	  {
	    exact: true,
	    title: "Rotas percorridas",
	    apiIdentifier: "routes",
	    urlIdentifier: "routes-data",
	    initialPath: publicPaths.openPolicy + privatePaths.routes,
	    path: publicPaths.openPolicy + privatePaths.routes + ":id",
        socket: {
          send: true,
        },
	    formatResponse: (data) => openPolicyRoutesData(data),
	    component: asyncComponent(() => import('containers/Products/cargoOpenPolicy/RoutesData'))
	  },
	  {
	    exact: true,
	    title: "Dados da mercadoria",
	    apiIdentifier: "goods",
	    urlIdentifier: "goods-data",
	    formatResponse: (data) => goodsData(data),
	    initialPath: publicPaths.openPolicy + privatePaths.goods,
	    path: publicPaths.openPolicy + privatePaths.goods + ":id",
	    component: asyncComponent(() => import('containers/Products/cargoOpenPolicy/GoodsData'))
	  },
	  {
	    exact: true,
	    title: "Cotações",
	    apiIdentifier: "quotations",
	    urlIdentifier: "quotations-data",
	    formatResponse: (data) => data,
	    initialPath: publicPaths.openPolicy + privatePaths.quotation,
	    path: publicPaths.openPolicy + privatePaths.quotation + ":id",
	    component: asyncComponent(() => import('containers/Products/cargoOpenPolicy/Quotations'))
	  },
	  {
	    exact: true,
	    title: "Dados operacionais",
	    apiIdentifier: "operational",
	    urlIdentifier: "operational-data",
	    initialPath: publicPaths.openPolicy + privatePaths.operational,
	    path: publicPaths.openPolicy + privatePaths.operational + ":id",
	    formatResponse: (data) => operationalData(data),
	    component: asyncComponent(() => import('containers/Products/cargoOpenPolicy/Operational'))
	  },
	  {
	    exact: true,
	    title: "Histórico da empresa",
	    apiIdentifier: "historic",
	    urlIdentifier: "historic-data",
	    initialPath: publicPaths.openPolicy + privatePaths.historic,
	    path: publicPaths.openPolicy + privatePaths.historic + ":id",
	    formatResponse: (data) => historicData(data),
	    component: asyncComponent(() => import('containers/Products/cargoOpenPolicy/Historic'))
	  },
	  {
	    exact: true,
	    title: "Dados do segurado",
	    apiIdentifier: "insured",
	    urlIdentifier: "insured-data",
	    formatResponse: (data) => insuredData(data),
	    initialPath: publicPaths.openPolicy + privatePaths.insured,
	    path: publicPaths.openPolicy + privatePaths.insured + ":id",
	    component: asyncComponent(() => import('containers/Products/cargoOpenPolicy/InsuredData'))
	  },
	  {
	    exact: true,
	    title: "Final",
	    apiIdentifier: "final",
	    urlIdentifier: "final",
	    initialPath: publicPaths.openPolicy + privatePaths.final,
	    path: publicPaths.openPolicy + privatePaths.final + ":id",
	    component: asyncComponent(() => import('containers/Products/cargoOpenPolicy/FinalMessage'))
	  }
	],
	config: {
	  product: "cargo",
	  productId: 'seguro-de-carga/multiplo',
	  type: "OPEN_POLICY",
      socket: {
        url: "/cargo/ws",
        publishUrl: "/app/v1/open-policy",
        topics: ["/user/queue/simulation", "/user/queue/error"],
        formatPayload: (code, data, type) => openPolicyWsRequest({code, data, type})
      },
	  getUrl: function (identifier, code) {
	    return `cargo/v1/open-policy/${code}/${identifier}`;
	  }
	}
}