export const SURETY_DETAILS_CHANGE = "SURETY_DETAILS_CHANGE";
export const SURETY_DETAILS_VALIDATE_ERROR = "SURETY_DETAILS_VALIDATE_ERROR";

export const changeSuretyDetails = (attr, value) => {
  return {
    type: SURETY_DETAILS_CHANGE,
    attr: attr,
    value: value
  }
};

export const validateSuretyDetailsErrorMessage = (error) => {
  return {
    type: SURETY_DETAILS_VALIDATE_ERROR,
    error: error
  }
};
