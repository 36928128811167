import React, { Suspense } from "react";
//Redux Lib
import { ConnectedRouter } from "connected-react-router";
import { Provider } from "react-redux";
//Router Lib
import { BrowserRouter, Route, Switch } from "react-router-dom";
//Bindings
import configureStore, { history } from "./appRedux/store";
//Styles
// import "assets/vendors/style";
// import "styles/wieldy.less";
//Containers
import App from "./containers/App/index";
//Constants
import Startup from "./appRedux/startup";

// new routes
import { renderRoutes, routes } from "./routes/newRoutes";
import UTMAnalytics from "./components/UTMAnalytics";
import newReduxStore from "./store";

export const store = configureStore();

const NextApp = () => (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <BrowserRouter>
        <>
          <Provider store={newReduxStore}>
            <UTMAnalytics />
          </Provider>
          <Suspense fallback={null}>
            <Switch>
              {/* vamos colocar aqui uma nova estrutura de rotas para poder separar o novo layout do antigo layout */}

              {renderRoutes(routes)}

              {/* ----------------------------------------------------------------------------------------------- */}
              <Route path="/" component={App} />
            </Switch>
          </Suspense>
        </>
      </BrowserRouter>
    </ConnectedRouter>
  </Provider>
);

Startup(store)
  .then(() => NextApp())
  .catch((error) => console.error(error));

export default NextApp;
