import React from "react";
import ReactDOM from "react-dom";
import 'fontawesome';

import NextApp from './NextApp';
// import registerServiceWorker from './registerServiceWorker';
// Add this import:
import {AppContainer} from 'react-hot-loader';

// new translation
import './translation/i18n';

import * as Sentry from '@sentry/browser';
import config from './config';
import TagManager from 'react-gtm-module'

Sentry.init({dsn: "https://c431ed06ae5b46b3855377847eecc9df@o408156.ingest.sentry.io/5278559"});

Sentry.configureScope(function(scope) {
  scope.setTag("environment", config.environment);
});

const tagManagerArgs = {
  gtmId: 'GTM-WX2D8J8',
}

TagManager.initialize(tagManagerArgs)

// Wrap the rendering in a function:
const render = Component => {
  ReactDOM.render(
    // Wrap App inside AppContainer
    <AppContainer>
      <NextApp/>
    </AppContainer>,
    document.getElementById('root')
  );
};

// Render once
render(NextApp);

// Webpack Hot Module Replacement API
if (module.hot) {
  module.hot.accept('./NextApp', () => {
    render(NextApp);
  });
}
