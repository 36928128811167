export const CARGO_SINGLE_BOARDING_GOODS_CHANGE = "CARGO_SINGLE_BOARDING_GOODS_CHANGE";
export const CARGO_SINGLE_BOARDING_GOODS_VALIDATE_ERROR = "CARGO_SINGLE_BOARDING_GOODS_VALIDATE_ERROR";

export const changeCargoSingleBoardingGoodsEntity = (act, config) => {
  return {
    type: CARGO_SINGLE_BOARDING_GOODS_CHANGE,
    act: act,
    config: config
  }
};

export const validateSingleBoardingGoodsErrorMessage = (error) => {
  return {
    type: CARGO_SINGLE_BOARDING_GOODS_VALIDATE_ERROR,
    error: error
  }
};