//Libs
import produce from "immer"
//Action Types
import {
  INITIALIZE_OPEN_POLICY_DATA,
  CARGO_OPEN_POLICY_UPDATE_API,
  CARGO_OPEN_POLICY_COVERAGES_CHANGE, 
  CARGO_OPEN_POLICY_COVERAGES_VALIDATE_ERROR,
  // CARGO_OPEN_POLICY_UPDATE_API_PARTIALLY,
  CARGO_OPEN_POLICY_COVERAGES_LOCAL_VALIDATION_ERROR
} from "../../../actions";
//Custom helpers
import { actionTypes, formatActionType, refreshState } from "../../helpers";
//Constants
const { SUCCESS, ERROR } = actionTypes;

const initialState = {
  updatingPartially: false,
  errors: {},
  data: {
    accident: true,
    damage: false,
    lifeInsurance: false,
    other: false,
    otherDescription: null,
    theft: false,
    trackCleaning: false,
    loadingUnloading:false,
    loadingUnloadingLifting:false,
    fluvialRoute:false,
    freight:false,
    type: 'COMPANY'
  }
};
//Reducer Function
const GeneralData = produce((draft, action) => {
  switch (action.type) {
    case INITIALIZE_OPEN_POLICY_DATA:
    case formatActionType(CARGO_OPEN_POLICY_UPDATE_API, SUCCESS): {
      refreshState("coverage", draft, action.data)
      break;
    }

    case formatActionType(CARGO_OPEN_POLICY_UPDATE_API, ERROR): {
      draft.errors = action.errors;
      break;
    }

    case CARGO_OPEN_POLICY_COVERAGES_VALIDATE_ERROR: {
      delete draft.errors[action.error];
      break;
    }

    case CARGO_OPEN_POLICY_COVERAGES_CHANGE:
      const {attr, value} = action;
      draft.data[attr] = value;
      break;

    case CARGO_OPEN_POLICY_COVERAGES_LOCAL_VALIDATION_ERROR:
      draft.errors = action.errors;
      break;
      
    default:
      return;
  }
}, initialState);

export default GeneralData;
