//Libs
import produce from "immer"
//Action Types
import {
  INITIALIZE_OPEN_POLICY_DATA,
  CARGO_OPEN_POLICY_UPDATE_API,
  CARGO_OPEN_POLICY_OPERATIONAL_CHANGE, 
  CARGO_OPEN_POLICY_OPERATIONAL_VALIDATE_ERROR,
  CARGO_OPEN_POLICY_OPERATIONAL_CUSTOM_CHANGE,
} from "../../../actions";
//Custom helpers
import { actionTypes, formatActionType, refreshState } from "../../helpers";
//Constants
const { SUCCESS, ERROR } = actionTypes;

const initialState = {
  updatingPartially: false,
  errors: {},
  data: {
    exclusivityCompanies: null,
    fleets: {
      OWN: null,
      THIRD_PARTY: null,
      INDEPENDENT: null
    },
    hasDDR: false,
    hasExclusivity: false,
    logistic: "DISTRIBUTION",
    oneResponsibleForStorage: false,
    registeredDrivers: false,
    riskManagement: [],
    riskManagementCompanies: null,
    shipper: null,
    trackings: [],
    transportDocuments: []
  }
};
//Reducer Function
const GeneralData = produce((draft, action) => {
  switch (action.type) {
    case INITIALIZE_OPEN_POLICY_DATA:
    case formatActionType(CARGO_OPEN_POLICY_UPDATE_API, SUCCESS): {
      refreshState("operational", draft, action.data)
      break;
    }

    case formatActionType(CARGO_OPEN_POLICY_UPDATE_API, ERROR): {
      draft.errors = action.errors;
      break;
    }

    case CARGO_OPEN_POLICY_OPERATIONAL_VALIDATE_ERROR: {
      delete draft.errors[action.error];
      break;
    }

    case CARGO_OPEN_POLICY_OPERATIONAL_CHANGE: {
      const {attr, value} = action;
      draft.data[attr] = value;
      break;
    }

    case CARGO_OPEN_POLICY_OPERATIONAL_CUSTOM_CHANGE: {
      const { act, config } = action;
      const { type, value, attr } = config;
      switch (act) {
        case "EDIT": {
          draft.data.fleets[type][attr] = value;
          break;
        }
        case "CREATE": {
          draft.data.fleets[type] = {};
          break;
        }
        case "DELETE": {
          draft.data.fleets[type] = null;
          break;
        }
        // no default
      }
      break;
    }
      
    default:
      return;
  }
}, initialState);

export default GeneralData;
