import React, { useState, useEffect } from 'react';
//redux
import { useSelector } from 'react-redux';

import { Button, Modal, Input, Row, Col } from "antd";
import emailMask from "text-mask-addons/dist/emailMask";
import FormField from "components/FormField";
import Request from "util/Request";
import {
  extractUrlConfig,
  getIdentifierByPath,
  getProductNameByPath,
  productsInfo
} from "helpers";
import "./style.less";

const exitPopupConfig = {
  'surety': {
    initialSteps: [
      'initial',
      'type',
      'term',
      'quotation',
      'insured',
      'details',
    ],
    initial: `Já vai? Ainda precisamos de alguns dados para apresentar as cotações das melhores seguradoras!`,
    final: `Já vai? Falta muito pouco para concluir seu pedido de cotação!`
  },
  'life': {
    initialSteps: [
      'initial',
      'general',
      'resume',
      'coverages',
      'details',
    ],
    initial: `Já vai? Ainda precisamos de alguns dados para apresentar as cotações das melhores seguradoras!`,
    final: `Já vai? Falta muito pouco para concluir seu pedido de cotação!`
  },
  'cargoSingleBoarding': {
    initialSteps: [
      'initial',
      'general',
      'resume',
      'goods',
      'insured',
      'shipping',
    ],
    initial: `Já vai? Ainda precisamos de alguns dados para apresentar as cotações das melhores seguradoras!`,
    final: `Já vai? Falta muito pouco para concluir seu pedido de cotação!`
  },
  'cargoOpenPolicy': {
    initialSteps: [
      'initial',
      'general',
      'routes',
      'coverages',
      'insured',
      'goods',
    ],
    initial: `Já vai? Ainda precisamos de alguns dados para apresentar as cotações das melhores seguradoras!`,
    final: `Já vai? Falta muito pouco para concluir seu pedido de cotação!`
  },
}

export const ExitPopup = () => {

  const wasDisplayed = sessionStorage.getItem(`exitPopup-${product}`);
  const [visible, setVisible] = useState(false);
  const [displayed, setDisplayed] = useState(wasDisplayed);
  const url = extractUrlConfig();
  const product = getProductNameByPath(url.product);
  const step = getIdentifierByPath(url.identifier);
  const body = document.querySelector('body');

  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState(null)
  let crmIntegrationUrl;

  const { products } = useSelector((state) => state);

  let initialEmail = '';
  if (products[productsInfo[product]]) {
    initialEmail = products[productsInfo[product]].initial
      ? products[productsInfo[product]].initial.data.email
      : "";
  }

  useEffect(() => {
    setEmail(initialEmail);
  }, [initialEmail])

  if(product){
    const isInitialStep = exitPopupConfig[product].initialSteps.includes(step);
    if (!isInitialStep) {
      return null;
    }

    if(product === 'surety'){
      crmIntegrationUrl = `/surety/v2/${url.code}/email`
    }else if(product === 'life'){
      crmIntegrationUrl = `/life/v1/${url.code}/email`
    }else if(product === 'cargoSingleBoarding'){
      crmIntegrationUrl = `/cargo/v1/single-boarding/${url.code}/email`
    }else if(product === 'cargoOpenPolicy'){
      crmIntegrationUrl = `/cargo/v1/open-policy/${url.code}/email`
    }
  }else{
    return null;
  }

  const showDrawer = (event) => {
    const wasDisplayed = sessionStorage.getItem(`exitPopup-${product}-${step}`);
    if (product && wasDisplayed == null && event.clientY < 50) {
      setVisible(true);
      sessionStorage.setItem(`exitPopup-${product}-${step}`, 'true');
      body.removeEventListener("mouseout", showDrawer);
    }
  };

  const onClose = () => {
    setVisible(false);
  };

  const getMsg = (product, step) => {
    if (!exitPopupConfig[product]) {
      return null
    }
    const isInitial = exitPopupConfig[product].initialSteps.includes(step);
    const msg = isInitial ? 'initial' : 'final';
    return exitPopupConfig[product][msg];
  }

  body.addEventListener("mouseout", evt => {
    if (evt.toElement === null && evt.relatedTarget === null) {
      showDrawer(evt);
    }
  });

  const handlerChange = e => {
    setEmail(e.target.value)
    setEmailError(null)
  }

  const saveEmail = () => {
    Request.put(crmIntegrationUrl, {email: email})
      .catch(error => {
        console.log(error)
        setEmailError('Houve um problema, tente novamente.')
        return {};
      });
    setVisible(false)
    return;
  }

  const popupContent = () => {
    if (step === 'initial'){
      return(
        <>
          <p className="gx-m-0 gx-title">Vai sair sem pedir seu seguro?</p>
          <p className="gx-mb-0 gx-subtitle">Nosso melhor especialista entrará em contato na sequência para te ajudar agora</p>
          <p className="gx-mb-0 gx-mt-1">Basta deixar seu melhor e-mail para contato</p>
          <div className="exitpopup-form">
            <Row type="flex" justify="space-between">
              <Col xs={24} md={15} className="gx-p-0">
                <FormField
                    onTouch
                    error={emailError}
                >
                  <Input
                    value={email}
                    onChange={e => handlerChange(e)}
                    placeholder="Informe seu e-mail aqui"
                    mask={emailMask}
                  />
                </FormField>
              </Col>
              <Col xs={24} md={8} className="gx-p-0">
                <Button onClick={saveEmail} className="gx-mr-0" type="primary">Aguardar contato</Button>
              </Col>
            </Row>
            <div className="exit-popup-link" onClick={onClose}>Não preciso de ajuda</div>
          </div>
        </>
      )
    }

    else if (product === 'cargoOpenPolicy' || product === 'life') {
      return(
        <>
          <p className="gx-m-0 gx-title">Espere um pouquinho... Você sabia?</p>
          <p className="gx-mb-2 gx-subtitle cargo-subtitle">A cada passo completado nossa Inteligência Artificial consegue melhorar a sua cotação</p>
          <Row type="flex" justify="space-between">
            <Button onClick={onClose} className="gx-mr-0" type="primary">Quero o melhor preço</Button>
            <Button onClick={onClose} className="gx-mr-0">Não gosto de desconto</Button>
          </Row>
        </>
      )
    }

    else {
      return(
        <>
          <p className="gx-m-0 gx-title">Espere um pouquinho...</p>
          <p className="gx-subtitle surety-subtitle">Preenchendo mais um passo, sua cotação de seguro chega muito mais rápido</p>
          <Row type="flex" justify="space-between">
            <Button onClick={onClose} type="primary">Continuar o preenchimento</Button>
            <Button onClick={onClose} >Voltar depois</Button>
          </Row>
        </>
      )
    }
  }

  return (
    <Modal 
      className="mu-modal-confirm gx-text-left" 
      visible={visible} 
      onCancel={onClose}
      icon={false}
      footer={null}
      closable={false}
    >
      <div className="exitPopup">
        {popupContent()}
      </div>
    </Modal>
  )
}