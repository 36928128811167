import { fork, put, call, takeLatest, all } from "redux-saga/effects";
//Action Types
import {
  PREPARE_ORDER,
  initializeOrder,
  initializeSingleBoardingData,
  initializeOpenPolicyData,
  initializeSuretyData,
  initializeLifeData,
} from "../../actions";
import { connectWebSocket } from "../../actions/websocket";
//Helpers
import { extractUrlConfig, getProductPathByName } from "helpers";
import { fetchData } from "./helpers";

export function* prepareOrderConfigurations() {
  yield takeLatest(PREPARE_ORDER, function* prepareOrder() {
    try {
      const browserUrl = window.location.href;
      const { product, code } = extractUrlConfig(browserUrl);
      const data = yield call(fetchData, { code, product });
      // If we're getting back what we want
      if (data && !data.error) {
        if (
          product === getProductPathByName("surety") ||
          getProductPathByName("cargoOpenPolicy")
        ) {
          yield put(connectWebSocket(product));
        }
        yield put(initializeOrder(code, product, data));
        switch (product) {
          case getProductPathByName("cargoSingleBoarding"):
            return yield put(initializeSingleBoardingData(data));
          case getProductPathByName("cargoOpenPolicy"):
            return yield put(initializeOpenPolicyData(data));
          case getProductPathByName("surety"):
            return yield put(initializeSuretyData(data));
          case getProductPathByName("life"):
            return yield put(initializeLifeData(data));
          default:
            return null;
        }
      } else {
        // Dispatch the 'ERROR' action to the store
        // to tell the user something went wrong
      }
    } catch (error) {
      console.log("Error", error);
    }
  });
}

export default function* rootSaga() {
  yield all([fork(prepareOrderConfigurations)]);
}
