import moment from "moment";
import i18n from "../translation/i18n";

export const phoneTypes = {
	MOBILE_PHONE_NUMBER: "MOBILE_PHONE_NUMBER",
	WHATSAPP_PHONE_NUMBER: "WHATSAPP_PHONE_NUMBER",
	PHONE_NUMBER: "PHONE_NUMBER"
};

export const documentTypes = {
	INDIVIDUAL: "INDIVIDUAL",
	COMPANY: "COMPANY",
};

export const publicPaths = {
	surety: "/seguro-garantia",
	cargo: "/seguro-de-carga",
	life: "/seguro-de-vida",
	type: "/tipo-seguro",
	others: "/outros-seguros",
	singleBoarding: "/seguro-de-carga/unico",
	openPolicy: "/seguro-de-carga/multiplo",
	suretyTerms: "/seguro-garantia/termos",
}

export const privatePaths = {
	initial: "/dados-iniciais/",
	type: "/tipo-seguro/",
	term: "/dados-garantia/",
	quotation: "/cotacao/",
	insured: "/dados-segurado/",
	details: "/detalhes/",
	final: "/final/",
	general: "/dados-gerais/",
	routes: "/rotas/",
	coverages: "/coberturas/",
	goods: "/dados-mercadoria/",
	resume: "/estimativa/",
	shipping: "/dados-embarque/",
	operational: "/dados-operacao/",
	historic: "/historico/",
	lives: "/relacao-vidas/",
	customer: "/dados-estipulante/",
	minute: "/minuta/",
	payment: "/forma-pagamento/",
}

export const modals = [
	{
		key: "ROAD_MODAL",
		value: "Rodoviário"
	},
	{
		key: "MARINE_MODAL",
		value: "Marítimo"
	},
	{
		key: "AIR_MODAL",
		value: "Aéreo"
	},
	{
		key: "RAIL_MODAL",
		value: "Ferroviário"
	}
]

export const modalSimulationType = {
	NATIONAL_CARRYING_ROAD: "Transportador nacional rodoviário",
	NATIONAL_CARRYING_RAIL: "Transportador nacional ferroviário",
	NATIONAL_CARRYING_AIR: "Transportador nacional aéreo",
	NATIONAL_CARRYING_MARINE: "Transportador nacional marítimo",
	INTERNATIONAL_CARRYING_ROAD: "Transportador internacional rodoviário",
	INTERNATIONAL_CARRYING_RAIL: "Transportador internacional ferroviário",
	INTERNATIONAL_CARRYING_AIR: "Transportador internacional aéreo",
	INTERNATIONAL_CARRYING_MARINE: "Transportador internacional marítimo",
	OWN_NATIONAL: "Dono da carga nacional",
	OWN_INTERNATIONAL: "Dono da carga internacional"
}

export const modalLabels = {
	NATIONALROAD: "Nacional rodoviário",
	NATIONALRAIL: "Nacional ferroviário",
	NATIONALAIR: "Nacional aéreo",
	NATIONALMARINE: "Nacional marítimo",
	INTERNATIONALROAD: "Internacional rodoviário",
	INTERNATIONALRAIL: "Internacional ferroviário",
	INTERNATIONALAIR: "Internacional aéreo",
	INTERNATIONALMARINE: "Internacional marítimo",
}

export const courtTypes = [
	'CIVIL_COURT_BOND',
	'LABOUR_APPEAL_FILLING_BOND',
	'TAX_CREDITS_BOND',
	'TAX_ENFORCEMENT_BOND',
	'COURT_OTHERS_BOND',
	'LABOUR_SURETY'
]

export const coverageItems =
{
	ACCIDENT: "Acidente (Cobertura básica obrigatória)",
	THEFT: "Roubo",
	DAMAGE: "Avaria",
	TRACK_CLEANING: "Limpeza de Pista",
	ENVIRONMENTAL_LIABILITY_RISK: "Responsabilidade Civil Ambiental",
	LOADING_UNLOADING: "Carga e Descarga",
	LOADING_UNLOADING_LIFTING: "Carga, Descarga e Içamento",
	FREIGHT: "Frete",
	LIFE_INSURANCE: "Seguro de vida para os motoristas",
	FLUVIAL_ROUTE: "Percurso Fluvial",
	OTHER: "Outros"
}

export const currencyOptions = {
	USD: "Dólar",
	EUR: "Euro",
	BRA: "Real"
}

export const insuredTypes = {
	OWN_MERCHANDISE: "OWN_MERCHANDISE",
	THIRDY_PARTY_MERCHANDISE: "THIRDY_PARTY_MERCHANDISE"
}

export const coverageStatus = {
	NOT_SELECTED: "Não solicitado",
	UPON_REQUEST: "Sob consulta",
	NOT_AVAILABLE: "Em análise",
	AVAILABLE: "Disponível",
	UNOBTAINABLE: "Não possui"
}
export const openingHours = {
	startTime: moment('09:00:00 GMT-0300', 'HH:mm ZZ'),
	endTime: moment('18:00:00 GMT-0300', 'HH:mm ZZ')
}

export const companyActivitiesList = {
	SHIPPING_COMPANY : { label: "Transportadora", owner: "THIRDY_PARTY_MERCHANDISE" },
	LOADING_AGENT : { label: "Agente de carga", owner: "THIRDY_PARTY_MERCHANDISE" },
	AGRIBUSINESS: { label: "Agronegócio", owner: "OWN_MERCHANDISE" },
	TRUCK_DRIVER : { label: "Caminhoneiro", owner: "THIRDY_PARTY_MERCHANDISE" },
	COMMERCE : { label: "Comércio", owner: "OWN_MERCHANDISE" },
	FORWARDING : { label: "Despachante aduaneiro", owner: "OWN_MERCHANDISE" },
	SHIPPER : { label: "Embarcadora", owner: "OWN_MERCHANDISE" },
	EXPORTER : { label: "Exportadora", owner: "OWN_MERCHANDISE" },
	IMPORTER : { label: "Importadora", owner: "OWN_MERCHANDISE" },
	INDUSTRY : { label: "Indústria", owner: "OWN_MERCHANDISE" },
	OPERATOR : { label: "Operador logístico", owner: "THIRDY_PARTY_MERCHANDISE" },
	TRADING : { label: "Trading", owner: "OWN_MERCHANDISE" },
	OTHERS : { label: "Outros", owner: "OWN_MERCHANDISE" }
}

export const closingDays = [
	'Saturday',
	'Sunday'
]

export const phoneNumbers = [
	'3003.8387'
]

export const valueByAppealType = {
	INTERLOCUTORY_APPEAL_REVIEW: 24592.76,
	INTERLOCUTORY_APPEAL_ORDINARY:  12296.38,
	EMBARGOES: 24592.76,
	APPEAL: 24592.76,
	RESCISORY_APPEAL: 24592.76,
	EXTRAORDINARY_APPEAL: 21973.60,
	ORDINARY_APPEAL: 12296.38,
}

export const simulatorCompanyActivity = [
	{
		value: "CARRIER",
		label: i18n.t("simulator.simulatorForm.companyActivity.transport"),
	},
	{
		value: "SHIPPER",
		label: i18n.t("simulator.simulatorForm.companyActivity.boarding"),
	}
];

export const simulatorTransportType = [
	{
		value: "NATIONAL",
		label: i18n.t("simulator.simulatorForm.transportType.national"),
	},
	{
		value: "INTERNATIONAL",
		label: i18n.t("simulator.simulatorForm.transportType.international"),
		disabled: true
	},
	{
		value: "BOTH",
		label: i18n.t("simulator.simulatorForm.transportType.both"),
		disabled: true
	}
];

export const CNPJMASK = [
	/[0-9]/,
	/\d/,
	".",
	/\d/,
	/\d/,
	/\d/,
	".",
	/\d/,
	/\d/,
	/\d/,
	"/",
	/\d/,
	/\d/,
	/\d/,
	/\d/,
	"-",
	/\d/,
	/\d/,
];

export const PHONEMASK = [
  "(",
  /\d/,
  /\d/,
  ")",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
  /\d/,
  /\d/
];

export const policyTypes = [
	{
		value: "RCTR_C",
		label: i18n.t("simulator.simulationCard.coverageRCTRCLabel")
	},
	{
		value: "RCF_DC",
		label: i18n.t("simulator.simulationCard.coverageRCFDCLabel")
	},
	{
		value: "ROAD_NATIONAL",
		label: i18n.t("simulator.simulationCard.coverageTNLabel")
	},
	{
		value: "RCTR_VI",
		label: i18n.t("simulator.simulationCard.coverageRCTRVILabel")
	}
]
