export const INITIALIZE_WEBSOCKET_CHANNEL = "INITIALIZE_WEBSOCKET_CHANNEL";
export const CONNECT_WEBSOCKET_CHANNEL = "CONNECT_WEBSOCKET_CHANNEL";
export const CONNECT_WEBSOCKET_SUCCESS_CHANNEL = "CONNECT_WEBSOCKET_SUCCESS_CHANNEL";
export const CONNECT_WEBSOCKET_ERROR_CHANNEL = "CONNECT_WEBSOCKET_ERROR_CHANNEL";
export const RETRIEVE_WEBSOCKET_MESSAGES = "RETRIEVE_WEBSOCKET_MESSAGES";
export const SEND_WEBSOCKET_MESSAGES = "SEND_WEBSOCKET_MESSAGES";
export const STOP_WEBSOCKET_CHANNEL = "STOP_WEBSOCKET_CHANNEL";

export const initializeWebSocket = (product) => {
  return {
    type: INITIALIZE_WEBSOCKET_CHANNEL,
    product: product
  }
};

export const connectWebSocket = (product) => {
  return {
    type: CONNECT_WEBSOCKET_CHANNEL,
    product: product,
  }
};

export const websocketStarted = (product) => {
  return {
    type: CONNECT_WEBSOCKET_SUCCESS_CHANNEL,
    product: product
  }
};

export const websocketFailed = (product, error) => {
  return {
    type: CONNECT_WEBSOCKET_ERROR_CHANNEL,
    product: product,
    error: error
  }
};

export const retrieveWebSocketMessages = (product, data) => {
  return {
    type: RETRIEVE_WEBSOCKET_MESSAGES,
    product: product,
    data: data
  }
};

export const sendWebSockedMessages = (product, data) => {
  return {
    type: SEND_WEBSOCKET_MESSAGES,
    product: product,
    data: data
  }
};

export const disconnectWebSocket = (product) => {
  return {
    type: STOP_WEBSOCKET_CHANNEL,
    product: product,
  }
};
