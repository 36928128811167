//Redux Bindings
import {combineReducers} from "redux";
import {connectRouter} from "connected-react-router";
//Reducers
import Settings from "./Settings";
import Order from './order'
import Sidebar from './sidebar'
import Products from './products';
import Websocket from './websocket';
import {history} from '../history'

const reducers = combineReducers({
  router: connectRouter(history),
  settings: Settings,
  products: Products,
  order: Order,
  sidebar: Sidebar,
  websocket: Websocket,
});
export default reducers;
