import Request from "util/Request";
import { getProductPathByName, codeValidate } from "helpers";

export const fetchData = ({code, product}) => {
  const path = () => {
    switch (product) {
      case getProductPathByName('life'):
        return "life/v1/"
      case getProductPathByName('cargoSingleBoarding'):
        return "cargo/v1/single-boarding/"
      case getProductPathByName('cargoOpenPolicy'):
        return "cargo/v1/open-policy/"
      case getProductPathByName('surety'):
        return "surety/v2/"
      default:
        return null
    }
  };
  if (path() && codeValidate(code)) {
    return Request.get(`${path()}${code}`)
      .then(response => {
        if (response.status === 200) return response.data;
        return {};
      })
      .catch(error => {
        console.log("GET RES ERROR :", error);
        return {};
      });
  } else {
    return null
  }
};
