//Redux Bindings
import {combineReducers} from 'redux'
//Reducers
import initialData from "./initialData";
import generalData from "./generalData";
import routesData from "./routesData";
import coverages from "./coverages";
import insured from "./insured";
import goods from "./goods";
import quotations from "./quotations";
import final from "./final";
import operational from "./operational";
import historic from "./historic";

//Merge Reducers
export default combineReducers({
  initial: initialData,
  general: generalData,
  routes: routesData,
  coverages: coverages,
  insured: insured,
  goods: goods,
  quotations: quotations,
  operational: operational,
  historic: historic,
  final: final
})
