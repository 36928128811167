import {all} from "redux-saga/effects";
import productsSaga from './Products';
import orderSagas from "./Order";
import websocketSagas from './WebSocket';

export default function* rootSaga(getState) {
  yield all([
    orderSagas(),
    websocketSagas(),
    productsSaga()
  ]);
}
