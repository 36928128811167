import {applyMiddleware, compose, createStore} from "redux";
import reducers from "../reducers/index";
import {routerMiddleware} from "connected-react-router";
import {history} from '../history'
import createSagaMiddleware from "redux-saga";
import {loaderMiddleware, apiMiddleware, webSocketMiddleware} from "../middlewares";
import {createPromise} from 'redux-promise-middleware';
import rootSaga from "../sagas/index";


const routeMiddleware = routerMiddleware(history);
const sagaMiddleware = createSagaMiddleware();
const promise = createPromise({
  promiseTypeSuffixes: ['LOADING', 'SUCCESS', 'ERROR']
});

//MiddleWares
const middlewares = [
  sagaMiddleware,
  routeMiddleware,
  promise,
  loaderMiddleware,
  apiMiddleware,
  webSocketMiddleware
];

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default function configureStore(initialState) {
  const store = createStore(reducers, initialState,
    composeEnhancers(applyMiddleware(...middlewares)));
  sagaMiddleware.run(rootSaga);
  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('../reducers/index', () => {
      const nextRootReducer = require('../reducers/index');
      store.replaceReducer(nextRootReducer);
    });
  }
  return store;
}
export {history};
