export const CARGO_SINGLE_BOARDING_RESUME_CHANGE = "CARGO_SINGLE_BOARDING_RESUME_CHANGE";
export const CARGO_SINGLE_BOARDING_RESUME_VALIDATE_ERROR = "CARGO_SINGLE_BOARDING_RESUME_VALIDATE_ERROR";
export const CARGO_SINGLE_BOARDING_RESUME_LOCAL_VALIDATION_ERROR = "CARGO_SINGLE_BOARDING_RESUME_LOCAL_VALIDATION_ERROR";

export const changeCargoSingleBoardingResume = (attr, value) => {
  return {
    type: CARGO_SINGLE_BOARDING_RESUME_CHANGE,
    attr: attr,
    value: value
  }
};

export const validateSingleBoardingResumeErrorMessage = (error) => {
  return {
    type: CARGO_SINGLE_BOARDING_RESUME_VALIDATE_ERROR,
    error: error
  }
};

export const localValidationSingleBoardingResumeErrorMessage = (errors) => {
  return {
    type: CARGO_SINGLE_BOARDING_RESUME_LOCAL_VALIDATION_ERROR,
    errors: errors
  }
};