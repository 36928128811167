import { library } from '@fortawesome/fontawesome-svg-core';

import { 
  faClock,
  faQuestionCircle,
  faCheckCircle,
  faEnvelope 
} from '@fortawesome/free-regular-svg-icons';
import { 
  faChevronLeft, 
  faCaretUp, 
  faCaretDown, 
  faUserTie, 
  faChevronRight, 
  faSyncAlt, 
  faSearch, 
  faStar, 
  faTimes, 
  faTrashAlt, 
  faPlus,
  faPen,
  faArrowCircleDown,
  faFileDownload,
  faSearchPlus,
  faCheck,
  faBolt,
  faSearchMinus,
  faPhone,
  faSpinner } from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'

library.add(
  faClock,
  faWhatsapp,
  faCaretUp,
  faCaretDown,
  faUserTie,
  faQuestionCircle,
  faChevronLeft, 
  faChevronRight,
  faSyncAlt, 
  faSearch,
  faCheckCircle,
	faEnvelope,
	faStar,
  faTimes,
  faTrashAlt,
  faSpinner,
	faPlus,
  faPen,
  faFileDownload,
  faSearchPlus,
  faCheck,
  faBolt,
  faSearchMinus,
	faArrowCircleDown,
  faPhone
);