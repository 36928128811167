//Libs
import produce from "immer"
//Action Types
import {
  INITIALIZE_SURETY_DATA,
  SURETY_UPDATE_API,
  SURETY_GENERAL_DATA_CHANGE,
  SURETY_GENERAL_DATA_VALIDATE_ERROR,
  // SURETY_UPDATE_API_PARTIALLY,
} from "../../../actions";
//Custom helpers
import { actionTypes, formatActionType, refreshState } from "../../helpers";
//Constants
const { SUCCESS, ERROR } = actionTypes;

const initialState = {
  updatingPartially: false,
  errors: {},
  others:{
    bondName: null,
  },
  data: {
    type: null,
    contractType: 'PUBLIC_CONTRACT',
    suretyAmount: null,
    initialTermAt: '',
    finalTermAt: '',
    daysBetweenTerm: null,
  }
};

//Reducer Function
const GeneralData = produce((draft, action) => {
  switch (action.type) {
    case INITIALIZE_SURETY_DATA:
    case formatActionType(SURETY_UPDATE_API, SUCCESS): {
      refreshState("term", draft, action.data)
      if (action.data.hasOwnProperty("bond"))
        draft.others.bondName = action.data.bond.name;
      break;
    }

    case formatActionType(SURETY_UPDATE_API, ERROR): {
      draft.errors = action.errors;
      break;
    }


    case SURETY_GENERAL_DATA_VALIDATE_ERROR: {
      delete draft.errors[action.error];
      break;
    }

    case SURETY_GENERAL_DATA_CHANGE:
      const { attr, value } = action;
      draft.data[attr] = value;
      break;
    default:
      return;
  }
}, initialState);

export default GeneralData;
