export const LIFE_DETAILS_CHANGE = "LIFE_DETAILS_CHANGE";
export const LIFE_DETAILS_VALIDATE_ERROR = "LIFE_DETAILS_VALIDATE_ERROR";
export const LIFE_DETAILS_LIST_CHANGE = "LIFE_DETAILS_LIST_CHANGE";

export const validateLifeDetailsErrorMessage = (error) => {
  return {
    type: LIFE_DETAILS_VALIDATE_ERROR,
    error: error
  }
};

export const changeLifeDetails = (attr, value) => {
  return {
    type: LIFE_DETAILS_CHANGE,
    attr: attr,
    value: value
  }
};

export const changeLifeDetailsEntity = (act, config) => {
  return {
    type: LIFE_DETAILS_LIST_CHANGE,
    act: act,
    config: config
  }
};