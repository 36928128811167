import { createSlice } from "@reduxjs/toolkit";
import { setLoading } from "../common";
import axios from "../../../util/Request";
import i18n from "../../../translation/i18n";
import { message } from "antd";

export const lifeSlice = createSlice({
  name: "life",
  initialState: {
    code: "",
  },
  reducers: {
    setCode: (state, action) => {
      state.code = action.payload;
    },
  },
});

export const { setCode } = lifeSlice.actions;

export default lifeSlice.reducer;

// -----------------------------------------------------------

export const generateLifeCode = (body) => (dispatch) => {
  dispatch(setLoading(true));

  return new Promise((resolve, reject) => {
    axios
      .post(`/life/v1`, {
        partner: null,
        type: "LIFE",
        ...body
      })
      .then((response) => {
        dispatch(setCode(response.data && response.data.code));
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  });
};
