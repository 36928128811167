export const LIFE_RESUME_CHANGE = "LIFE_RESUME_CHANGE";
export const LIFE_RESUME_VALIDATE_ERROR = "LIFE_RESUME_VALIDATE_ERROR";
export const LIFE_RESUME_LOCAL_VALIDATION_ERROR = "LIFE_RESUME_LOCAL_VALIDATION_ERROR";

export const validateLifeResumeErrorMessage = (error) => {
  return {
    type: LIFE_RESUME_VALIDATE_ERROR,
    error: error
  }
};

export const changeLifeResume = (attr, value) => {
  return {
    type: LIFE_RESUME_CHANGE,
    attr: attr,
    value: value
  }
};

export const localValidationLifeResumeErrorMessage = (errors) => {
  return {
    type: LIFE_RESUME_LOCAL_VALIDATION_ERROR,
    errors: errors
  }
};