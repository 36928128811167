//Libs
import produce from "immer"
//WebSocket Action Types
import {
  CONNECT_WEBSOCKET_ERROR_CHANNEL,
  STOP_WEBSOCKET_CHANNEL,
  CONNECT_WEBSOCKET_SUCCESS_CHANNEL,
  RETRIEVE_WEBSOCKET_MESSAGES,
} from "../../actions/websocket";

const initialState = {
  'seguro-de-carga/multiplo':{
    error: null,
    data: null
  },
  'seguro-garantia':{
    error: null,
    data: []
  }

};

function initializeWebsocket() {
  return {
    error: null,
    data: []
  }
}

const Websocket = produce((draft, action) => {
  const {product, error, data} = action;

  switch (action.type) {
    case CONNECT_WEBSOCKET_SUCCESS_CHANNEL: {
      draft[product] = initializeWebsocket();
      break;
    }
    case STOP_WEBSOCKET_CHANNEL: {
      delete draft[product];
      break
    }

    case CONNECT_WEBSOCKET_ERROR_CHANNEL: {
      draft[product].error = error;
      break
    }

    case RETRIEVE_WEBSOCKET_MESSAGES: {
      if(product === 'seguro-garantia'){
        let newQuotation = [
          ...draft[product].data,
          data
        ];
        draft[product].data = newQuotation;
      } else {
        draft[product].data = data;
      }
      break;
    }
    default:
      return;
  }
}, initialState);

export default Websocket;
