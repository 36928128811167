import React from "react";
import { Route, Switch } from "react-router-dom";
import asyncComponent from "util/asyncComponent";

const App = React.memo((props) => (
  <div className="gx-main-content-wrapper">
    <Switch>
      <Route path="/" component={asyncComponent(() => import('../pages/index.js'))}/>
    </Switch>
  </div>
));

export default App;
