import axios from 'axios';
//Configurations
axios.defaults.baseURL = window._env_.API_URL || "";
axios.defaults.headers.common["Content-Type"] = "application/json";

const base = (method, url, data = null) => {
  return axios({
    url: url,
    method: method,
    data: data,
  })
};

const Request = {};
['get', 'post', 'put', 'patch', 'delete'].forEach(method => {
  Request[method] = base.bind(null, method);
});

export default Request;
