import { formatMaskInput } from "helpers";

export const documentValidator = (val, type) => {
    const formatVal = formatMaskInput(val),
        documentNumber = formatVal ? formatVal.split('') : null,
        length = documentNumber ? documentNumber.length : null;
    let v1 = 0;
    let v2 = 0;
    let aux = false;

    if (length === 11 && type === 'INDIVIDUAL') {
        var cpf = documentNumber;

        for (var a = 1; cpf.length > a; a++) {
            if (cpf[a - 1] !== cpf[a]) {
                aux = true;
            }
        }

        if (aux === false) {
            return false;
        }

        for (var b = 0, o = 10; (cpf.length - 2) > b; b++, o--) {
            v1 += cpf[b] * o;
        }

        v1 = ((v1 * 10) % 11);

        if (v1 === 10) {
            v1 = 0;
        }

        if (v1 !== Number(cpf[9])) {
            return false;
        }

        for (var c = 0, p = 11; (cpf.length - 1) > c; c++, p--) {
            v2 += cpf[c] * p;
        }

        v2 = ((v2 * 10) % 11);

        if (v2 === 10) {
            v2 = 0;
        }

        if (v2 !== Number(cpf[10])) {
            return false;
        } else {
            return true;
        }
    } else if (length === 14 && type === 'COMPANY') {
        var cnpj = documentNumber;

        for (var d = 1; cnpj.length > d; d++) {
            if (cnpj[d - 1] !== cnpj[d]) {
                aux = true;
            }
        }

        if (aux === false) {
            return false;
        }

        for (var e = 0, o1 = 5, o2 = 13; (cnpj.length - 2) > e; e++, o1--, o2--) {
            if (o1 >= 2) {
                v1 += cnpj[e] * o1;
            } else {
                v1 += cnpj[e] * o2;
            }
        }

        v1 = (v1 % 11);

        if (v1 < 2) {
            v1 = 0;
        } else {
            v1 = (11 - v1);
        }

        if (v1 !== Number(cnpj[12])) {
            return false;
        }

        for (var f = 0, p1 = 6, p2 = 14; (cnpj.length - 1) > f; f++, p1--, p2--) {
            if (p1 >= 2) {
                v2 += cnpj[f] * p1;
            } else {
                v2 += cnpj[f] * p2;
            }
        }

        v2 = (v2 % 11);

        if (v2 < 2) {
            v2 = 0;
        } else {
            v2 = (11 - v2);
        }

        if (v2 !== Number(cnpj[13])) {
            return false;
        } else {
            return true;
        }
    } else {
        return false;
    }
}