export const CARGO_SINGLE_BOARDING_DETAILS_CHANGE = "CARGO_SINGLE_BOARDING_DETAILS_CHANGE";
export const CARGO_SINGLE_BOARDING_DETAILS_VALIDATE_ERROR = "CARGO_SINGLE_BOARDING_DETAILS_VALIDATE_ERROR";

export const changeCargoSingleBoardingDetails = (attr, value) => {
  return {
    type: CARGO_SINGLE_BOARDING_DETAILS_CHANGE,
    attr: attr,
    value: value
  }
};

export const validateSingleBoardingDetailsErrorMessage = (error) => {
  return {
    type: CARGO_SINGLE_BOARDING_DETAILS_VALIDATE_ERROR,
    error: error
  }
};
