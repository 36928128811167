import { notification } from 'antd';
import { takeLatest, select, put } from "redux-saga/effects";
import { CARGO_SINGLE_BOARDING_REQUEST_CONVERTION, registerSingleBoardingConverted, setConvertionModal, setConvertionLoading } from 'appRedux/actions'
import { getOrderConfiguration } from '../../../selectors'
import { validateResponse } from 'helpers/validators'
import { history } from 'appRedux/history'
import axios from 'axios'

export default function* initializeSingleBoarding() {

    yield takeLatest(CARGO_SINGLE_BOARDING_REQUEST_CONVERTION, function* () {
        const { code } = yield select(getOrderConfiguration)
        const config = { headers: { 'Content-Type': 'application/json' } }
        try {
            const response = yield axios.put(`${window._env_.API_URL}/cargo/v1/single-boarding/convert/${code}`, config)
            const cargoOpenPolicyUrl = `/seguro-de-carga/multiplo/dados-gerais/${response.data.code}`
            yield put(registerSingleBoardingConverted())
            history.replace(cargoOpenPolicyUrl)
        } catch (error) {
            yield put(setConvertionModal(false))
            yield put(setConvertionLoading(false))
            notification.error({message:`Código:${error.response.data.status} - ${error.response.data.message}`})
        }

    })
}