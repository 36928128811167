export const CARGO_OPEN_POLICY_GENERAL_DATA_CHANGE = "CARGO_OPEN_POLICY_GENERAL_DATA_CHANGE";
export const CARGO_OPEN_POLICY_GENERAL_DATA_VALIDATE_ERROR = "CARGO_OPEN_POLICY_GENERAL_DATA_VALIDATE_ERROR";

export const validateOpenPolicyGeneralDataErrorMessage = (error) => {
  return {
    type: CARGO_OPEN_POLICY_GENERAL_DATA_VALIDATE_ERROR,
    error: error
  }
};

export const changeCargoOpenPolicyGeneralData = (attr, value) => {
  return {
    type: CARGO_OPEN_POLICY_GENERAL_DATA_CHANGE,
    attr: attr,
    value: value
  }
};