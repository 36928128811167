//Libs
import produce from "immer"
//Action Types
import {
  INITIALIZE_OPEN_POLICY_DATA,
  CARGO_OPEN_POLICY_UPDATE_API,
  CARGO_OPEN_POLICY_GOODS_CHANGE,
  CARGO_OPEN_POLICY_GOODS_VALIDATE_ERROR,
} from "../../../actions";
//Custom helpers
import { actionTypes, formatActionType, refreshState } from "../../helpers";
//Constants
const { SUCCESS, ERROR } = actionTypes;
//Initial State
const initialState = {
  updatingPartially: false,
  errors: {},
  others: {
    cargoTotalAmount: null
  },
  data: {
    goods:[
      {}
    ]
  }
};

//Reducer Function
const Goods = produce((draft, action) => {
  switch (action.type) {
    case INITIALIZE_OPEN_POLICY_DATA:
    case formatActionType(CARGO_OPEN_POLICY_UPDATE_API, SUCCESS): {
      draft.others.cargoTotalAmount = action.data.general.cargoTotalAmount;
      refreshState("goods",draft,action.data)
      break;
    }

    case formatActionType(CARGO_OPEN_POLICY_UPDATE_API, ERROR): {
      draft.errors = action.errors;
      break;
    }

    case CARGO_OPEN_POLICY_GOODS_CHANGE: {
      const { act, config } = action;
      const { index, value, attr, resetFields } = config;
      switch (act) {
        case "EDIT": {
          if (resetFields.length) {
            resetFields.forEach(field => {
              draft.data.goods[index][field] = null;
            })
          }
          draft.data.goods[index][attr] = value;
          break;
        }
        case "CREATE": {
          draft.data.goods.push(value);
          break;
        }
        case "DELETE": {
          draft.data.goods.splice(index, 1);
          break;
        }
        // no default
      }
      break;
    }

    case CARGO_OPEN_POLICY_GOODS_VALIDATE_ERROR: {
      delete draft.errors[action.error];
      break;
    }
    
    default:
      return;
  }
}, initialState);

export default Goods;