//Libs
import produce from "immer"
//Action Types
import {
  INITIALIZE_ORDER,
  INITIALIZE_SINGLE_BOARDING_DATA,
  CARGO_SINGLE_BOARDING_UPDATE_API,
  CARGO_SINGLE_BOARDING_INITIAL_DATA_CHANGE,
  CARGO_SINGLE_BOARDING_INITIAL_DATA_VALIDATE_ERROR,
  // CARGO_SINGLE_BOARDING_UPDATE_API_PARTIALLY,
  CARGO_SINGLE_BOARDING_INITIAL_DATA_LOCAL_VALIDATION_ERROR,
} from "../../../actions";
//Custom helpers
import { actionTypes, formatActionType, refreshState } from "../../helpers";
import { getUrlParam } from "helpers";
//Constants
const { SUCCESS, ERROR } = actionTypes;

const initialState = {
  updatingPartially: false,
  errors: {},
  data: {
    name: "",
    type: "",
    documentNumber: "",
    email: "",
    phoneNumber: "",
    phoneType: "",
    receiveWhatsAppMessage: false,
    hasntDocumentNumber: false
  }
};

const emailParam = getUrlParam('email');

//Reducer Function
const InitialData = produce((draft, action) => {
  switch (action.type) {
    case INITIALIZE_ORDER: {
      if(emailParam){
        draft.data.email = emailParam;
      }
      break;
    }

    case INITIALIZE_SINGLE_BOARDING_DATA: {
      if (action.data.stage !== 'FORM_NOT_QUALIFIED') {
        draft.data.hasntDocumentNumber = action.data.initial.documentNumber ? false : true;
      }
      refreshState("initial", draft, action.data)
      break;
    }

    case formatActionType(CARGO_SINGLE_BOARDING_UPDATE_API, SUCCESS): {
      refreshState("initial", draft, action.data)
      break;
    }

    case formatActionType(CARGO_SINGLE_BOARDING_UPDATE_API, ERROR): {
      draft.errors = action.errors;
      break;
    }

    case CARGO_SINGLE_BOARDING_INITIAL_DATA_VALIDATE_ERROR: {
      delete draft.errors[action.error];
      break;
    }
    case CARGO_SINGLE_BOARDING_INITIAL_DATA_LOCAL_VALIDATION_ERROR: {
      draft.errors = action.errors;
      break;
    }

    case CARGO_SINGLE_BOARDING_INITIAL_DATA_CHANGE: {
      const { attr, value } = action;
      draft.data[attr] = value;
      break;
    }

    default:
      return;
  }
}, initialState);

export default InitialData;
