//Libs
import produce from "immer"
//Action Types
import {
  INITIALIZE_SURETY_DATA,
  SURETY_OBLIGEE_CHANGE,
  SURETY_UPDATE_API,
  SURETY_OBLIGEE_SEARCH_DATA_API,
  SURETY_OBLIGEE_VALIDATE_ERROR,
  SURETY_OBLIGEE_LOCAL_VALIDATION_ERROR,
} from "../../../actions";
//Custom helpers
import { actionTypes, formatActionType, refreshState } from "../../helpers";
//Constants
const { SUCCESS, ERROR } = actionTypes;

const initialState = {
  updatingPartially: false,
  errors: {},
  data: {
    additionalData: null,
    address: null,
    city: null,
    documentNumber: null,
    name: null,
    neighborhood: null,
    number: null,
    state: null,
    type: 'COMPANY',
    zipCode: null
  }
};

//Reducer Function
const Obligee = produce((draft, action) => {
  switch (action.type) {
    case INITIALIZE_SURETY_DATA:
    case formatActionType(SURETY_UPDATE_API, SUCCESS): {
      refreshState("obligee", draft, action.data)
      break;
    }

    case formatActionType(SURETY_UPDATE_API, ERROR): {
      draft.errors = action.errors;
      break;
    }

    case SURETY_OBLIGEE_VALIDATE_ERROR: {
      if (action.error) {
        delete draft.errors[action.error];
      } else {
        draft.errors = [];
      }
      break;
    }

    case formatActionType(SURETY_OBLIGEE_SEARCH_DATA_API, SUCCESS): {
      draft.data = {
        ...action.data,
        type: action.data.personType,
        name: action.data.companyName
      };
      break;
    }

    case formatActionType(SURETY_OBLIGEE_SEARCH_DATA_API, ERROR): {
      break;
    }
    
    case SURETY_OBLIGEE_LOCAL_VALIDATION_ERROR: {
      draft.errors = action.errors;
      break;
    }

    case SURETY_OBLIGEE_CHANGE:
      const { attr, value } = action;
      draft.data[attr] = value;
      break;
      
    default:
      return;
  }

}, initialState);

export default Obligee;
