//Libs
import produce from "immer"
//Action Types
import {
  CARGO_SINGLE_BOARDING_UPDATE_API,
  CARGO_SINGLE_BOARDING_RESUME_CHANGE,
  CARGO_SINGLE_BOARDING_RESUME_VALIDATE_ERROR,
  INITIALIZE_SINGLE_BOARDING_DATA,
  CARGO_SINGLE_BOARDING_RESUME_LOCAL_VALIDATION_ERROR,
} from "../../../actions";
//Custom helpers
import { actionTypes, formatActionType } from "../../helpers";
//Constants
const { SUCCESS, ERROR } = actionTypes;
//Initial State
const initialState = {
  updatingPartially: false,
  errors: {},
  data: {
    damage:null,
    theft:null,
    documentNumber: "",
    simulations: {}
  }
};

//Reducer Function
const Resume = produce((draft, action) => {
  switch (action.type) {
    case INITIALIZE_SINGLE_BOARDING_DATA: {
      const data = action.data;
      if (data.hasOwnProperty("simulations"))
        draft.data.simulations = data.simulations;
      draft.data.simulations.values = data.simulations.values;
      if (data.hasOwnProperty("initial"))
        draft.data.documentNumber = data.initial.documentNumber;
      break;
    }

    case formatActionType(CARGO_SINGLE_BOARDING_UPDATE_API, SUCCESS): {
      const data = action.data;
      if (data.hasOwnProperty("simulations"))
        draft.data.simulations = data.simulations;
      draft.data.simulations.values = data.simulations.values;
      if (data.hasOwnProperty("initial"))
        draft.data.documentNumber = data.initial.documentNumber;
      break;
    }

    case formatActionType(CARGO_SINGLE_BOARDING_UPDATE_API, ERROR): {
      draft.errors = action.errors;
      break;
    }

    case CARGO_SINGLE_BOARDING_RESUME_VALIDATE_ERROR: {
      delete draft.errors[action.error];
      break;
    }
    
    case CARGO_SINGLE_BOARDING_RESUME_LOCAL_VALIDATION_ERROR: {
      draft.errors = action.errors;
      break;
    }

    case CARGO_SINGLE_BOARDING_RESUME_CHANGE:
      const { attr, value } = action;
      draft.data[attr] = value;
      break;
    default:
      return;
  }
}, initialState);

export default Resume;

