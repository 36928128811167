//Libs
import produce from "immer"
//constants
import { privatePaths } from "constants/index"
//Action INITIALIZE_ORDER
import { 
  CHANGE_ACTIVE_ROUTE, 
  FILL_ROUTE,
  INITIALIZE_SURETY_DATA,
  SURETY_UPDATE_API,
} from "../../actions";
//Custom helpers
import { actionTypes, formatActionType } from "../helpers";
//Constants
const { SUCCESS } = actionTypes;

const initialState = {
  activeRoute: null,
  filledRoutes:[],
  displayRoutes: []
};

const Order = produce((draft, action) => {
  switch (action.type) {
    case CHANGE_ACTIVE_ROUTE: {
      const {activeRoute} = action;
      draft.activeRoute = activeRoute;
      return;
    }
    case FILL_ROUTE: {
      const {route} = action;
      if(!draft.filledRoutes.includes(route)){
        draft.filledRoutes.push(route);
      }
      return;
    }
    case INITIALIZE_SURETY_DATA:
    case formatActionType(SURETY_UPDATE_API, SUCCESS): {
      if (action.data.hasOwnProperty("hasExpressEmission") && action.data.type === 'SURETY'){
        if(action.data.hasExpressEmission){
          draft.displayRoutes.push(`/seguro-garantia${privatePaths['minute']}`);
          draft.displayRoutes.push(`/seguro-garantia${privatePaths['payment']}`);
        } else {
          draft.displayRoutes = [];
        }
      }
      break;
    }
    default:
      return draft;
  }
}, initialState);

export default Order;
