import { Client } from '@stomp/stompjs';
//Action Types
import {
  CONNECT_WEBSOCKET_SUCCESS_CHANNEL,
  CONNECT_WEBSOCKET_CHANNEL,
  SEND_WEBSOCKET_MESSAGES,
  STOP_WEBSOCKET_CHANNEL,
} from "../actions";
//Actions Creators
import {
  websocketStarted,
  disconnectWebSocket,
  retrieveWebSocketMessages,
  sendWebSockedMessages
} from "../actions/websocket";
//Constants
import allRoutes from '../../routes/routes';

//Custom Helpers
function formatWebSocketPayload(data) {
  return JSON.stringify(data);
}

function anyEmptyQuotation(data) {
  return data.some((item) => item.status !== 'RESERVED')
}

const socketMiddleware = (store) => {
  let socket = null;

  const onOpen = (store, product, topics) => (event) => {
    topics.map((topic) => {
      if(topic.includes('error')){
        return socket.subscribe(topic, onError(store, product));
      }else{
        return socket.subscribe(topic, onMessage(store, product));
      }
    })
    store.dispatch(
      websocketStarted(product)
    );
  };
  
  const onError = () => (event) => {
    console.log('Ocorreu um erro', event.body)
  }

  const onMessage = (store, product) => (message) => {
    const payload = JSON.parse(message.body);
    store.dispatch(
      retrieveWebSocketMessages(product, payload)
    );
  };

  const disconnect = (store, product) => {
    if (socket !== null)
      socket.deactivate();
    socket = null;
    store.dispatch(
      disconnectWebSocket(product)
    );
  }

  // the middleware part of this function
  return store => next => action => {
    const { data, product } = action;
    //Grab the host Url
    let productConfig, code, productState;
    if (product){
      productConfig = allRoutes.private[product].config;
    }

    code = store.getState().order.code;
    productState = store.getState().products[product];

    switch (action.type) {
      case CONNECT_WEBSOCKET_CHANNEL:
        if(socket || !productConfig.socket){
          break;
        }
        socket = new Client({
          brokerURL: `${window._env_.WS_API}${productConfig.socket.url}`,
          connectHeaders: {},
          debug: function (str) {
          },
          reconnectDelay: 3000,
          heartbeatIncoming: 4000,
          heartbeatOutgoing: 4000,
          onConnect: onOpen(store, product, productConfig.socket.topics),
          onStompError: function (frame) {
            console.log('Broker reported error: ' + frame.headers['message']);
            console.log('Additional details: ' + frame.body);
          },
          onWebSocketClose: function(frame) {
            // console.log('Close connetion teste: ',frame)
          }
        });

        socket.activate();
        break;

      case SEND_WEBSOCKET_MESSAGES:
        const payload = formatWebSocketPayload(data);
        if (payload) { 
          socket.publish({ destination: productConfig.socket.publishUrl, body: payload }) 
        };
        break;

      case STOP_WEBSOCKET_CHANNEL:
        disconnect(store, product);
        break;

      case CONNECT_WEBSOCKET_SUCCESS_CHANNEL:
        if (productState) {
          switch(product){
            case 'seguro-garantia':
              
              const needResend = anyEmptyQuotation(productState.quotation.data.quotations.data);
              const payload = productConfig.socket.formatPayload(code, productState);
              if (payload) {
                store.dispatch(sendWebSockedMessages(product, {"code":payload.code}));
                }
              
              break;
            case 'seguro-de-carga/multiplo':
              if(productState.general.data.insuredType){
                const payload = {
                  code: code,
                  general: productState.general.data,
                  routes: productState.routes.data,
                  coverages: productState.coverages.data
                }
                store.dispatch(sendWebSockedMessages(product, payload));
              }
              break;
            default:
              break;
          }
        }
        break;

      default:
        return next(action);
    }
  };
};

export default socketMiddleware();