//Libs
import produce from "immer"
//Action Types
import {
  INITIALIZE_OPEN_POLICY_DATA,
  CARGO_OPEN_POLICY_UPDATE_API,
  CARGO_OPEN_POLICY_ROUTES_DATA_LIST_CHANGE, 
  CARGO_OPEN_POLICY_ROUTES_DATA_VALIDATE_ERROR,
  // CARGO_OPEN_POLICY_ROUTES_DA_PARTIALLY,
} from "../../../actions";
//Custom helpers
import { actionTypes, formatActionType, refreshState } from "../../helpers";
//Constants
const { SUCCESS, ERROR } = actionTypes;

const initialState = {
  updatingPartially: false,
  errors: {},
  data: {
    routes: [{}]
  }
};

//Reducer Function
const RoutesData = produce((draft, action) => {
  switch (action.type) {
    case INITIALIZE_OPEN_POLICY_DATA:
    case formatActionType(CARGO_OPEN_POLICY_UPDATE_API, SUCCESS): {
      if(action.data.hasOwnProperty("general")){
        draft.data.modals = action.data.general.modals;
      }
      refreshState("routes", draft, action.data)
      break;
    }

    case formatActionType(CARGO_OPEN_POLICY_UPDATE_API, ERROR): {
      draft.errors = action.errors;
      break;
    }

    case CARGO_OPEN_POLICY_ROUTES_DATA_VALIDATE_ERROR: {
      delete draft.errors[action.error];
      break;
    }

    case CARGO_OPEN_POLICY_ROUTES_DATA_LIST_CHANGE: {
      const {act, config} = action;
      const {index, value, attr} = config;
      switch (act) {
        case "EDIT": {
          draft.data.routes[index][attr] = value;
          break;
        }
        case "CREATE": {
          draft.data.routes.push(value);
          break;
        }
        case "DELETE": {
          draft.data.routes.splice(index, 1);
          break;
        }
        // no default
      }
      break;
    }

    default:
      return;
  }
}, initialState);

export default RoutesData;
