import axios from 'axios';
import { notification } from "antd";

export const fileStatus = {
  PROCESSING: 'uploading', 
  COMPLETED: 'done', 
  ERROR: 'error'
}

export const uploadConfig = (handleFileChange, handleProgress) => {
  let progressCount = 0;
  return {
    name: 'file',
    multiple: true,
    headers: {
      'Content-type': 'multipart/form-data',
    },
    showUploadList: {
      showPreviewIcon: false, 
      showDownloadIcon: false, 
      showRemoveIcon: false
    },
    customRequest: option => {
      const { action, file, headers, onSuccess, onError, onProgress } = option;
      const formData = new FormData();
      formData.append('file', file);
      const config = {
        onUploadProgress: ({ total, loaded }) => {
          onProgress({ percent: Math.round(loaded / total * 100).toFixed(2) }, file);
        },
        headers: headers,
      };
      axios
      .post(action, formData, config)
      .then(({ data: response }) => {
        notification.success({
          duration: 5.5,
          message: `'${file.name}' enviado com sucesso`
        });
        return onSuccess(response,file)
      })
      .catch(err => {
        notification.error({
          duration: 5.5,
          message: `Erro no envio de '${file.name}'`,
          description: err.response ? err.response.data.message : 'Erro desconhecido'
        });
        return onError(err)
      });
    },
    onChange: handleFileChange,
    onProgress: ({ percent },file) => {
      progressCount++;
      if (progressCount <= 1) {
        notification.info({
          message: `Enviando '${file.name}'...`
        });
      }
      handleProgress(percent, file);
    }
  }
};

export const getFileList = (files) => {
  if(files){
    let fileList = [];
    files.map((file, index) => {
      const { link, name, status } = file;
      return fileList.push({
        uid: index,
        name: name,
        status: fileStatus[status],
        url: link,
      })
    })
    return fileList;
  }
  return null;
}