//Libs
import produce from "immer"
//Action Types
import {
  INITIALIZE_SINGLE_BOARDING_DATA,
  CARGO_SINGLE_BOARDING_UPDATE_API,
  CARGO_SINGLE_BOARDING_GENERAL_DATA_CHANGE, 
  CARGO_SINGLE_BOARDING_GENERAL_DATA_VALIDATE_ERROR,
  CARGO_SINGLE_BOARDING_REGISTER_CONVERTED,
  REGISTER_CONVERTION_ERROR,
  REGISTER_CONVERTION_LOADING,
  REGISTER_CONVERTION_MODAL
  // CARGO_SINGLE_BOARDING_UPDATE_API_PARTIALLY,
} from "../../../actions";
//Custom helpers
import {actionTypes, formatActionType, refreshState} from "../../helpers";
//Constants
const {SUCCESS, ERROR} = actionTypes;

const initialState = {
  updatingPartially: false,
  others: {
    simulations:{
      commodity:null,
      values:[]
    },
    international:false
  },
  errors: {},
  data: {
    anyUsedMerchandise: false,
    cargoTotalAmount: "",
    travelAverageAmount:"",
    currency: "BRA",
    destination: {
      description: "",
      placeId: "",
      terms: []
    },
    companyActivity: null,
    origin: {
      description: "",
      terms: []
    }
  },
  newFormCode:null,
  convertionModal:false,
  convertionLoading:false
};
//Reducer Function
const GeneralData = produce((draft, action) => {
  switch (action.type) {
    case INITIALIZE_SINGLE_BOARDING_DATA: {
      const data = action.data;      
      if (data.hasOwnProperty("simulations"))
        draft.others.simulations.commodity = data.simulations.commodity;
        draft.others.simulations.values = data.simulations.values;
      draft.others.international = data.international;
      refreshState("general",draft,action.data)
      break;
    }

    case formatActionType(CARGO_SINGLE_BOARDING_UPDATE_API, SUCCESS): {
      draft.others.simulations.commodity = action.data.simulations.commodity;
      draft.others.simulations.values = action.data.simulations.values;
      draft.others.international = action.data.international;
      refreshState("general",draft,action.data)
      break;
    }

    case formatActionType(CARGO_SINGLE_BOARDING_UPDATE_API, ERROR): {
      draft.errors = action.errors;
      break;
    }


    case CARGO_SINGLE_BOARDING_GENERAL_DATA_VALIDATE_ERROR: {
      delete draft.errors[action.error];
      break;
    }

    case CARGO_SINGLE_BOARDING_GENERAL_DATA_CHANGE:
      const {attr, value} = action;
      draft.data[attr] = value;
      break;

    case CARGO_SINGLE_BOARDING_REGISTER_CONVERTED:
      draft.newFormCode = action.value
      draft.convertionError = null
      break;

    case REGISTER_CONVERTION_LOADING:
      draft.convertionLoading = action.value
      break
    case REGISTER_CONVERTION_MODAL:
      draft.convertionModal = action.value
      break

    default:
      return;
  }
}, initialState);

export default GeneralData;
