import { dateApiFormat } from "components/AppConfig/index";
import moment from "moment";
//Helpers
import { formatDocInput, normalizeCurrency } from "helpers";
//Constants
import { documentTypes } from "constants/index";

export const singleBoardingResumeData = (resumeData, initialData) => {
    let data = {
        type: documentTypes.COMPANY,
        accident: true,
        damage: resumeData.damage,
        theft: resumeData.damage ? true : resumeData.theft,
    };
    if (resumeData.documentNumber)
        data.documentNumber = formatDocInput(resumeData.documentNumber,documentTypes.COMPANY);

    return data;
}

export const singleBoardingGeneralData = data => {
    function isSelectionValid(entry) {
        return !!entry.description;
    }

    const formattedData = {
        anyUsedMerchandise: data.anyUsedMerchandise,
        cargoTotalAmount: Number(normalizeCurrency(data.cargoTotalAmount)),
        travelAverageAmount: Number(data.travelAverageAmount),
        currency: data.currency,
        companyActivity: data.companyActivity,
        destination: null,
        origin: null
    };

    if (isSelectionValid(data.destination))
        formattedData.destination = data.destination;

    if (isSelectionValid(data.origin))
        formattedData.origin = data.origin;

    return formattedData;
}

export const singleBoardingDetailsData = data => {
    const {
        boardingDate,
        acceptPolicyAndPrivacy,
        additionalInformation,
        incoTerm,
        modals,
        acceptPaymentSlipAndPolicyName,
        acceptRiskManagement
    } = data;
    const date = boardingDate ? moment(boardingDate).format(dateApiFormat) : null;
    const details = {
        boardingDate: date,
        acceptPolicyAndPrivacy: acceptPolicyAndPrivacy,
        additionalInformation: additionalInformation,
        incoTerm: incoTerm,
        modals: modals
    }
    if(acceptPaymentSlipAndPolicyName !== false) {
        details.acceptPaymentSlipAndPolicyName = acceptPaymentSlipAndPolicyName;
    }
    if(acceptRiskManagement !== false) {
        details.acceptRiskManagement = acceptRiskManagement;
    }
    return details;
}