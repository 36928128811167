//Utils
import asyncComponent from "util/asyncComponent";
//Constants
import { publicPaths, privatePaths } from "constants/index"
//Helpers
import { suretyWsRequest } from "helpers/requestsFormatter";
//RequestsFormatter
import {
	initialData, 
	resumeData,
	insuredData,
	termData,
	suretyDetailsData,
  minuteData,
  paymentData
} from "helpers/requestsFormatter";

export const surety = {
    routes: [
      {
        exact: true,
        title: "Seus dados",
        apiIdentifier: "initial",
        urlIdentifier: "initial-data",
        initialPath: publicPaths.surety + privatePaths.initial,
        path: publicPaths.surety + privatePaths.initial + ":id",
        socket: {
          initialize: true,
        },
        formatResponse: (data) => initialData(data),
        component: asyncComponent(() => import('containers/Products/surety/InitialData'))
      },
      {
        exact: true,
        title: "Tipo de garantia",
        apiIdentifier: "type",
        urlIdentifier: "type-data",
        formatResponse: (data) => data,
        initialPath: publicPaths.surety + privatePaths.type,
        path: publicPaths.surety + privatePaths.type + ":id",
        component: asyncComponent(() => import('containers/Products/surety/SuretyType'))
      },
      {
        exact: true,
        title: "Dados da garantia",
        apiIdentifier: "term",
        urlIdentifier: "term-data",
        formatResponse: (data) => termData(data),
        initialPath: publicPaths.surety + privatePaths.term,
        path: publicPaths.surety + privatePaths.term + ":id",
        socket: {
          send: true,
        },
        component: asyncComponent(() => import('containers/Products/surety/GeneralData'))
      },
      {
        exact: true,
        title: "Resumo da cotação",
        apiIdentifier: "quotation",
        urlIdentifier: "quotation-data",
        formatResponse: (data) => resumeData(data),
        sendWebsocketData: true,
        initialPath: publicPaths.surety + privatePaths.quotation,
        path: publicPaths.surety + privatePaths.quotation + ":id",
        socket: {
          send: true,
        },
        component: asyncComponent(() => import('containers/Products/surety/Quotations/'))
      },
      {
        exact: true,
        title: "Dados do segurado",
        apiIdentifier: "obligee",
        urlIdentifier: "obligee-data",
        formatResponse: (data) => insuredData(data),
        initialPath: publicPaths.surety + privatePaths.insured,
        path: publicPaths.surety + privatePaths.insured + ":id",
        socket: {
          send: true,
        },
        component: asyncComponent(() => import('containers/Products/surety/ObligeeData'))
      },
      {
        exact: true,
        title: "Detalhes da garantia",
        apiIdentifier: "detail",
        urlIdentifier: "detail-data",
        formatResponse: (data) => suretyDetailsData(data),
        initialPath: publicPaths.surety + privatePaths.details,
        path: publicPaths.surety + privatePaths.details + ":id",
        component: asyncComponent(() => import('containers/Products/surety/Details'))
      },
      {
        exact: true,
        hidden: true,
        title: "Forma de pagamento",
        apiIdentifier: "payment",
        urlIdentifier: "payment-data",
        formatResponse: (data) => paymentData(data),
        initialPath: publicPaths.surety + privatePaths.payment,
        path: publicPaths.surety + privatePaths.payment + ":id",
        component: asyncComponent(() => import('containers/Products/surety/Payments/'))
      },
      {
        exact: true,
        hidden: true,
        title: "Minuta",
        apiIdentifier: "minute",
        urlIdentifier: "minute-data",
        formatResponse: (data) => minuteData(data),
        initialPath: publicPaths.surety + privatePaths.minute,
        path: publicPaths.surety + privatePaths.minute + ":id",
        component: asyncComponent(() => import('containers/Products/surety/Minute/'))
      },
      {
        exact: true,
        title: "Final",
        apiIdentifier: "final",
        urlIdentifier: "final",
        initialPath: publicPaths.surety + privatePaths.final,
        path: publicPaths.surety + privatePaths.final + ":id",
        component: asyncComponent(() => import('containers/Products/surety/FinalMessage'))
      }
    ],
    config: {
      product: "surety",
      productId: 'seguro-garantia',
      type: "SURETY",
      socket: {
        url: "/surety/ws",
        publishUrl: "/app/surety/v1",
        topics: ["/user/queue/reserve", "/user/queue/error"],
        formatPayload: (code, productData, documentNumber) => suretyWsRequest({code, productData, documentNumber})
      },
      getUrl: function (identifier, code) {
        return `surety/v2/${code}/${identifier}`;
      }
    }
}