export const LIFE_INITIAL_DATA_CHANGE = "LIFE_INITIAL_DATA_CHANGE";
export const LIFE_INITIAL_DATA_VALIDATE_ERROR = "LIFE_INITIAL_DATA_VALIDATE_ERROR";
export const LIFE_INITIAL_DATA_LOCAL_VALIDATION_ERROR = "LIFE_INITIAL_DATA_LOCAL_VALIDATION_ERROR";

export const changeLifeInitialData = (attr, value) => {
  return {
    type: LIFE_INITIAL_DATA_CHANGE,
    attr: attr,
    value: value
  }
};

export const validateLifeInitialDataErrorMessage = (error) => {
  return {
    type: LIFE_INITIAL_DATA_VALIDATE_ERROR,
    error: error
  }
};

export const localValidationLifeInitialDataErrorMessage = (errors) => {
  return {
    type: LIFE_INITIAL_DATA_LOCAL_VALIDATION_ERROR,
    errors: errors
  }
};