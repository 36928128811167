export const CHANGE_ACTIVE_ROUTE = "CHANGE_ACTIVE_ROUTE";
export const FILL_ROUTE = "FILL_ROUTE";

export const changeActiveRoute = (activeRoute) => {
  return {
    type: CHANGE_ACTIVE_ROUTE,
    activeRoute: activeRoute
  }
};

export const fillRoute = (route) => {
  return {
    type: FILL_ROUTE,
    route: route
  }
};