// redux-toolkit
import { configureStore } from "@reduxjs/toolkit";
// slices
import simulator from "./slices/simulator";
import common from "./slices/common";
import cargo from "./slices/cargo";
import life from "./slices/life";
import surety from "./slices/surety";

import tracking from "./slices/tracking";

// ---------------------------------------------------------------------

const store = configureStore({
  reducer: {
    simulator,
    common,
    tracking,
    cargo,
    life,
    surety
  },
});

export default store;