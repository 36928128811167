//Libs
import produce from "immer"
//Action Types
import {
  INITIALIZE_LIFE_DATA,
  LIFE_UPDATE_API,
  LIFE_DETAILS_CHANGE,
  LIFE_DETAILS_VALIDATE_ERROR,
  LIFE_DETAILS_LIST_CHANGE
} from "../../../actions";
//Custom helpers
import { actionTypes, formatActionType, refreshState } from "../../helpers";
//Constants
const { SUCCESS, ERROR } = actionTypes;

const initialState = {
  updatingPartially: false,
  errors: {},
  data: {
    capitalType: 'UNIFORM_VALUE',
    insurableGroups: [
      {
        group: null,
        value: null
      }
    ]
  }
};

//Reducer Function
const GeneralData = produce((draft, action) => {
  switch (action.type) {
    case INITIALIZE_LIFE_DATA:
    case formatActionType(LIFE_UPDATE_API, SUCCESS): {
      refreshState("details", draft, action.data)
      break;
    }

    case formatActionType(LIFE_UPDATE_API, ERROR): {
      draft.errors = action.errors;
      break;
    }

    case LIFE_DETAILS_VALIDATE_ERROR: {
      delete draft.errors[action.error];
      break;
    }

    case LIFE_DETAILS_LIST_CHANGE: {
      const {act, config} = action;
      const {index, value, attr} = config;
      switch (act) {
        case "EDIT": {
          draft.data.insurableGroups[index][attr] = value;
          break;
        }
        case "CREATE": {
          draft.data.insurableGroups.push(value);
          break;
        }
        case "DELETE": {
          draft.data.insurableGroups.splice(index, 1);
          break;
        }
        // no default
      }
      break;
    }

    case LIFE_DETAILS_CHANGE:
      const { attr, value } = action;
      draft.data[attr] = value;
      break;
    default:
      return;
  }
}, initialState);

export default GeneralData;
