//Libs
import produce from "immer"
import _ from "lodash"
//Action Types
import {
  INITIALIZE_SURETY_DATA,
  SURETY_UPDATE_API,
  SURETY_QUOTATIONS_CHANGE,
  SURETY_QUOTATIONS_VALIDATE_ERROR,
  RETRIEVE_WEBSOCKET_MESSAGES,
  SURETY_QUOTATIONS_LOCAL_VALIDATION_ERROR,
  // SURETY_UPDATE_API_PARTIALLY,
} from "../../../actions";
//Custom helpers
import { actionTypes, formatActionType, refreshState } from "../../helpers";
import { sendQuotationsNotifications, getProductPathByName } from "helpers";
//Constants
import { privatePaths } from "constants/index"
const { SUCCESS, ERROR } = actionTypes;

const initialState = {
  updatingPartially: false,
  errors: {},
  data: {
    quotations: {
      data:[],
      bestChoice: null
    },
    simulations: {
      data:[]
    },
    chosenInsurer: '',
    premium: null,
    type: 'COMPANY'
  }
};

const hasNewBestChoice = (quotations) => {
  const choices = quotations.data ? quotations.data.filter((item) => item.rate !== undefined) : [];
  const browserUrl = window.location.href;
  const excludedSteps = [ privatePaths.quotation, privatePaths.term ];

  if(choices[0]){     
    let bestChoice;
    const minPremium = _.minBy(choices, "premium");
    bestChoice = minPremium;

    const allMin = choices.filter(item => item.premium === minPremium.premium)
    if(allMin.length > 1){
      const minRate = _.minBy(allMin, "rate");
      bestChoice = minRate;
    }
    
    if(
      !(_.isEqual(quotations.bestChoice, bestChoice)) && 
      !excludedSteps.some(el => browserUrl.includes(el))
    ){
      sendQuotationsNotifications();
    }
    return bestChoice;
  }
  return null;
}

//Reducer Function
const Quotations = produce((draft, action) => {
  switch (action.type) {
    case INITIALIZE_SURETY_DATA: {
      refreshState("quotations", draft.data.quotations, action.data)
      refreshState("simulations", draft.data.simulations, action.data)
      const newBestChoice = hasNewBestChoice(draft.data.quotations);
      if(newBestChoice){
        draft.data.quotations.bestChoice = newBestChoice;
      }
      break;
    }

    case formatActionType(SURETY_UPDATE_API, SUCCESS): {
      refreshState("simulations", draft.data.simulations, action.data)
      const newBestChoice = hasNewBestChoice(draft.data.quotations);
      if(newBestChoice){
        draft.data.quotations.bestChoice = newBestChoice;
      }
      break;
    }

    case formatActionType(SURETY_UPDATE_API, ERROR): {
      draft.errors = action.errors;
      break;
    }

    case RETRIEVE_WEBSOCKET_MESSAGES: {
      if(action.product === getProductPathByName('surety')){ 
        let newQuotation = [
          action.data,
          ...draft.data.quotations.data
        ];
        var removeDuplicates = _.uniqBy(newQuotation, "insurer")
        var items = _.values(removeDuplicates)
        draft.data.quotations.data = items;
        const newBestChoice = hasNewBestChoice(draft.data.quotations);
        if(newBestChoice){
          draft.data.quotations.bestChoice = newBestChoice;
        }
      }
      break;
    }

    case SURETY_QUOTATIONS_VALIDATE_ERROR: {
      delete draft.errors[action.error];
      break;
    }
    
    case SURETY_QUOTATIONS_LOCAL_VALIDATION_ERROR: {
      draft.errors = action.errors;
      break;
    }

    case SURETY_QUOTATIONS_CHANGE:
      const { attr, value } = action;
      draft.data[attr] = value;
      break;
    default:
      return;
  }
}, initialState);

export default Quotations;
