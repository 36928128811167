// helpers
import { 
	extractUrlConfig,
	getProductNameByPath,
	getIdentifierByPath
} from "helpers";
import { notification } from "antd";
import { privatePaths } from "constants/index";

const hideNewQuotationsOn = {
	surety: [
		'payment',
		'minute'
	]
}

const hideQuotationsNotifications = (product, identifier) => {
	const productName = getProductNameByPath(product);
	const identifierName = getIdentifierByPath(identifier);

	if(hideNewQuotationsOn.hasOwnProperty(productName)){
		if(hideNewQuotationsOn[productName].indexOf(identifierName) !== -1) {
			return true;
		}
	}
	return false;
}

export const sendQuotationsNotifications = () => {
	// let history = useHistory();
  	const browserUrl = window.location.href;
	const { code, product, identifier } = extractUrlConfig(browserUrl);

	if(!hideQuotationsNotifications(product, identifier)){
		notification.success({
			duration: 5.5,
			message:'Chegou uma nova cotação!', 
			description:'Clique e confira o melhor valor para seu seguro!',
			onClick: () => {
				const url = '/' + product + privatePaths['quotation'] + code;
				// Testes usando hooks para não recarregar a página (ainda não funcionando)
				// return browserHistory.push(url);
				return window.location.assign(url);
			}
		})
	}
}