import React, { useEffect } from "react";
// redux
import { useDispatch } from "react-redux";
import { appendUtmParams } from "../../store/slices/tracking";

const UTMAnalytics = ({ children }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    for (var pair of searchParams.entries()) {
      dispatch(appendUtmParams({ [pair[0]]: pair[1] }));
    }
  });
  return <>{children}</>;
};

export default UTMAnalytics;
