export const SURETY_INITIAL_DATA_CHANGE = "SURETY_INITIAL_DATA_CHANGE";
export const SURETY_INITIAL_DATA_VALIDATE_ERROR = "SURETY_INITIAL_DATA_VALIDATE_ERROR";
export const SURETY_INITIAL_DATA_LOCAL_VALIDATION_ERROR = "SURETY_INITIAL_DATA_LOCAL_VALIDATION_ERROR";

export const changeSuretyInitialData = (attr, value) => {
  return {
    type: SURETY_INITIAL_DATA_CHANGE,
    attr: attr,
    value: value
  }
};

export const validateSuretyInitialDataErrorMessage = (error) => {
  return {
    type: SURETY_INITIAL_DATA_VALIDATE_ERROR,
    error: error
  }
};

export const localValidationSuretyInitialDataErrorMessage = (errors) => {
  return {
    type: SURETY_INITIAL_DATA_LOCAL_VALIDATION_ERROR,
    errors: errors
  }
};