import Request from "../../../../util/Request";

export const SURETY_PAYMENT_CHANGE = "SURETY_PAYMENT_CHANGE";
export const SURETY_PAYMENT_VALIDATE_ERROR = "SURETY_PAYMENT_VALIDATE_ERROR";
export const SURETY_MINUTE_GET_PAYMENT_OPTION = "SURETY_MINUTE_GET_PAYMENT_OPTION";
export const SURETY_MINUTE_GET_PAYMENT_OPTION_ERROR = "SURETY_MINUTE_GET_PAYMENT_OPTION_ERROR";

export const changeSuretyPayment = (attr, value) => {
  return {
    type: SURETY_PAYMENT_CHANGE,
    attr: attr,
    value: value
  }
};

export const validateSuretyPaymentErrorMessage = (error) => {
  return {
    type: SURETY_PAYMENT_VALIDATE_ERROR,
    error: error
  }
};

export const getSuretyPaymentOptions = (url, successCallback) => {
  return {
    type: SURETY_MINUTE_GET_PAYMENT_OPTION,
    request: Request.get(url),
    handleSuccess: successCallback
  }
};

export const setSuretyPaymentOptionsError = (error) => {
  return {
    type: SURETY_MINUTE_GET_PAYMENT_OPTION_ERROR,
    error: error
  }
};