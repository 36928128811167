import { put, all, select, fork, takeLatest } from "redux-saga/effects";
//Actions Types
import { INITIALIZE_WEBSOCKET_CHANNEL } from "../../actions/websocket";
//Action Creators
import { connectWebSocket } from "../../actions/websocket";
//Selectors
import { getWebSocketConfig } from "../../selectors";

export function* initializeWebSocketSaga() {
  yield takeLatest(INITIALIZE_WEBSOCKET_CHANNEL, function* initialize(action) {
    try {
      const product = action.product;
      const webSocket = yield select(getWebSocketConfig);
      if (!webSocket.hasOwnProperty(product))
        yield put(connectWebSocket(product))
    } catch (error) {
      console.log("Error", error);
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(initializeWebSocketSaga)
  ]);
}
