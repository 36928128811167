import axios from "axios";

const sleep = (milliseconds) => {
  return new Promise(resolve => setTimeout(resolve, milliseconds))
};

export const interceptor = (maxRetry, delay) => {
  axios.interceptors.response.use((response) => {
    if (response.config.method !== "patch")
    return response;
  }, (error) => {
    if (maxRetry > 0) {
      if (error.response) {
        if (error.response.method !== "patch")
          if (error.response.status === 503 || error.response.status === 504) {
            const requestConfig = error.config;
            sleep(delay).then(() => {
              axios(requestConfig);
              maxRetry--
            });
          }
      }
    } else {
      window.location.href = '/erro/';
    }
    return Promise.reject(error);
  });

  axios.interceptors.request.use(async (config) => {
    return config;
  }, (error) => {
    return Promise.reject(error);
  });
};
