//Libs
import produce from "immer"
//Action INITIALIZE_ORDER
import { INITIALIZE_ORDER } from "../../actions";

const initialState = {
  code: null,
  product: null,
};

const Order = produce((draft, action) => {
  switch (action.type) {
    case INITIALIZE_ORDER:
      draft.code = action.code;
      draft.product = action.product;
      return;
      ;
    default:
      return draft;
  }
}, initialState);

export default Order;
