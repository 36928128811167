import React from "react";
import { Link } from "react-router-dom";

class SidebarItem extends React.Component {
	render() {
		const { listItem, activeRoute, filledRoutes, displayRoutes, code } = this.props;
		const { title, path, initialPath, hidden } = listItem;
		let status = '';
		const isActivePage = (activeRoute === initialPath);
		const codePath = (path, code) => {
			let newPath = path.split('/');
			newPath[newPath.length - 1] = code;
			return newPath.join('/');
		}
		if(filledRoutes.indexOf(initialPath) !== -1){
			status = 'on'
		}
		if(hidden && displayRoutes.indexOf(initialPath) === -1) {
			return null;
		}
		return (
			<Link
				to={codePath(path, code)}
				className={
					isActivePage
						? `active sidebar-item ${status}`
						: `sidebar-item ${status}`
				}
			>
				<div className="dot"></div>
				<div className="step-name">{title}</div>
			</Link>
		);
	}
}

export default SidebarItem;
