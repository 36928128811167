//Components
import { notification } from "antd";
import Nprogress from "nprogress";

//Custom Action Types
const actionTypes = {
  LOADING: 'LOADING',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
};

function isPromise(value) {
  if (value !== null && typeof value === 'object')
    return value && typeof value.then === 'function';
  return false;
}

function toastMessage(errorResponse) {
  const message = () => {
    if(errorResponse.errors){
      return errorResponse.errors[0].message;
    } else if(errorResponse.message) {
      if(!/INTERNAL|UNKNOWN|No message available/.test(errorResponse.message)){
        return errorResponse.message;
      } else {
        return "Ocorreu algum erro";
      }
    }
    return "Ocorreu algum erro";
  }
  notification.error({ duration: 5.5, message: message(), description: null });
}

function parseErrors(response) {
  const fields = {};
  response.data.errors.forEach(error => {
    const { field, message } = error;
    fields[field] = message;
  });
  return fields;
}


export const displayWarning = (description, message = "Warning") => {
  notification.warning({ duration: 5.5, message, description })
};

const imgUrl = process.env.PUBLIC_URL + "/loader.gif";

Nprogress.configure({

  template:

    '<div role="bar" class="bar custom-loading"><img src="' +

    imgUrl +

    '" alt="loader"/></div>'

});


const apiMiddleware = (store) => {
  const { dispatch } = store;

  return next => (action) => {
    next(action);

    const { type, request } = action;

    if (!type || !request || !isPromise(request))
      return;
    if(!type.endsWith('_PARTIALLY') && (type !== 'SURETY_MINUTE_GET_FILE') && (type !== 'SURETY_MINUTE_GET_PAYMENT_OPTION') ){
      Nprogress.start();
    }
    request.then((response) => {
      if(!type.endsWith('_PARTIALLY')){
        Nprogress.done();
      }
      if (response.status === 200) {
        dispatch({
          type: `${type}_${actionTypes.SUCCESS}`,
          data: response.data
        });

        if (action.handleSuccess)
          action.handleSuccess(response.data);
      }
    })
      .catch(error => {
        if(!type.endsWith('_PARTIALLY') && type !== 'SURETY_MINUTE_GET_PAYMENT_OPTION'){
          Nprogress.done();
        }
        if(type === 'SURETY_MINUTE_GET_FILE') {
          const response = error.response;
          if (response.data.hasOwnProperty("error")) {
              dispatch({
                type: `${type}_${actionTypes.ERROR}`,
                errors: response.data
              });
          }
        }
        if(type === 'SURETY_MINUTE_GET_PAYMENT_OPTION') {
          const response = error.response;
          if (response && response.data.hasOwnProperty("error")) {
              dispatch({
                type: `${type}_${actionTypes.ERROR}`,
                errors: response.data.message
              });
          }
        }
        if (error.response && !type.endsWith('_PARTIALLY')) {
          const response = error.response;
          if (response.data.hasOwnProperty("errors")) {
            if (response.status >= 400 && response.status < 500) {
              dispatch({
                type: `${type}_${actionTypes.ERROR}`,
                errors: parseErrors(response)
              });
            } else if (error.request) {
              console.log(error.request);
            } else {
              // Something happened in setting up the request and triggered an Error
              console.log('Error', error.message);
            }
          } else {
            /*Prepare the fields error using error message object*/
            toastMessage(response.data);
          }
          if (action.handleFailure)
            action.handleFailure(error);
        }
      }
    )
  };
};


export default apiMiddleware;
