import _ from "lodash"

export const actionTypes = {
  ERROR: "ERROR",
  SUCCESS: "SUCCESS"
};

export function formatActionType(actionType, currentState) {
  return `${actionType}_${currentState}`;
}

export function refreshState(identifier, draft, data) {
    if (data.hasOwnProperty(identifier)){
      	draft.data = _.isEmpty(data[identifier]) ? draft.data : Object.assign(draft.data, data[identifier]);
    }
}