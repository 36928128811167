//Libs
import produce from "immer"
//Action Types
import {
  LIFE_UPDATE_API,
  LIFE_RESUME_CHANGE,
  LIFE_RESUME_VALIDATE_ERROR,
  INITIALIZE_LIFE_DATA,
  LIFE_RESUME_LOCAL_VALIDATION_ERROR,
} from "../../../actions";
//Custom helpers
import { actionTypes, formatActionType } from "../../helpers";
//Constants
const { SUCCESS, ERROR } = actionTypes;
//Initial State
const initialState = {
  updatingPartially: false,
  errors: {},
  data: {
    documentNumber: "",
    simulations: {}
  }
};

//Reducer Function
const Resume = produce((draft, action) => {
  switch (action.type) {
    case INITIALIZE_LIFE_DATA:
    case formatActionType(LIFE_UPDATE_API, SUCCESS): {
      const data = action.data;
      if (data.hasOwnProperty("simulation"))
        draft.data.simulations = data.simulation;
      if (data.hasOwnProperty("initial"))
        draft.data.documentNumber = data.initial.documentNumber;
      break;
    }

    case LIFE_RESUME_VALIDATE_ERROR: {
      delete draft.errors[action.error];
      break;
    }

    case formatActionType(LIFE_UPDATE_API, ERROR):
    case LIFE_RESUME_LOCAL_VALIDATION_ERROR: {
      draft.errors = action.errors;
      break;
    }

    case LIFE_RESUME_CHANGE:
      const { attr, value } = action;
      draft.data[attr] = value;
      break;
    default:
      return;
  }
}, initialState);

export default Resume;

