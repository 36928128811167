//Helpers
import { formatMaskInput, formatDocInput } from "helpers";
//Constants
import { phoneTypes, documentTypes } from "constants/index";

export const initialData = data => {
    const phoneNumber = formatMaskInput(data.phoneNumber);
    const formattedPhoneNumber = phoneNumber ? phoneNumber.slice(2) : null;
    const receiveWhatsAppMessage = data.receiveWhatsAppMessage;
    let phoneType = null;

    if (formattedPhoneNumber) {
        if (formattedPhoneNumber.length === 8){
            phoneType = phoneTypes.PHONE_NUMBER;
        }
        else if (formattedPhoneNumber.length === 9){
            phoneType = !!receiveWhatsAppMessage ?
                phoneTypes.WHATSAPP_PHONE_NUMBER : phoneTypes.MOBILE_PHONE_NUMBER;
        }
    }

    return {
        contactType: documentTypes.COMPANY,
        documentNumber: formatDocInput(data.documentNumber,documentTypes.COMPANY),
        email: data.email,
        name: data.name,
        phoneNumber: phoneNumber,
        phoneType: phoneType,
        receiveWhatsAppMessage: receiveWhatsAppMessage
    }
}

export const insuredData = data => {
    return {
        additionalData: data.additionalData,
        address: data.address,
        city: data.city,
        documentNumber: formatDocInput(data.documentNumber,data.type),
        name: data.name,
        neighborhood: data.neighborhood,
        number: data.number,
        state: data.state,
        type: data.type,
        zipCode: data.zipCode
    }
}

export const resumeData = data => {
    const {documentNumber, chosenInsurer, premium} = data
    if (documentNumber) {
        return {
          documentNumber: formatDocInput(data.documentNumber,documentTypes.COMPANY),
          type: documentTypes.COMPANY
        }
    } else if(chosenInsurer) {
        return {
            insurer: chosenInsurer,
            premium: premium
        }
    } else {
        return {}
    }
}

export const goodsData = data => {
    const goods = [];
    let formatedData = data.goods ? data.goods : data;
    
    formatedData.forEach(good => {
        goods.push(good)
    })
    return {
        "goods": goods
    };
}