export const LIFE_GENERAL_DATA_CHANGE = "LIFE_GENERAL_DATA_CHANGE";
export const LIFE_GENERAL_DATA_VALIDATE_ERROR = "LIFE_GENERAL_DATA_VALIDATE_ERROR";

export const validateLifeGeneralDataErrorMessage = (error) => {
  return {
    type: LIFE_GENERAL_DATA_VALIDATE_ERROR,
    error: error
  }
};

export const changeLifeGeneralData = (attr, value) => {
  return {
    type: LIFE_GENERAL_DATA_CHANGE,
    attr: attr,
    value: value
  }
};