import React from "react";
import SidebarItem from "./SidebarItem";
import "./style.less";

class SidebarItems extends React.Component {
  render() {
    const {
      activeRoute,
      filledRoutes,
      displayRoutes,
      itemsList,
      code
    } = this.props;
    return (
      <>
        <div className={`sidebar-items`}>
          {itemsList
            .map((listItem, index) => (
              <SidebarItem
                key={index}
                listItem={listItem}
                activeRoute={activeRoute}
                filledRoutes={filledRoutes}
                displayRoutes={displayRoutes}
                code={code}
              />
            ))}
        </div>
      </>
    );
  }
}

export default SidebarItems