//OrderConfig
export const getOrderConfiguration = (state) => state.order;
//Route Data
export const getProductData = (state, product) => state.products[product];
export const getRouteData = (state, product, identifier, entity = "data") => state.products[product][identifier][entity];
export const getSocketData = (state, product, entity = "data") => state.websocket[product][entity];
export const isRouteUpdatingPartially = (state, product, identifier) => state.products[product][identifier].updatingPartially;
//WebSocket
export const getWebsocketData = (state, product) => {
  if (state.hasOwnProperty(product))
    return state[product].data;
  return null;
};

export const getWebSocketConfig = (state, product) => {
  return state.hasOwnProperty(product);
};
