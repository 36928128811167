import { createSlice } from "@reduxjs/toolkit";

const trackingSlice = createSlice({
  name: "tracking",
  initialState: {
    utmParams: {},
  },
  reducers: {
    appendUtmParams(state, action) {
      state.utmParams = {
        ...state.utmParams,
        ...action.payload,
      };
    },
  },
});

export const { appendUtmParams } = trackingSlice.actions;

export default trackingSlice.reducer;
