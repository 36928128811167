import Request from "util/Request";

export const CARGO_SINGLE_BOARDING_INSURED_CHANGE = "CARGO_SINGLE_BOARDING_INSURED_CHANGE";
export const CARGO_SINGLE_BOARDING_INSURED_VALIDATE_ERROR = "CARGO_SINGLE_BOARDING_INSURED_VALIDATE_ERROR";
export const CARGO_SINGLE_BOARDING_INSURED_SEARCH_DATA_API = "CARGO_SINGLE_BOARDING_INSURED_SEARCH_DATA_API";
export const CARGO_SINGLE_BOARDING_INSURED_LOCAL_VALIDATION_ERROR = "CARGO_SINGLE_BOARDING_INSURED_LOCAL_VALIDATION_ERROR";

export const changeCargoSingleBoardingInsured = (attr, value) => {
  return {
    type: CARGO_SINGLE_BOARDING_INSURED_CHANGE,
    attr: attr,
    value: value
  }
};

export const validateSingleBoardingInsuredErrorMessage = (error) => {
  return {
    type: CARGO_SINGLE_BOARDING_INSURED_VALIDATE_ERROR,
    error: error
  }
};

export const searchApiCargoSingleBoardingInsured = (url, successCallback) => {
  return {
    type: CARGO_SINGLE_BOARDING_INSURED_SEARCH_DATA_API,
    request: Request.get(url),
    handleSuccess: successCallback
  }
};

export const localValidationSingleBoardingInsuredErrorMessage = (errors) => {
  return {
    type: CARGO_SINGLE_BOARDING_INSURED_LOCAL_VALIDATION_ERROR,
    errors: errors
  }
};