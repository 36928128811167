import { createSlice } from "@reduxjs/toolkit";
import { setLoading } from "../common";
import axios from "../../../util/Request";
import i18n from "../../../translation/i18n";
import { message } from "antd";
import {
  simulatorCompanyActivity,
  simulatorTransportType,
} from "../../../constants";

export const simulatorSlice = createSlice({
  name: "simulator",
  initialState: {
    errors: [],
    simulationData: {},
    dataToSimulate: {
      insuredType: simulatorCompanyActivity[0].value,
      transport: simulatorTransportType[0].value,
    },
    quotationData: {}
  },
  reducers: {
    setErrors: (state, action) => {
      state.errors = action.payload;
    },
    setSimulationData: (state, action) => {
      state.simulationData = action.payload;
    },
    appendDataToSimulate: (state, action) => {
      state.dataToSimulate = {
        ...state.dataToSimulate,
        ...action.payload,
      };
    },
    appendSimulationData: (state, action) => {
      state.simulationData = {
        ...state.simulationData,
        ...action.payload,
      };
    },
    setQuotationData: (state, action) => {
      state.quotationData = action.payload;
    }
  },
});

export const {
  setErrors,
  setIsLoading,
  setQuotationData,
  setSimulationData,
  appendSimulationData,
  appendDataToSimulate,
} = simulatorSlice.actions;

export default simulatorSlice.reducer;

// -----------------------------------------------------------

export const requestSimulation = () => (dispatch, getState) => {
  const {
    simulator: {
      dataToSimulate: {
        email,
        transport,
        insuredType,
        monthlyShipmentQuantity,
        averageAmountPerBoarding,
        maximumAmountPerBoarding,
      },
    },
  } = getState();
  dispatch(setLoading(true));
  return new Promise((resolve, reject) => {
    axios
      .post("/cargo/v1/simulation", {
        email,
        transport,
        insuredType,
        monthlyShipmentQuantity,
        averageAmountPerBoarding,
        maximumAmountPerBoarding,
      })
      .then((response) => {
        dispatch(setSimulationData(response.data));
        return resolve(response);
      })
      .catch((error) => {
        if (error.response.data.message)
          message.error(error.response.data.message);
        if (
          error.response.data.errors &&
          Array.isArray(error.response.data.errors)
        ) {
          dispatch(setErrors(error.response.data.errors));
        }
        return reject(error);
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  });
};

export const requestQuotation = (id) => (dispatch, getState) => {
  const {
    simulator: {
      dataToSimulate: { name, phoneNumber, documentNumber },
    },
  } = getState();
  dispatch(setLoading(true));
  return new Promise((resolve, reject) => {
    axios
      .put(`/cargo/v1/simulation/${id}`, {
        name,
        phoneNumber,
        documentNumber,
      })
      .then((response) => {
        message.success(i18n.t("simulator.quotation.success"));
        dispatch(setQuotationData(response.data));
        return resolve(response);
      })
      .catch((error) => {
        if (error.response.data.message)
          message.error(error.response.data.message);
        if (
          error.response.data.errors &&
          Array.isArray(error.response.data.errors)
        ) {
          dispatch(setErrors(error.response.data.errors));
        }
        return reject(error);
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  });
};
