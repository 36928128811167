//Libs
import produce from "immer"
//Action Types
import {
  INITIALIZE_SURETY_DATA,
  SURETY_UPDATE_API
} from "../../../actions";
//Custom helpers
import { actionTypes, formatActionType } from "../../helpers";
//Constants
const { SUCCESS } = actionTypes;

const initialState = {
  data: {
    hasExpressEmission: false
  }
};

//Reducer Function
const hasExpressEmission = produce((draft, action) => {
  switch (action.type) {
    case INITIALIZE_SURETY_DATA:
    case formatActionType(SURETY_UPDATE_API, SUCCESS): {
      if (action.data.hasOwnProperty("hasExpressEmission"))
        draft.data.hasExpressEmission = action.data.hasExpressEmission;
      break;
    }
    default:
      return;
  }
}, initialState);

export default hasExpressEmission;
