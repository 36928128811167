//Libs
import produce from "immer"
//Action Types
import {
  INITIALIZE_SINGLE_BOARDING_DATA,
  CARGO_SINGLE_BOARDING_UPDATE_API,
} from "../../../actions";
//Custom helpers
import { actionTypes, formatActionType, refreshState } from "../../helpers";
//Constants
const { SUCCESS } = actionTypes;
//Initial State
const initialState = {
  data: {
    salesRepresentative: {}
  }
};
//Reducer Function
const Final = produce((draft, action) => {
  switch (action.type) {
    case INITIALIZE_SINGLE_BOARDING_DATA: {
      refreshState("salesRepresentative", draft, action.data)
      break;
    }

    case formatActionType(CARGO_SINGLE_BOARDING_UPDATE_API, SUCCESS): {
      refreshState("salesRepresentative", draft, action.data)
      break;
    }

    default:
      return draft;
  }
}, initialState);

export default Final;