import Request from "util/Request";

export const SURETY_OBLIGEE_CHANGE = "SURETY_OBLIGEE_CHANGE";
export const SURETY_OBLIGEE_VALIDATE_ERROR = "SURETY_OBLIGEE_VALIDATE_ERROR";
export const SURETY_OBLIGEE_SEARCH_DATA_API = "SURETY_OBLIGEE_SEARCH_DATA_API";
export const SURETY_OBLIGEE_LOCAL_VALIDATION_ERROR = "SURETY_OBLIGEE_LOCAL_VALIDATION_ERROR";

export const changeSuretyObligee = (attr, value) => {
  return {
    type: SURETY_OBLIGEE_CHANGE,
    attr: attr,
    value: value
  }
};

export const validateSuretyObligeeErrorMessage = (error) => {
  return {
    type: SURETY_OBLIGEE_VALIDATE_ERROR,
    error: error
  }
};

export const searchApiSuretyObligee = (url, successCallback) => {
  return {
    type: SURETY_OBLIGEE_SEARCH_DATA_API,
    request: Request.get(url),
    handleSuccess: successCallback
  }
};

export const localValidationSuretyObligeeErrorMessage = (errors) => {
  return {
    type: SURETY_OBLIGEE_LOCAL_VALIDATION_ERROR,
    errors: errors
  }
};