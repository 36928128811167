export const CARGO_OPEN_POLICY_COVERAGES_CHANGE = "CARGO_OPEN_POLICY_COVERAGES_CHANGE";
export const CARGO_OPEN_POLICY_COVERAGES_VALIDATE_ERROR = "CARGO_OPEN_POLICY_COVERAGES_VALIDATE_ERROR";
export const CARGO_OPEN_POLICY_COVERAGES_LOCAL_VALIDATION_ERROR = "CARGO_OPEN_POLICY_COVERAGES_LOCAL_VALIDATION_ERROR";

export const validateOpenPolicyCoveragesErrorMessage = (error) => {
  return {
    type: CARGO_OPEN_POLICY_COVERAGES_VALIDATE_ERROR,
    error: error
  }
};

export const changeCargoOpenPolicyCoverages = (attr, value) => {
  return {
    type: CARGO_OPEN_POLICY_COVERAGES_CHANGE,
    attr: attr,
    value: value
  }
};

export const localValidationOpenPolicyCoveragesErrorMessage = (errors) => {
  return {
    type: CARGO_OPEN_POLICY_COVERAGES_LOCAL_VALIDATION_ERROR,
    errors: errors
  }
};