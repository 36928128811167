export const SURETY_GENERAL_DATA_CHANGE = "SURETY_GENERAL_DATA_CHANGE";
export const SURETY_GENERAL_DATA_VALIDATE_ERROR = "SURETY_GENERAL_DATA_VALIDATE_ERROR";

export const validateSuretyGeneralDataErrorMessage = (error) => {
  return {
    type: SURETY_GENERAL_DATA_VALIDATE_ERROR,
    error: error
  }
};

export const changeSuretyGeneralData = (attr, value) => {
  return {
    type: SURETY_GENERAL_DATA_CHANGE,
    attr: attr,
    value: value
  }
};