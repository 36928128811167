//Libs
import produce from "immer"
//Action Types
import {
  INITIALIZE_SINGLE_BOARDING_DATA,
  CARGO_SINGLE_BOARDING_DETAILS_CHANGE,
  CARGO_SINGLE_BOARDING_UPDATE_API,
  CARGO_SINGLE_BOARDING_DETAILS_VALIDATE_ERROR
} from "../../../actions";
//Custom helpers
import {actionTypes, formatActionType, refreshState} from "../../helpers";
//Constants
const {SUCCESS, ERROR} = actionTypes;

const initialState = {
  updatingPartially: false,
  errors: {},
  files: null,
  others: {
    onlyCarrierActivity: false,
    insuredName: '',
    hasTheft: false
  },
  data: {
    international:undefined,
    acceptPaymentSlipAndPolicyName: false,
    acceptPolicyAndPrivacy: false,
    acceptRiskManagement: false,
    additionalInformation: null,
    boardingDate: null,
    incoTerm: null,
    modals: []
  }
};

//Reducer Function
const Details = produce((draft, action) => {
  switch (action.type) {
    case INITIALIZE_SINGLE_BOARDING_DATA: {
      draft.data.international = action.data.international;
      draft.others.onlyCarrierActivity = action.data.onlyCarrierActivity;
      draft.others.insuredName = action.data.insured.name;
      draft.others.hasTheft = action.data.simulations.theft;
      refreshState("details",draft,action.data)
      if(action.data.files)
        draft.files = action.data.files
      break;
    }

    case formatActionType(CARGO_SINGLE_BOARDING_UPDATE_API, SUCCESS): {
      draft.data.international = action.data.international;
      draft.others.onlyCarrierActivity = action.data.onlyCarrierActivity;
      draft.others.insuredName = action.data.insured.name;
      draft.others.hasTheft = action.data.simulations.theft;
      refreshState("details",draft,action.data)
      break;
    }

    case formatActionType(CARGO_SINGLE_BOARDING_UPDATE_API, ERROR): {
      draft.errors = action.errors;
      break;
    }

    case CARGO_SINGLE_BOARDING_DETAILS_VALIDATE_ERROR: {
      delete draft.errors[action.error];
      break;
    }

    case CARGO_SINGLE_BOARDING_DETAILS_CHANGE:
      const {attr, value} = action;
      draft.data[attr] = value;
      break;
    default:
      return;
  }
}, initialState);

export default Details;
