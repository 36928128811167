//Libs
import produce from "immer"
//Action Types
import {
  INITIALIZE_SURETY_DATA,
  SURETY_DETAILS_CHANGE,
  SURETY_UPDATE_API,
  SURETY_DETAILS_VALIDATE_ERROR
} from "../../../actions";
//Custom helpers
import { actionTypes, formatActionType, refreshState } from "../../helpers";
//Constants
const { SUCCESS, ERROR } = actionTypes;

const initialState = {
  updatingPartially: false,
  errors: {},
  files: null,
  data: {

  }
};

//Reducer Function
const Details = produce((draft, action) => {
  switch (action.type) {
    case INITIALIZE_SURETY_DATA:
    case formatActionType(SURETY_UPDATE_API, SUCCESS): {
      refreshState("detail", draft, action.data)
      if(action.data.files)
        draft.files = action.data.files
      if(action.data.detail)
        draft.data.contactType = action.data.detail.contactType ? action.data.detail.contactType : 'INDIVIDUAL'
      break;
    }

    case formatActionType(SURETY_UPDATE_API, ERROR): {
      draft.errors = action.errors;
      break;
    }

    case SURETY_DETAILS_VALIDATE_ERROR: {
      delete draft.errors[action.error];
      break;
    }

    case SURETY_DETAILS_CHANGE:
      const { attr, value } = action;
      draft.data[attr] = value;
      break;
    default:
      return;
  }
}, initialState);

export default Details;
