//Libs
import produce from "immer"
//Action Types
import {
  INITIALIZE_OPEN_POLICY_DATA,
  CARGO_OPEN_POLICY_UPDATE_API,
  CARGO_OPEN_POLICY_QUOTATIONS_CHANGE, 
  CARGO_OPEN_POLICY_QUOTATIONS_VALIDATE_ERROR,
  CARGO_OPEN_POLICY_QUOTATIONS_CHOSE_INSURER,
  // CARGO_OPEN_POLICY_UPDATE_API_PARTIALLY,
} from "../../../actions";
//Custom helpers
import { actionTypes, formatActionType, refreshState } from "../../helpers";
//Constants
const { SUCCESS, ERROR } = actionTypes;

const initialState = {
  updatingPartially: false,
  errors: {},
  chosenInsurer:null,
  data: {
    accident: true,
    damage: false,
    documentNumber: null,
    lifeInsurance: false,
    fluvialRoute: false,
    other: false,
    otherDescription: null,
    theft: false,
    trackCleaning: false,
    type: 'COMPANY'
  }
};

//Reducer Function
const GeneralData = produce((draft, action) => {
  switch (action.type) {
    case INITIALIZE_OPEN_POLICY_DATA:
    case formatActionType(CARGO_OPEN_POLICY_UPDATE_API, SUCCESS): {
      refreshState("coverages", draft, action.data)
      break;
    }

    case formatActionType(CARGO_OPEN_POLICY_UPDATE_API, ERROR): {
      draft.errors = action.errors;
      break;
    }


    case CARGO_OPEN_POLICY_QUOTATIONS_VALIDATE_ERROR: {
      delete draft.errors[action.error];
      break;
    }

    case CARGO_OPEN_POLICY_QUOTATIONS_CHANGE:{
      const {attr, value} = action;
      draft.data[attr] = value;
      break;
    }
    case CARGO_OPEN_POLICY_QUOTATIONS_CHOSE_INSURER : {
      const { value } = action;
      console.log('value',value)
      draft.chosenInsurer = value;
      break;
    }
    default:
      return;
  }
}, initialState);

export default GeneralData;
