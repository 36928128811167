import React from "react";

export const PoweredBy = () => {
  if(window._env_.APP_PARTNER_ORIGIN){
    return (
      <div className="PoweredBy">
        Powered By
        <img alt="" src={require("assets/images/logo-white.svg")}/>
      </div>
    )
  }
  return null
}