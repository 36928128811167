//Libs
import produce from "immer"
//Action Types
import {
  INITIALIZE_SURETY_DATA,
  SURETY_PAYMENT_CHANGE,
  SURETY_UPDATE_API,
  SURETY_PAYMENT_VALIDATE_ERROR,
  SURETY_MINUTE_GET_PAYMENT_OPTION_ERROR
} from "../../../actions";
//Custom helpers
import { actionTypes, formatActionType } from "../../helpers";
//Constants
const { SUCCESS, ERROR } = actionTypes;

const initialState = {
  updatingPartially: false,
  errors: null,
  files: null,
  data: {
    installment: null,
    payments: null
  }
};

//Reducer Function
const Payment = produce((draft, action) => {
  switch (action.type) {
    case INITIALIZE_SURETY_DATA:
    case formatActionType(SURETY_UPDATE_API, SUCCESS): {
      if (action.data.hasOwnProperty("payments")){
        draft.data.payments = action.data.payments;
      }
      break;
    }

    case formatActionType(SURETY_UPDATE_API, ERROR): {
      draft.errors = action.errors;
      break;
    }

    case SURETY_PAYMENT_VALIDATE_ERROR: {
      delete draft.errors[action.error];
      break;
    }

    case SURETY_MINUTE_GET_PAYMENT_OPTION_ERROR:
      case formatActionType(SURETY_UPDATE_API, ERROR): {
        draft.errors = action.errors;
        break;
      }  

    case SURETY_PAYMENT_CHANGE:
      const { attr, value } = action;
      draft.data[attr] = value;
      break;
    default:
      return;
  }
}, initialState);

export default Payment;
