import Request from "util/Request";

export const CARGO_OPEN_POLICY_INSURED_CHANGE = "CARGO_OPEN_POLICY_INSURED_CHANGE";
export const CARGO_OPEN_POLICY_INSURED_VALIDATE_ERROR = "CARGO_OPEN_POLICY_INSURED_VALIDATE_ERROR";
export const CARGO_OPEN_POLICY_INSURED_SEARCH_DATA_API = "CARGO_OPEN_POLICY_INSURED_SEARCH_DATA_API";
export const CARGO_OPEN_POLICY_INSURED_LOCAL_VALIDATION_ERROR = "CARGO_OPEN_POLICY_INSURED_LOCAL_VALIDATION_ERROR";

export const changeCargoOpenPolicyInsured = (attr, value) => {
  return {
    type: CARGO_OPEN_POLICY_INSURED_CHANGE,
    attr: attr,
    value: value
  }
};

export const validateOpenPolicyInsuredErrorMessage = (error) => {
  return {
    type: CARGO_OPEN_POLICY_INSURED_VALIDATE_ERROR,
    error: error
  }
};

export const searchApiCargoOpenPolicyInsured = (url, successCallback) => {
  return {
    type: CARGO_OPEN_POLICY_INSURED_SEARCH_DATA_API,
    request: Request.get(url),
    handleSuccess: successCallback
  }
};

export const localValidationOpenPolicyInsuredErrorMessage = (errors) => {
  return {
    type: CARGO_OPEN_POLICY_INSURED_LOCAL_VALIDATION_ERROR,
    errors: errors
  }
};