export * from "./cargoSingleBoarding";
export * from "./cargoOpenPolicy";
export * from "./surety";
export * from "./life";

export const PREPARE_ORDER = "PREPARE_ORDER";
export const INITIALIZE_ORDER = "INITIALIZE_ORDER";
export const INITIALIZE_PRODUCT = "INITIALIZE_PRODUCT";

export const prepareOrder = () => {
  return {
    type: PREPARE_ORDER
  }
};

export const initializeOrder = (code, product) => {
  return {
    type: INITIALIZE_ORDER,
    code: code,
    product: product,
  }
};

export const initializeProduct = (product, data) => {
  return {
    type: INITIALIZE_PRODUCT,
    product: product,
    data: data
  }
};


