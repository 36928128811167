export default {
	app: {
		baseUri: window._env_.APP_URL || 'https://staging.web.mutuus.net/',
		landingPageUri: window._env_.SITE_URL || 'https://staging.mutuus.net/',
		releaseStage: window._env_.NODE_ENV || 'development',
		designationLetter: window._env_.DESIGNATION_LETTER || 'https://mutuus-seguros.s3-sa-east-1.amazonaws.com/files/surety/MUTUUS-CARTA-NOMEACAO-GARANTIA.doc',
		newFormUrl: window._env_.NEW_FORMS_URL || 'https://forms.mutuus.net',
	},
	api: {
		baseUri: window._env_.API_URL || 'https://staging.mutuus.net/',
	},
	monitoring: {
		bugsnag: '408042abf03e36e7967fe66f56fd8450',
		logrocket: 'hlqsse/mutuus',
	},
	environment: window._env_.NODE_ENV
};
