import React, { useState } from "react";
import Request from "util/Request";
import moment from "moment";
import { Modal, Button, Row } from 'antd';
import { privatePaths } from "constants/index";
import { fetchData } from "../appRedux/sagas/Order/helpers";
import { conformToMask } from "react-text-mask";
import { phoneMask, cnpjMask } from "components/TextMask/index";
//custom styles
import "./styles.less"

// new redux store
import newStore from "../store";

// Constants
export const productsInfo = {
  surety: 'seguro-garantia',
  life: 'seguro-de-vida',
  cargoSingleBoarding: 'seguro-de-carga/unico',
  cargoOpenPolicy: 'seguro-de-carga/multiplo'
}

export function getUrlParam(param) {
  const url = new URL(window.location.href);
  const paramValue = url.searchParams.get(param);
  const urlParam = paramValue ? decodeURI(paramValue) : null;
  return urlParam;
}

export function generateOrderCode(product, type, body) {
  const version = {
    'surety': 'v2',
    'life': 'v1', 
    'cargo': 'v1'
  };
  const partner = window._env_.APP_PARTNER_ORIGIN ? window._env_.APP_PARTNER_ORIGIN.toUpperCase() : null;

  const { tracking: { utmParams } } = newStore.getState();
  if (utmParams && utmParams.utm_source) {
    body = {
      ...body,
      origin: utmParams.utm_source
    }
  }
  
  if(product === 'cargo'){
    body = {
      ...body,
      type: type,
      partner: partner,
    }
  } else {
    body = {
      ...body,
      partner: partner,
    }
  }
  return new Promise((resolve, reject) => {
    Request.post("/" + product + "/" + version[product], body)
    .then(response => {
      return resolve(response.data);
    })
    .catch(error => {
      return reject(error);
    });
  })
}

export function extractUrlConfig(url = window.location.href) {
  url = url.split('?');
  url = url[0].split('/');
  const filter = new RegExp("/[]/");
  if (url.filter(filter.test, /[?,&]/)[0]) {
    url.pop();
  }
  url = url.filter(function (el) {
    return el !== '';
  })
  url.splice(0, 2);
  const length = url.length;
  let data = {
    product: url[length - 3],
    identifier: url[length - 2],
    code: url[length - 1] || null
  };
  if(data.code){
    if(data.code.includes('seguro-')){
      data.code = null;
    }
  }
  data.product = url[0]
  if (url[length - 4]) {
    if (url[length - 4].includes('seguro-')) {
      data.product = url[length - 4] + '/' + url[length - 3];
    }
  }
  return data;
}

export const getProductPathByName = name => {
  return productsInfo[name]
}

export const getProductNameByPath = path => {
  return Object.keys(productsInfo).find(name => productsInfo[name] === path);
}

export const getIdentifierByPath = path => {
  return Object.keys(privatePaths).find(name => privatePaths[name].replace(/\//g, '') === path);
}

export const getOldFormCode = (productID) => {
  if(!localStorage) {
    return null;
  }
  
  const oldForm = localStorage.getItem("oldForm-" + productID);
  if(oldForm) {
    const { code, date } = JSON.parse(oldForm);
    let limitDate = moment(date);
    let daysToLimit = 30;
    if (productID === 'seguro-garantia') {
      daysToLimit = 10;
    }
    limitDate.add(daysToLimit, 'd');
    const currentDate = moment();
    if(currentDate.isBefore(limitDate)){
      return code;
    }
  }
  return null;
}

export const newFormCode = (productID,newCode) => {
  removeFormCode(productID);
  return localStorage.setItem("oldForm-" + productID,JSON.stringify({
    code: newCode,
    date: new Date()
  }));
}

export const removeFormCode = (productID) => {
  return localStorage.removeItem("oldForm-" + productID);
}

const handleGenerateCode = async (product, type, body) => {
  const response = await generateOrderCode(product, type, body);
  return response ? response.code : null;
}

export const processRedirection = async ({ product, productID, type, initialPath, history, initializeOrder }) => {
  const currentPath = extractUrlConfig().product;
  const email = getUrlParam('email');
  const emailParam = email ? '/?email=' + email : '';
  const oldCode = getOldFormCode(productID);
  let redirectUrl;
  let newCode;

  const handleNewForm = async (body) => {
    newCode = await handleGenerateCode(product, type, body);
    if(newCode) {
      redirectUrl = initialPath + newCode + emailParam;
      newFormCode(productID, newCode);
      return initialRedirect(newCode, productID, redirectUrl, history, initializeOrder);
    }
    return null;
  }

  const handleOldForm = () => {
    redirectUrl = initialPath + oldCode + emailParam;
    return initialRedirect(oldCode, productID, redirectUrl, history, initializeOrder);
  }

  const getInitialProductData = async () => {
    return await fetchData({
      code: oldCode,
      product: productID
    })
  }

  const initialProductData = await getInitialProductData()

  const popupDataItem = (data, label, styleName) => {
    return(
      <p className={styleName}>{label}:&nbsp;  
        <span className="gx-content-text-bold user-data-text">{data}</span>
      </p>
    )
  }

  const handleCancelInterest = (modal) => {
    modal.destroy()
    history.push('/')
  }

  const PopupContent = ({data, productType, date, modalRef}) => {

    const [isLoading, setIsLoading] = useState({
      continue: false,
      useOtherData: false,
      newQuotation: false
    });

    const requestNewForm = async (requestData, requestType) => {
      setIsLoading({
        ...isLoading,
        [requestType]: true
      });
      await handleNewForm(requestData);
      return setIsLoading({
        ...isLoading,
        [requestType]: false
      });
    };

    let {name, phoneNumber, email, documentNumber} = data
    
    if (name == null && phoneNumber == null && email == null && documentNumber == null) {
      return(
        <>
          <p className="gx-mb-0 gx-subtitle">Vamos continuar de onde paramos?</p>
          <Row type="flex" justify="space-between" className="initial-popup-buttons gx-mt-3">
            <Button onClick={handleOldForm} type="primary" className="with-margin-right">continuar o preenchimento</Button>
            <Button onClick={() => handleCancelInterest(modalRef)}>voltar</Button>
          </Row>
        </>
      )
    }

    if(phoneNumber) phoneNumber = conformToMask(phoneNumber, phoneMask, { guide: false }).conformedValue;
    if(documentNumber) documentNumber = conformToMask(documentNumber, cnpjMask, { guide: false }).conformedValue;

    date = date.split('-')

    switch (productType) {
      case 'surety':
      case 'cargoSingleBoarding':
        return(
          <>
            <p className="gx-m-0 gx-subtitle">Identificamos uma solicitação anterior que foi preenchida com os seguintes dados:</p>
            <div className="gx-content">
              {name ? popupDataItem(name, "Nome de contato", "gx-mb-1") : null}
              {phoneNumber ? popupDataItem(phoneNumber, "Whatsapp", "gx-mb-1") : null}
              {email ? popupDataItem(email, "Melhor e-mail de contato", "gx-mb-1") : null}
              {documentNumber ? popupDataItem(documentNumber, "CNPJ da sua empresa", "gx-mb-0") : null}
            </div>
            <Row type="flex" justify="space-between" className="initial-popup-buttons gx-mt-3">
              <Button disabled={isLoading.useOtherData} loading={isLoading.continue} onClick={() => requestNewForm(data, "continue")} type="primary">continuar com estes dados</Button>
              <Button disabled={isLoading.continue} loading={isLoading.useOtherData} onClick={() => requestNewForm(null, "useOtherData")}>não, usar outros dados</Button>
            </Row>
          </>
        )
      case 'life':
      case 'cargoOpenPolicy':
        return(
          <>
            <p className="gx-m-0 gx-subtitle">{`Identificamos que no dia ${date[2]}/${date[1]} você fez o preenchimento de uma solicitação de cotação.`}</p>
            <Row type="flex" justify="space-between" className="initial-popup-buttons gx-mt-3">
              <Button disabled={isLoading.newQuotation} onClick={handleOldForm} type="primary">continuar o preenchimento do antigo</Button>
              <Button loading={isLoading.newQuotation} onClick={() => requestNewForm(null, "newQuotation")}>cotar novo seguro</Button>
            </Row>
          </>
        )
    }
  }

  const modalProps = {
    className: 'mu-modal-confirm',
    title: 'Opa, parece que você já tem um pedido de cotação em andamento!',
    content: 'Deseja continuar o preenchimento dele ou criar uma nova cotação?',
    okText: 'Criar uma nova cotação',
    cancelText: 'Continuar o preenchimento',
    onOk() { handleNewForm() },
    icon: false,
  }
  
  if(oldCode && initialProductData.hasOwnProperty('initial')){

    const productType = getProductNameByPath(productID)
    const oldForm = localStorage.getItem("oldForm-" + productID);
    let {date} = JSON.parse(oldForm)
    date = moment(date).format("YYYY-MM-DD")

    const modal = Modal.confirm()
    return modal.update({
      ...modalProps,
      className: 'mu-modal-confirm gx-text-left',
      title: <p className="gx-m-0 gx-title gx-text-left">Que bom te ver de novo!</p>,
      content: <PopupContent data={initialProductData.initial} productType={productType} date={date} modalRef={modal} />,
      maskClosable: true,
      okButtonProps: {style:{display:'none'}},
      cancelButtonProps: {style:{display:'none'}},
    });
  } else {
    newCode = await handleGenerateCode(product, type);
    if(newCode) {
      redirectUrl = initialPath + newCode + emailParam;
      newFormCode(productID, newCode);
      initialRedirect(newCode, productID, redirectUrl, history, initializeOrder);      
    }
  }
};

const initialRedirect = (code, productID, redirectUrl, history, initializeOrder) => {
    initializeOrder(code, productID);
    return window.location.href = redirectUrl;
}

export const codeValidate = (code) => {
  if(code) {
    const match = code.match('^MUU.*');
    return match;
  }
  return null
}

export const routesWithoutHiddenSteps = (routes, steps) => {
  let cleannedRoutes = routes;

  cleannedRoutes = cleannedRoutes.filter(route => {
      let isUnhidden;
      if(steps.length){
        steps.map(step => {
          if(!route.hidden || route.initialPath === step) {
            return isUnhidden = step            
          }
          return null;
        })
      } else {
        isUnhidden = !route.hidden;
      }
      return isUnhidden;
    })
  return cleannedRoutes;
}
export const getAcceptedInsurer = (quotationData) => {
  let acceptedQuotation 
  quotationData.quotations.data.forEach((quotation)=>{
    if(quotation.accepted){
      acceptedQuotation = quotation.insurer
    }
  })

  return acceptedQuotation
}

export const checkCurrentStep = (step) => {
  const { identifier } = extractUrlConfig();
  return identifier === step;
}