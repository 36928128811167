import React, { Component } from "react";
import { connect } from "react-redux";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import CustomScrollbars from "util/CustomScrollbars";
import { whatsappNumber } from "util/config";
import SidebarLogo from "./SidebarLogo";

import Auxiliary from "util/Auxiliary";
import SidebarItems from "components/SidebarItems";
//Selectors
import { getOrderConfiguration, getRouteData } from "../../appRedux/selectors";
//Routes
import allRoutes from "routes/routes";
//Components
import { PoweredBy } from "./components"
//helpers
import { isOpening, checkCurrentStep, formatMaskInput } from "helpers"

import "../../fontawesome"
import { Divider } from "antd";
import { phoneNumbers, courtTypes } from "../../constants";

//custom styles
import "./styles.less"

class SidebarContent extends Component {
  render() {
    const { 
      orderConfig: {
        code, 
        product
      }, 
      suretyData,
      lifeData,
      singleBoardingData,
      openPolicyData,
      activeRoute, 
      filledRoutes, 
      displayRoutes, 
      productName,
      suretyTermData
    } = this.props;

    const getSalesRepresentative = () =>{
      let products = [
        suretyData,
        lifeData,
        singleBoardingData,
        openPolicyData
      ]
      products = products.filter(prod => {
        return prod.hasOwnProperty('name');
      })
      return products.length && products[0]

    } 
    
    const productId = product || productName;
    let productRoutes = [];
    if (productId && allRoutes.private.hasOwnProperty(productId)){
      productRoutes = allRoutes.private[productId].routes;
      if(productId === "seguro-garantia"){
        productRoutes.forEach(item => {
          if(item.urlIdentifier === "obligee-data"){
            item.title = courtTypes.includes(suretyTermData.type) ? "Dados do reclamante" : "Dados do segurado";
          }
        });
      }
    }

    const ContactBox = (props) => {
      const { salesRepresentative } = props;
      if(salesRepresentative) {

        if(activeRoute.includes("seguro-garantia") || activeRoute.includes("multiplo") || activeRoute.includes("unico")){
          if(
            activeRoute.includes("tipo-seguro") || activeRoute.includes("dados-garantia") ||
            activeRoute.includes("dados-iniciais") || activeRoute.includes("dados-gerais")){
            return null
          }
        }

        if(activeRoute.includes("/seguro-de-vida/dados-iniciais/")) return null 
        
        return (
          <>
            {!isOpening() && !checkCurrentStep('dados-iniciais') &&
              <span class="oppeingHoursAlert gx-mt-0">
                <FontAwesomeIcon icon={['far', 'clock']}/> 
                <span>Nosso horário de atendimento é das 9h e 18h de segunda à sexta. Você será atendido por nossa equipe assim que possível.</span>
              </span>
            }
            <div className="gx-sidebar-contact-box withPerson">
              <div className="title">Precisa de ajuda?</div>
              <div className="gx-d-flex">
                <img src={salesRepresentative.photo} alt="" className="avatar"/>
                <div className="content gx-text-center">
                  <h3>Fale com <span>{salesRepresentative.name}</span></h3>
                  <div className="gx-d-flex gx-mb-2 gx-justify-content-around">
                    <a target="_blank" href="tel:+5530038387"
                    rel="noopener noreferrer"
                    className="contact-link contact-link-flip"
                    >
                      <FontAwesomeIcon icon={['fa', 'phone']}/>
                    </a>              
                    <a target="_blank" href={`https://wa.me/${formatMaskInput(salesRepresentative.whatsapp)}`}
                    rel="noopener noreferrer"
                    className="contact-link"
                    >
                      <FontAwesomeIcon icon={['fab', 'whatsapp']}/>
                    </a>
                    <a target="_blank" href={`mailto:${salesRepresentative.email}`}
                    rel="noopener noreferrer"
                    className="contact-link"
                    >
                      <FontAwesomeIcon icon={['far', 'envelope']}/>
                    </a>
                  </div>
                  <div className="contact-box-divider"/>
                  <p className="phone">Tel.: {phoneNumbers[0]}</p>
                  <p className="phone">Ramal {salesRepresentative.extensionNumber}</p>
                </div>
              </div>
            </div>
          </>
        )
      }
      return null
    }
    
    return (
      <Auxiliary>
        <SidebarLogo/>
        <div className="gx-sidebar-content">
          {productId && productId !== 'outros-seguros' && productId !== 'tipo-seguro' && productId !== 'seguro-de-carga' ? (
            <>
              <CustomScrollbars className="gx-layout-sider-scrollbar custom-sidebar-content">
                <SidebarItems
                  className="gx-sidebar-progress-list"
                  itemsList={productRoutes}
                  activeRoute={activeRoute}
                  filledRoutes={filledRoutes}
                  displayRoutes={displayRoutes}
                  code={code}/>
              </CustomScrollbars>
              <PoweredBy />
              <ContactBox salesRepresentative={getSalesRepresentative()}/>
            </>
          ) : (
            <div className="helloMsg">
              <p className="title">Olá! É muuuito bom <br/>ver você aqui!</p>
              <p>Vamos juntos pra você se sentir seguro de verdade?</p>
              <p>Para sugerir um seguro do seu jeito, precisamos de algumas informações, basta seguir preenchendo os dados solicitado.</p>
              <p><b>Vamos lá!</b></p>
              <PoweredBy />
            </div>
          )}
        </div>
      </Auxiliary>
    );
  }
}

SidebarContent.propTypes = {};
const mapStateToProps = (state) => {
  const {settings, sidebar} = state;
  
  return {
    locale: settings.locale,
    orderConfig: getOrderConfiguration(state),
    suretyData: getRouteData(state, 'seguro-garantia', 'final'),
    lifeData: getRouteData(state, 'seguro-de-vida', 'final'),
    singleBoardingData: getRouteData(state, 'seguro-de-carga/unico', 'final'),
    openPolicyData: getRouteData(state, 'seguro-de-carga/multiplo', 'final'),
    suretyTermData: getRouteData(state, 'seguro-garantia', "term"),
    activeRoute: sidebar.activeRoute,
    filledRoutes: sidebar.filledRoutes,
    displayRoutes: sidebar.displayRoutes,
  }
};
export default connect(mapStateToProps)(SidebarContent);

