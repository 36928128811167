import moment from "moment";
import { openingHours, closingDays } from "constants/index"

export function getDaysDifference(startDate, endDate) {
    if (startDate && endDate)
        return moment(endDate).diff(startDate, 'days');
    return null;
}

export function getCurrentTime() {
    return new Date();
}

export function getFormattedDate(date, format = "DD/MM/YYYY") {
    if (date)
        return moment(date).format(format);
    return null;
}

export function formatDate(date, type = "front") {
    if (date) {
        const formattedDate = date.split(/-/g);
        if (type === "front") {
            const [yy, mm, dd] = formattedDate;
            return `${dd}-${mm}-${yy}`;
        } else if (type === "back") {
            const [dd, mm, yy] = formattedDate;
            return `${yy}-${mm}-${dd}`;
        }
    }
    return null;
}

export function updateDate(currentDate, days, type) {
    if (type === "remove")
        days = (-1) * days;
    currentDate = moment(currentDate).clone();
    currentDate.add(days, 'days');
    return currentDate;
}

export const isOpening = () => {
    const now = moment();
    const { startTime, endTime } = openingHours;
    const dayCheck = closingDays.filter(day => {
        moment.locale('en');
        return now.format('dddd') !== day;
    })
    
    if(closingDays.length === dayCheck.length){
        return now.isBetween(startTime , endTime)
    } else {
        return false;
    }
}