//Redux Bindings
import {combineReducers} from 'redux'
//Reducers
import initialData from "./initialData";
import general from "./general";
import resume from "./resume";
import coverages from "./coverages";
import details from "./details";
import lives from "./lives";
import customerData from "./customerData";
import final from "./final";

//Merge Reducers
export default combineReducers({
  initial: initialData,
  general: general,
  resume: resume,
  coverages: coverages,
  details: details,
  'insured-lives': lives,
  customer: customerData,
  final: final
})
