//Libs
import produce from "immer"
//Action Types
import {
  INITIALIZE_OPEN_POLICY_DATA,
  CARGO_OPEN_POLICY_UPDATE_API,
  CARGO_OPEN_POLICY_HISTORIC_CHANGE, 
  CARGO_OPEN_POLICY_HISTORIC_CUSTOM_CHANGE, 
  CARGO_OPEN_POLICY_HISTORIC_VALIDATE_ERROR,
} from "../../../actions";
//Custom helpers
import { actionTypes, formatActionType, refreshState } from "../../helpers";
//Constants
const { SUCCESS, ERROR } = actionTypes;

const initialState = {
  updatingPartially: false,
  errors: {},
  data: {
    accidents: [],
    additionalInformation: null,
    contractedCoverages: null,
    hasPreviousInsurance: false,
    previousInsurer: null,
    maximumGuaranteeLimit: null,
    minimumPremium: null,
    monthlyInvoiceAmount: null,
    rateOrDiscountApplied: null
  }
};
//Reducer Function
const GeneralData = produce((draft, action) => {
  switch (action.type) {
    case INITIALIZE_OPEN_POLICY_DATA:
    case formatActionType(CARGO_OPEN_POLICY_UPDATE_API, SUCCESS): {
      refreshState("historic", draft, action.data)
      break;
    }

    case formatActionType(CARGO_OPEN_POLICY_UPDATE_API, ERROR): {
      draft.errors = action.errors;
      break;
    }

    case CARGO_OPEN_POLICY_HISTORIC_VALIDATE_ERROR: {
      delete draft.errors[action.error];
      break;
    }

    case CARGO_OPEN_POLICY_HISTORIC_CHANGE: {
      const {attr, value} = action;
      draft.data[attr] = value;
      break;
    }

    case CARGO_OPEN_POLICY_HISTORIC_CUSTOM_CHANGE: {
      const { act, config } = action;
      const { attr, value, index } = config;

      switch (act) {
        case "EDIT": {
          draft.data.accidents[index][attr] = value;
          break;
        }
        case "CREATE": {
          draft.data.accidents.push(value);
          break;
        }
        case "DELETE": {
          draft.data.accidents.splice(index, 1);
          break;
        }
        default: {
          draft.data[attr] = value;
          break;
        }
      }
      break;
    }

    default:
      return;
  }
}, initialState);

export default GeneralData;
