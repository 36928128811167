export const SURETY_QUOTATIONS_CHANGE = "SURETY_QUOTATIONS_CHANGE";
export const SURETY_QUOTATIONS_VALIDATE_ERROR = "SURETY_QUOTATIONS_VALIDATE_ERROR";
export const SURETY_QUOTATIONS_LOCAL_VALIDATION_ERROR = "SURETY_QUOTATIONS_LOCAL_VALIDATION_ERROR";

export const validateSuretyQuotationsErrorMessage = (error) => {
  return {
    type: SURETY_QUOTATIONS_VALIDATE_ERROR,
    error: error
  }
};

export const changeSuretyQuotations = (attr, value) => {
  return {
    type: SURETY_QUOTATIONS_CHANGE,
    attr: attr,
    value: value
  }
};

export const localValidationSuretyQuotationsErrorMessage = (errors) => {
  return {
    type: SURETY_QUOTATIONS_LOCAL_VALIDATION_ERROR,
    errors: errors
  }
};