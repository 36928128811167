export const CARGO_OPEN_POLICY_OPERATIONAL_CHANGE = "CARGO_OPEN_POLICY_OPERATIONAL_CHANGE";
export const CARGO_OPEN_POLICY_OPERATIONAL_VALIDATE_ERROR = "CARGO_OPEN_POLICY_OPERATIONAL_VALIDATE_ERROR";
export const CARGO_OPEN_POLICY_OPERATIONAL_CUSTOM_CHANGE = "CARGO_OPEN_POLICY_OPERATIONAL_CUSTOM_CHANGE";

export const validateOpenPolicyOperationalErrorMessage = (error) => {
  return {
    type: CARGO_OPEN_POLICY_OPERATIONAL_VALIDATE_ERROR,
    error: error
  }
};

export const changeCargoOpenPolicyOperational = (attr, value) => {
  return {
    type: CARGO_OPEN_POLICY_OPERATIONAL_CHANGE,
    attr: attr,
    value: value
  }
};

export const changeCargoOpenPolicyOperationalCustom = (act, config) => {
  return {
    type: CARGO_OPEN_POLICY_OPERATIONAL_CUSTOM_CHANGE,
    act: act,
    config: config
  }
};