export const CARGO_OPEN_POLICY_QUOTATIONS_CHANGE = "CARGO_OPEN_POLICY_QUOTATIONS_CHANGE";
export const CARGO_OPEN_POLICY_QUOTATIONS_VALIDATE_ERROR = "CARGO_OPEN_POLICY_QUOTATIONS_VALIDATE_ERROR";
export const CARGO_OPEN_POLICY_QUOTATIONS_CHOSE_INSURER = "CARGO_OPEN_POLICY_QUOTATIONS_CHOSE_INSURER";

export const validateOpenPolicyQuotationsErrorMessage = (error) => {
  return {
    type: CARGO_OPEN_POLICY_QUOTATIONS_VALIDATE_ERROR,
    error: error
  }
};


export const changeCargoOpenPolicyQuotations = (attr, value) => {
  return {
    type: CARGO_OPEN_POLICY_QUOTATIONS_CHANGE,
    attr: attr,
    value: value
  }
};

export const setCargoQuotationInsurer = (value) => {
  return {
    type: CARGO_OPEN_POLICY_QUOTATIONS_CHOSE_INSURER,
    value: value
  }
};