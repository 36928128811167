export const CARGO_SINGLE_BOARDING_GENERAL_DATA_CHANGE = "CARGO_SINGLE_BOARDING_GENERAL_DATA_CHANGE";
export const CARGO_SINGLE_BOARDING_GENERAL_DATA_VALIDATE_ERROR = "CARGO_SINGLE_BOARDING_GENERAL_DATA_VALIDATE_ERROR";
export const CARGO_SINGLE_BOARDING_REQUEST_CONVERTION = "CARGO_SINGLE_BOARDING_REQUEST_CONVERTION";
export const CARGO_SINGLE_BOARDING_REGISTER_CONVERTED = "CARGO_SINGLE_BOARDING_REGISTER_CONVERTED";
export const REGISTER_CONVERTION_LOADING = "REGISTER_CONVERTION_LOADING";
export const REGISTER_CONVERTION_MODAL = "REGISTER_CONVERTION_MODAL";

export const validateSingleBoardingGeneralDataDataErrorMessage = (error) => {
  return {
    type: CARGO_SINGLE_BOARDING_GENERAL_DATA_VALIDATE_ERROR,
    error: error
  }
};

export const changeCargoSingleBoardingGeneralData = (attr, value) => {
  return {
    type: CARGO_SINGLE_BOARDING_GENERAL_DATA_CHANGE,
    attr: attr,
    value: value
  }
};

export const requestSingleBoardingConvertion = () => {
  return {
    type: CARGO_SINGLE_BOARDING_REQUEST_CONVERTION,
  }
};

export const registerSingleBoardingConverted = (value) => {
  return {
    type: CARGO_SINGLE_BOARDING_REGISTER_CONVERTED,
    value: value
  }
};

export const setConvertionLoading = (value) => {
  return {
    type: REGISTER_CONVERTION_LOADING,
    value: value
  }
};

export const setConvertionModal = (value) => {
  return {
    type: REGISTER_CONVERTION_MODAL,
    value: value
  }
};