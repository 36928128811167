//Libs
import produce from "immer"
//Action Types
import {
  INITIALIZE_OPEN_POLICY_DATA,
  CARGO_OPEN_POLICY_INSURED_CHANGE,
  CARGO_OPEN_POLICY_UPDATE_API,
  CARGO_OPEN_POLICY_INSURED_SEARCH_DATA_API,
  CARGO_OPEN_POLICY_INSURED_VALIDATE_ERROR,
  CARGO_OPEN_POLICY_INSURED_LOCAL_VALIDATION_ERROR,
} from "../../../actions";
//Custom helpers
import { actionTypes, formatActionType, refreshState } from "../../helpers";
//Constants
const { SUCCESS, ERROR } = actionTypes;

const initialState = {
  updatingPartially: false,
  errors: {},
  data: {
    additionalData: null,
    address: null,
    city: null,
    documentNumber: null,
    name: null,
    neighborhood: null,
    number: null,
    state: null,
    type: 'COMPANY',
    zipCode: null
  }
};

//Reducer Function
const Insured = produce((draft, action) => {
  switch (action.type) {
    case INITIALIZE_OPEN_POLICY_DATA:
    case formatActionType(CARGO_OPEN_POLICY_UPDATE_API, SUCCESS): {
      refreshState("insured", draft, action.data)
      break;
    }

    case formatActionType(CARGO_OPEN_POLICY_UPDATE_API, ERROR): {
      draft.errors = action.errors;
      break;
    }

    case CARGO_OPEN_POLICY_INSURED_VALIDATE_ERROR: {
      if (action.error) {
        delete draft.errors[action.error];
      } else {
        draft.errors = [];
      }
      break;
    }

    case formatActionType(CARGO_OPEN_POLICY_INSURED_SEARCH_DATA_API, SUCCESS): {
      draft.data = {
        ...action.data,
        type: action.data.personType,
        name: action.data.companyName,
      };
      break;
    }
    
    case formatActionType(CARGO_OPEN_POLICY_INSURED_SEARCH_DATA_API, ERROR): {
      break;
    }
    
    case CARGO_OPEN_POLICY_INSURED_LOCAL_VALIDATION_ERROR: {
      draft.errors = action.errors;
      break;
    }

    case CARGO_OPEN_POLICY_INSURED_CHANGE:
      const { attr, value } = action;
      draft.data[attr] = value;
      break;

    default:
      return;
  }
}, initialState);

export default Insured;