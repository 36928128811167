//Libs
import produce from "immer"
//Action Types
import {
  INITIALIZE_LIFE_DATA,
  LIFE_UPDATE_API,
  LIFE_COVERAGES_CHANGE,
  LIFE_COVERAGES_VALIDATE_ERROR
} from "../../../actions";
//Custom helpers
import { actionTypes, formatActionType, refreshState } from "../../helpers";
//Constants
const { SUCCESS, ERROR } = actionTypes;

const initialState = {
  updatingPartially: false,
  errors: {},
  data: {
    assistances: [],
    coverages: [
      "DEATH"
    ]
  }
};

//Reducer Function
const GeneralData = produce((draft, action) => {
  switch (action.type) {
    case INITIALIZE_LIFE_DATA:
    case formatActionType(LIFE_UPDATE_API, SUCCESS): {
      refreshState("coverages", draft, action.data)
      break;
    }

    case formatActionType(LIFE_UPDATE_API, ERROR): {
      draft.errors = action.errors;
      break;
    }

    case LIFE_COVERAGES_VALIDATE_ERROR: {
      delete draft.errors[action.error];
      break;
    }

    case LIFE_COVERAGES_CHANGE:
      const { attr, value } = action;
      draft.data[attr] = value;
      break;
    default:
      return;
  }
}, initialState);

export default GeneralData;
