//Constants
import {GLOBAL_LOADING} from "../actions";
/*TODO : Change the action  type based on GLOBAL_LOADING*/
const actionTypes = [
  '_LOADING',
  '_SUCCESS',
  '_ERROR',
];

export default (store) => {
  const {dispatch} = store;
  return next => (action) => {
    actionTypes.forEach((item, key) => {
      const {type} = action;
      if (type.endsWith(item)) {
        const STATE = (key === 0) ? 'STARTED' : 'FINISHED';
        const entity = type.replace(item, '');
        dispatch({
          type: `${GLOBAL_LOADING}_${STATE}`,
          entity: entity
        });
      }
    });
    next(action);
  };
};
