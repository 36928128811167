//Routes Config
import allRoutes from "routes/routes";
import { publicPaths } from "constants/index";

export const socketSend = socketData => {
	const { 
		apiIdentifier,
		socket, 
		product, 
		code, 
		data, 
		initializeWebSocket, 
		sendWebSockedMessages,
		onBlur,
		onBlurParams
	} = socketData;

	const productDetails = allRoutes.private[product];
	const productConfig = productDetails.config;

	if (socket.initialize) {
		initializeWebSocket(product);
	}
	if (socket.send) {
		const param = (product) => {
			if(publicPaths.openPolicy.includes(product)){
				return apiIdentifier;
			}
			if(publicPaths.surety.includes(product)){
				return data.initial.data.documentNumber;
			}
			return null;
		}
		const payload = productConfig.socket.formatPayload(code, data, param(product));
		if (payload) {
			sendWebSockedMessages(product, payload);
		}
	}
	if(onBlur){
		return onBlur(onBlurParams);
	} else {
		return null;
	}
}