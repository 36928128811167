import Request from "util/Request";

export * from "./initialData";
export * from "./general";
export * from "./details";
export * from "./obligee";
export * from "./quotations";
export * from "./minute";
export * from "./payment";
export * from "./websocket";

//Order Action Types
export const INITIALIZE_SURETY_DATA = "INITIALIZE_SURETY_DATA";
export const SURETY_UPDATE_API = "SURETY_UPDATE_API";

/*Order Actions*/

export const updateApiSurety = (url, data, successCallback) => {
  return {
    type: SURETY_UPDATE_API,
    request: Request.put(url, data),
    handleSuccess: successCallback
  }
};

export const initializeSuretyData = (data) => {
  return {
    type: INITIALIZE_SURETY_DATA,
    data: data
  }
};

//Order Single Boarding Action Types
export const SURETY_UPDATE_API_PARTIALLY = "SURETY_UPDATE_API_PARTIALLY";

export const updateApiSuretyPartially = (code, entity, data) => {
  return {
    entity: entity,
    type: SURETY_UPDATE_API_PARTIALLY,
    request: Request.patch(`surety/v2/${code}`, {[entity]: data})
  }
};