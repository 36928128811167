//Redux Bindings
import {combineReducers} from 'redux'
//Reducers
import initialData from "./initialData";
import generalData from "./generalData";
import final from "./final";
import resume from "./resume";
import goods from "./goods";
import insured from "./insured";
import details from "./details";

//Merge Reducers
export default combineReducers({

  initial: initialData,
  general: generalData,
  resume: resume,
  goods: goods,
  insured: insured,
  details: details,
  final: final
})
