import { formatMaskInput, normalizeCurrency } from "helpers";

export const lifeGeneralData = data => {
	const {
		averageAgeEmployees,
		capitalInsuredPerson,
		employeesAmount,
        reasonTakeInsurance,
        hasBiddingWaiver
	} = data;

    return {
        averageAgeEmployees: formatMaskInput(averageAgeEmployees),
        capitalInsuredPerson: normalizeCurrency(capitalInsuredPerson),
        employeesAmount: formatMaskInput(employeesAmount),
        reasonTakeInsurance: reasonTakeInsurance,
        hasBiddingWaiver: hasBiddingWaiver
    }
}

export const lifeCoverages = data => {
    return data;
}

export const lifeDetails = data => {
    return data;
}

export const livesData = data => {
    return data;
}