//Redux Bindings
import {combineReducers} from "redux";
//Reducers
import cargoSingleBoarding from './cargoSingleBoarding';
import cargoOpenPolicy from './cargoOpenPolicy';
import surety from './surety';
import life from './life';

export default combineReducers({
  'seguro-de-vida': life,
  'seguro-de-carga/unico': cargoSingleBoarding,
  'seguro-de-carga/multiplo': cargoOpenPolicy,
  'seguro-garantia': surety,
});
