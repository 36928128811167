//Libs
import produce from "immer"
//Action Types
import {
  INITIALIZE_SINGLE_BOARDING_DATA,
  CARGO_SINGLE_BOARDING_UPDATE_API,
  CARGO_SINGLE_BOARDING_GOODS_CHANGE,
  CARGO_SINGLE_BOARDING_GOODS_VALIDATE_ERROR,
} from "../../../actions";
//Custom helpers
import {actionTypes, formatActionType, refreshState} from "../../helpers";
//Constants
const {SUCCESS, ERROR} = actionTypes;
//Initial State
const initialState = {
  updatingPartially: false,
  errors: {},
  others: {
    cargoTotalAmount: null
  },
  data: {
    goods:[
      {
        category: null,
        product: null,
        subCategory: null,
        amount: null,
        usedMerchandise: null
      }
    ]
  }
};

//Reducer Function
const Goods = produce((draft, action) => {
  switch (action.type) {
    case INITIALIZE_SINGLE_BOARDING_DATA: {
      draft.data.goods[0].amount = action.data.general.cargoTotalAmount;
      refreshState("goods",draft,action.data)
      break;
    }

    case formatActionType(CARGO_SINGLE_BOARDING_UPDATE_API, SUCCESS): {
      draft.data.goods[0].amount = action.data.general.cargoTotalAmount;
      refreshState("goods",draft,action.data)
      break;
    }

    case formatActionType(CARGO_SINGLE_BOARDING_UPDATE_API, ERROR): {
      draft.errors = action.errors;
      break;
    }

    case CARGO_SINGLE_BOARDING_GOODS_CHANGE: {
      const {act, config} = action;
      const {index, value, attr, resetFields} = config;
      switch (act) {
        case "EDIT": {
          if (resetFields.length) {
            resetFields.forEach(field => {
              draft.data.goods[index][field] = null;
            })
          }
          draft.data.goods[index][attr] = value;
          break;
        }
        case "CREATE": {
          draft.data.goods.push(value);
          break;
        }
        case "DELETE": {
          draft.data.goods.splice(index, 1);
          break;
        }
        // no default
      }
      break;
    }

    case CARGO_SINGLE_BOARDING_GOODS_VALIDATE_ERROR: {
      delete draft.errors[action.error];
      break;
    }
    
    default:
      return;
  }
}, initialState);

export default Goods;

