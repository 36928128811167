export const lifeCoverageOptions = {
  familyAssistances: [
    {
      value: "SPOUSAL_ACCIDENTAL_DEATH",
      label: "Morte acidental cônjuge",
      tooltip:
        "Possibilita a contratação da cobertura Básica de Morte para o cônjuge do segurado principal, garantindo uma indenização caso esse venha a falecer por morte acidental.",
    },
    {
      value: "CHILDREN_ACCIDENTAL_DEATH",
      label: "Morte acidental filho",
      tooltip:
        "Possibilita a contratação da cobertura Básica de Morte para os filhos dependentes do segurado principal, garantindo uma indenização caso esses venham a falecer por morte acidental.",
    },
    {
      value: "SPOUSAL_DEATH",
      label: "Morte cônjuge",
      tooltip:
        "Possibilita a contratação da cobertura Básica de Morte para o cônjuge do segurado principal, garantindo uma indenização caso esse venha a falecer por morte natural ou acidental.",
    },
    {
      value: "CHILDREN_DEATH",
      label: "Morte filhos",
      tooltip:
        "Possibilita a contratação da cobertura Básica de Morte para os filhos dependentes do segurado principal, garantindo uma indenização caso esses venham a falecer por morte natural ou acidental.",
    },
    {
      value: "CONGENITAL_DISORDER",
      label: "Doenças congênitas dos filhos",
      tooltip:
        "Garante ao segurado o pagamento de uma indenização, em caso de diagnóstico de doença congênita incapacitante que comprometa a desenvolvimento neuropsicomotor de seu(s) filho(s).",
    },
    {
      value: "SPOUSAL_PERMANENT_DISABILITY",
      label: "Invalidez permanente do cônjuge",
      tooltip:
        "Garante ao segurado uma indenização em caso de invalidez permanente parcial ou total por acidente do cônjuge, com consequencia exclusiva de acidente pessoal coberto.",
    },
    {
      value: "FAMILIAR_FUNERAL_ALLOWANCE",
      label: "Assistência Funeral Familiar",
      tooltip:
        "Garante ao beneficiario a reembolso das despesas com funeral ou a disponibilização dos serviços de assistência funeral, até o limite do capital segurado, seja decorrente de causa natural ou acidental.",
    },
  ],
  assistances: [
    {
      value: "INDIVIDUAL_FUNERAL_ALLOWANCE",
      label: "Assistência Funeral Individual",
      tooltip: "Garante ao beneficiario a reembolso das despesas com funeral ou a disponibilização dos serviços de assistência funeral, até o limite do capital segurado, seja decorrente de causa natural ou acidental.",
      mostRequested: true,
    },
    {
      value: "FOOD_STAMPS_ASSISTANCE",
      label: "Cesta Básica",
      tooltip: "Garante a entrega de cestas básicas à família do segurado principal em caso de falecimento.",
      mostRequested: true,
    },
    {
      value: "MATERNITY_ALLOWANCE",
      label: "Assistência natalidade",
      tooltip: "Pacote customizado contemplando produtos de higiêne para mamãe e bebê e também cesta alimentação para a mamãe, de acordo com a Cesta contratada.",
    },
    {
      value:"GENERAL_EXPENSES",
      label:"Despesas diversas",
      tooltip:"Garante ao beneficiario o pagamento de uma indenização, em consequência exclusiva de morte de segurado, seja decorrente de causa natural ou acidental."
    },
  ],
  coverages: [
    {
      value: "DEATH",
      label: "Morte",
      tooltip:
        "Garante ao beneficiario o pagamento de uma indenização, em caso de morte do segurado por causas naturais ou acidentais",
      required: true,
      disabled: true,
    },
    {
      value: "ACCIDENTAL_DEATH",
      label: "Morte acidental",
      tooltip:
        "Garante o pagamento de indenização em caso de morte do segurado por acidente.",
      mostRequested: true,
    },
    {
      value: "PERMANENT_DISABILITY",
      label: "Invalidez permanente por acidente",
      tooltip:
        "Garante o pagamento de indenização, proporcional ao grau de invalidez, em caso de acidente que resulte em perda ou impotência funcional de algum membro ou órgão.",
    },
    {
      value: "PRESUMPTIVE_DISABILITY",
      label: "invalidez funcional permanente por doença",
      tooltip: "Garante o pagamento de indenização, no valor total do capital segurado, em caso de invalidez funcional permanente e total por doença.",
    },
    {
      value: "SEVERANCE_PAY",
      label: "Verbas rescisórias",
      tooltip: "Garante ao estipulante o pagamento de uma indenização, a título de reembolso das despesas com pagamento de verbas rescisórias, respeitados os limites estabelecidos contratualmente, em consequência exclusiva de morte do segurado, seja decorrente de causa natural ou acidental.",
    },
    {
      value: "HOSPITAL_CASH",
      label: "Diárias de internação hospitalar",
      tooltip:
        "Garante o pagamento de indenização proporcional ao periodo de internação do segurado.",
    },
    {
      value: "MEDICAL_EXPENSES",
      label: "Despesas médicas hospitalares e odontológicas",
      tooltip:
        "Garante o reembolso limitado ao capital segurado das despesas médicas hospitalares e odontológicas em caso de acidente que o segurado tiver com o seu tratamento sob orientação médica.",
    },
    {
      value: "EMPLOYMENT_INSURANCE_SICKNESS",
      label: "Antecipação especial por doença",
      tooltip:
        "Garante a antecipação do pagamento do capital segurado relativo à cobertura de Morte nos casos em que apresentar quadro clinico irreversível, em fase terminal, em decorrência das doenças devidamente cobertas, observados os riscos excluídos.",
    },
  ],
};
