import Request from "util/Request";

export * from "./initialData";
export * from "./generalData";
export * from "./resume";
export * from "./coverages";
export * from "./details";
export * from "./lives";
export * from "./customerData";

//Order Action Types
export const INITIALIZE_LIFE_DATA = "INITIALIZE_LIFE_DATA";
export const LIFE_UPDATE_API = "LIFE_UPDATE_API";

/*Order Actions*/

export const updateApiLife = (url, data, successCallback) => {
  return {
    type: LIFE_UPDATE_API,
    request: Request.put(url, data),
    handleSuccess: successCallback
  }
};

export const initializeLifeData = (data) => {
  return {
    type: INITIALIZE_LIFE_DATA,
    data: data
  }
};

//Order Single Boarding Action Types
export const LIFE_UPDATE_API_PARTIALLY = "LIFE_UPDATE_API_PARTIALLY";

export const updateApiLifePartially = (code, entity, data) => {
  return {
    entity: entity,
    type: LIFE_UPDATE_API_PARTIALLY,
    request: Request.patch(`life/v1/${code}`, {[entity]: data})
  }
};