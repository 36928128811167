import React, { Component } from "react";
import moment from "moment";
import "moment/locale/pt-br";
import { Layout } from "antd";
import Sidebar from "../Sidebar/index";
import App from "routes/index";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
//Selectors
import { getOrderConfiguration } from "../../appRedux/selectors";
//Actions
import {
  changeActiveRoute,
  fillRoute
} from "appRedux/actions";
import { interceptor, getProductPathByName } from "helpers";
import {
  NAV_STYLE_DEFAULT_HORIZONTAL,
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  TAB_SIZE
} from "../../constants/ThemeSetting";
import allRoutes from "routes/routes";
import { ExitPopup } from "components/ExitPopup";

moment.locale("pt-br");
const { Content } = Layout;

export class MainApp extends Component {
  componentDidMount() {
    interceptor(3, 5000);
  }

  getContainerClass = navStyle => {
    switch (navStyle) {
      case NAV_STYLE_DEFAULT_HORIZONTAL:
        return "gx-container-wrap";
      default:
        return "";
    }
  };

  getSidebar = (navStyle, width, data, product) => {
    if (width < TAB_SIZE) {
      return <Sidebar data={data} product={product} />;
    }
    switch (navStyle) {
      case NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR:
        return <Sidebar data={data} product={product} />;
      default:
        return null;
    }
  };

  componentDidUpdate() {
    const {
      orderConfig: {
        product
      },
      actions: {
        handleActive,
        handleFill
      }
    } = this.props;
    let path = window.location.pathname.split('?');
    path = path[0].split('/');
    path = path.filter(function (el) {
      return el !== '';
    })
    const len = path.length;
    const hasName = (name) => {
      return path[0] === getProductPathByName(name);
    }
    if (len > 3 || hasName('surety') || hasName('life')) {
      path.pop();
      const itemPath = '/' + path.join('/') + '/';
      const productRoutes = allRoutes.private[product] ? allRoutes.private[product].routes : [];
      const routeIndex = productRoutes.findIndex(route => route.initialPath === itemPath);

      if (routeIndex) {
        for (var i = routeIndex - 1; i >= 0; i--) {
          handleFill(productRoutes[i].initialPath)
        }
      }
      handleFill(itemPath);
      handleActive(itemPath);
    }
  }

  render() {
    const {
      match,
      width,
      navStyle
    } = this.props;

    return (
      <>
        <Layout className="gx-app-layout">
          {this.getSidebar(
            navStyle,
            width,
            this.state,
            this.props.location.pathname.split("/", 3)[1]
          )}
          <Layout>
            <Content className={`gx-layout-content ${this.getContainerClass(navStyle)}`}>
              <ExitPopup />
              <App match={match} />
            </Content>
          </Layout>
        </Layout>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { settings, sidebar } = state;
  const { width, navStyle } = settings;
  return {
    width,
    navStyle,
    activeRoute: sidebar.activeRoute,
    orderConfig: getOrderConfiguration(state)
  };
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        handleActive: changeActiveRoute,
        handleFill: fillRoute
      }, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MainApp);
