import { formatValue } from "helpers";

export const onBlur = ({formatResponse, handleUpdatePartially, code, apiIdentifier, data}) => {
    const apiData = formatResponse(data);
    handleUpdatePartially(code, apiIdentifier, apiData);
};

export const onChange = (actions, errors, attr, isValue) => (event) => {
	if(errors){
		if (errors.hasOwnProperty(attr)){
			actions.validateError(attr);
		}
	}

	const value = formatValue(event,isValue);

	actions.handleChange(attr, value);
};

export const onSwitchChange = (attr, childFields, actions) => (event) => {
	const value = event;
	if(!value && childFields){
		childFields.map((item) => {
			return actions.handleChange(item, null);
		})
	}
	actions.handleChange(attr, value);
}