//Utils
import asyncComponent from "util/asyncComponent";
//Constants
import { publicPaths, privatePaths } from "constants/index"
//RequestsFormatter
import {
	initialData, 
	insuredData,
	goodsData,
	singleBoardingResumeData,
	singleBoardingGeneralData,
	singleBoardingDetailsData
} from "helpers/requestsFormatter";

export const singleBoarding = {
	routes: [
	  {
	    exact: true,
	    title: "Seus dados",
	    apiIdentifier: "initial",
	    urlIdentifier: "initial-data",
	    initialPath: publicPaths.singleBoarding + privatePaths.initial,
	    path: publicPaths.singleBoarding + privatePaths.initial + ":id",
	    formatResponse: (data) => initialData(data),
	    component: asyncComponent(() => import('containers/Products/cargoSingleBoarding/InitialData'))
	  },
	  {
	    exact: true,
	    title: "Dados gerais",
	    apiIdentifier: "general",
	    urlIdentifier: "general-data",
	    initialPath: publicPaths.singleBoarding + privatePaths.general,
	    path: publicPaths.singleBoarding + privatePaths.general + ":id",
	    formatResponse: (data) => singleBoardingGeneralData(data),
	    component: asyncComponent(() => import('containers/Products/cargoSingleBoarding/GeneralData'))
	  },
	  {
	    exact: true,
	    title: "Estimativa de preço",
	    apiIdentifier: "resume",
	    urlIdentifier: "resume-data",
	    initialPath: publicPaths.singleBoarding + privatePaths.resume,
	    path: publicPaths.singleBoarding + privatePaths.resume + ":id",
	    formatResponse: (resumeData, initialData) => singleBoardingResumeData(resumeData, initialData),
	    component: asyncComponent(() => import('containers/Products/cargoSingleBoarding/QuotationSummary/index'))
	  },
	  {
	    exact: true,
	    title: "Dados da mercadoria",
	    apiIdentifier: "goods",
	    urlIdentifier: "goods-data",
	    formatResponse: (data) => goodsData(data),
	    initialPath: publicPaths.singleBoarding + privatePaths.goods,
	    path: publicPaths.singleBoarding + privatePaths.goods + ":id",
	    component: asyncComponent(() => import('containers/Products/cargoSingleBoarding/GoodsData'))
	  },
	  {
	    exact: true,
	    title: "Dados do segurado",
	    apiIdentifier: "insured",
	    urlIdentifier: "insured-data",
	    formatResponse: (data) => insuredData(data),
	    initialPath: publicPaths.singleBoarding + privatePaths.insured,
	    path: publicPaths.singleBoarding + privatePaths.insured + ":id",
	    component: asyncComponent(() => import('containers/Products/cargoSingleBoarding/InsuredData'))
	  },
	  {
	    exact: true,
	    title: "Dados do embarque",
	    apiIdentifier: "details",
	    urlIdentifier: "details-data",
	    formatResponse: (data) => singleBoardingDetailsData(data),
	    initialPath: publicPaths.singleBoarding + privatePaths.shipping,
	    path: publicPaths.singleBoarding + privatePaths.shipping + ":id",
	    component: asyncComponent(() => import('containers/Products/cargoSingleBoarding/CargoDetails'))
	  },
	  {
	    exact: true,
	    title: "Final",
	    apiIdentifier: "final",
	    urlIdentifier: "final",
	    initialPath: publicPaths.singleBoarding + privatePaths.final,
	    path: publicPaths.singleBoarding + privatePaths.final + ":id",
	    component: asyncComponent(() => import('containers/Products/cargoSingleBoarding/FinalMessage'))
	  }
	],
	config: {
	  product: "cargo",
	  productId: 'seguro-de-carga/unico',
	  type: "SINGLE_BOARDING",
	  getUrl: function (identifier, code) {
	    return `cargo/v1/single-boarding/${code}/${identifier}`;
	  }
	}
}