//Utils
import asyncComponent from "util/asyncComponent";
//Constants
import { publicPaths, privatePaths } from "constants/index"
//RequestsFormatter
import {
  initialData, 
  resumeData, 
  lifeGeneralData,
  lifeCoverages,
  lifeDetails,
  livesData,
  insuredData
} from "helpers/requestsFormatter";

export const life = {
	routes: [
	  {
	    exact: true,
	    title: "Seus dados",
	    apiIdentifier: "initial",
	    urlIdentifier: "initial-data",
	    initialPath: publicPaths.life + privatePaths.initial,
	    path: publicPaths.life + privatePaths.initial + ":id",
	    formatResponse: (data) => initialData(data),
	    component: asyncComponent(() => import('containers/Products/life/InitialData'))
	  },
	  {
	    exact: true,
	    title: "Dados Básicos",
	    apiIdentifier: "general",
	    urlIdentifier: "general-data",
	    initialPath: publicPaths.life + privatePaths.general,
	    path: publicPaths.life + privatePaths.general + ":id",
	    formatResponse: (data) => lifeGeneralData(data),
	    component: asyncComponent(() => import('containers/Products/life/GeneralData'))
	  },
	  {
	    exact: true,
	    title: "Simulação",
	    apiIdentifier: "resume",
	    urlIdentifier: "resume-data",
	    initialPath: publicPaths.life + privatePaths.resume,
	    path: publicPaths.life + privatePaths.resume + ":id",
	    formatResponse: (data) => resumeData(data),
	    component: asyncComponent(() => import('containers/Products/life/Quotations/index'))
	  },
	  {
	    exact: true,
	    title: "Coberturas",
	    apiIdentifier: "coverages",
	    urlIdentifier: "coverages-data",
	    initialPath: publicPaths.life + privatePaths.coverages,
	    path: publicPaths.life + privatePaths.coverages + ":id",
	    formatResponse: (data) => lifeCoverages(data),
	    component: asyncComponent(() => import('containers/Products/life/Coverages'))
	  },
	  {
	    exact: true,
	    title: "Valores da cobertura",
	    apiIdentifier: "details",
	    urlIdentifier: "details-data",
	    initialPath: publicPaths.life + privatePaths.details,
	    path: publicPaths.life + privatePaths.details + ":id",
	    formatResponse: (data) => lifeDetails(data),
	    component: asyncComponent(() => import('containers/Products/life/Details'))
	  },
	  {
	    exact: true,
	    title: "Relação de vidas",
	    apiIdentifier: "insured-lives",
	    urlIdentifier: "insured-lives-data",
	    initialPath: publicPaths.life + privatePaths.lives,
	    path: publicPaths.life + privatePaths.lives + ":id",
	    formatResponse: (data) => livesData(data),
	    component: asyncComponent(() => import('containers/Products/life/Lives'))
	  },
	//   {
	//     exact: true,
	//     title: "Dados do estipulante",
	//     apiIdentifier: "customer",
	//     urlIdentifier: "customer-data",
	//     initialPath: publicPaths.life + privatePaths.customer,
	//     path: publicPaths.life + privatePaths.customer + ":id",
	//     formatResponse: (data) => insuredData(data),
	//     component: asyncComponent(() => import('containers/Products/life/CustomerData'))
	//   },
	  {
	    exact: true,
	    title: "Final",
	    apiIdentifier: "final",
	    urlIdentifier: "final",
	    initialPath: publicPaths.life + privatePaths.final,
	    path: publicPaths.life + privatePaths.final + ":id",
	    component: asyncComponent(() => import('containers/Products/life/FinalMessage'))
	  }
	],
	config: {
	  product: "life",
	  productId: 'seguro-de-vida',
	  getUrl: function (identifier, code) {
	    return `life/v1/${code}/${identifier}`;
	  }
	}
}