import Request from "util/Request";

export * from "./initialData";
export * from "./generalData";
export * from "./resume";
export * from "./goods";
export * from "./insured";
export * from "./details";

//Order Action Types
export const CARGO_SINGLE_BOARDING_UPDATE_API = "CARGO_SINGLE_BOARDING_UPDATE_API";
export const INITIALIZE_SINGLE_BOARDING_DATA = "INITIALIZE_SINGLE_BOARDING_DATA";

/*Order Actions*/

export const updateApiCargoSingleBoarding = (url, data, successCallback) => {
  return {
    type: CARGO_SINGLE_BOARDING_UPDATE_API,
    request: Request.put(url, data),
    handleSuccess: successCallback
  }
};

export const initializeSingleBoardingData = (data) => {
  return {
    type: INITIALIZE_SINGLE_BOARDING_DATA,
    data: data
  }
};

//Order Single Boarding Action Types
export const CARGO_SINGLE_BOARDING_UPDATE_API_PARTIALLY = "CARGO_SINGLE_BOARDING_UPDATE_API_PARTIALLY";

export const updateApiCargoSingleBoardingPartially = (code, entity, data) => {
  return {
    entity: entity,
    type: CARGO_SINGLE_BOARDING_UPDATE_API_PARTIALLY,
    request: Request.patch(`cargo/v1/single-boarding/${code}`, {[entity]: data})
  }
};
