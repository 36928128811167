import Request from "../../../../util/Request";

export const SURETY_MINUTE_CHANGE = "SURETY_MINUTE_CHANGE";
export const SURETY_MINUTE_GET_FILE = "SURETY_MINUTE_GET_FILE";
export const SURETY_MINUTE_GET_FILE_ERROR = "SURETY_MINUTE_GET_FILE_ERROR";
export const SURETY_MINUTE_ACCEPT = "SURETY_MINUTE_ACCEPT";

export const changeSuretyMinute = (attr, value) => {
  return {
    type: SURETY_MINUTE_CHANGE,
    attr: attr,
    value: value
  }
};

export const getSuretyMinuteFile = (url, successCallback) => {
  return {
    type: SURETY_MINUTE_GET_FILE,
    request: Request.get(url),
    handleSuccess: successCallback
  }
};

export const putSuretyAcceptMinute = (url, data, successCallback) => {
  return {
    type: SURETY_MINUTE_ACCEPT,
    request: Request.put(url,data),
    handleSuccess: successCallback
  }
};

export const getSuretyMinuteFileError = (error) => {
  return {
    type: SURETY_MINUTE_GET_FILE_ERROR,
    error: error
  }
};

