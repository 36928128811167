//Utils
import asyncComponent from "util/asyncComponent";
import { 
  life, 
  surety, 
  openPolicy, 
  singleBoarding 
} from "./products"
//Constants
import { publicPaths } from "constants/index"

const publicRoutes = [
  {
    exact: true,
    path: publicPaths.type,
    identifier: "formSelection",
    childRoutes: [
      'seguro-garantia',
      'seguro-de-vida'
    ],
    component: asyncComponent(() => import('../containers/Products/FormSelection'))
  },
  {
    exact: true,
    path: publicPaths.cargo,
    identifier: "productSelection",
    childRoutes: [
      'seguro-de-carga/unico',
      'seguro-de-carga/multiplo'
    ],
    component: asyncComponent(() => import('../containers/Products/ProductSelection'))
  },
  {
    exact: true,
    path: publicPaths.surety,
    identifier: "newSuretyForm",
    childRoutes: [],
    component: asyncComponent(() => import('../containers/Products/NewForm'))
  },
  {
    exact: true,
    path: publicPaths.singleBoarding,
    identifier: "newSingleBoarding",
    childRoutes: [],
    component: asyncComponent(() => import('../containers/Products/NewForm'))
  },
  {
    exact: true,
    path: publicPaths.openPolicy,
    identifier: "newOpenPolicy",
    childRoutes: [],
    component: asyncComponent(() => import('../containers/Products/NewForm'))
  },
  // {
  //   exact: true,
  //   path: publicPaths.life,
  //   identifier: "newLife",
  //   childRoutes: [],
  //   component: asyncComponent(() => import('../containers/Products/NewForm'))
  // },
  {
    exact: true,
    path: publicPaths.suretyTerms,
    identifier: "specialPages",
    childRoutes: [],
    component: asyncComponent(() => import('../containers/Products/surety/TermsAndConditions'))
  },
  {
    exact: true,
    path: publicPaths.others,
    identifier: "otherProducts",
    childRoutes: [],
    component: asyncComponent(() => import('../containers/Products/OtherProducts'))
  }
];

const privateRoutes = {
  'seguro-de-vida': life,
  'seguro-garantia': surety,
  'seguro-de-carga/multiplo': openPolicy,
  'seguro-de-carga/unico': singleBoarding,
  'seguro-de-carga': {
    routes: [],
  }
};

export default {
  private: privateRoutes,
  public: publicRoutes
}

