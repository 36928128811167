import Request from "util/Request";

export const LIFE_CUSTOMER_DATA_CHANGE = "LIFE_CUSTOMER_DATA_CHANGE";
export const LIFE_CUSTOMER_DATA_VALIDATE_ERROR = "LIFE_CUSTOMER_DATA_VALIDATE_ERROR";
export const LIFE_CUSTOMER_DATA_LOCAL_VALIDATION_ERROR = "LIFE_CUSTOMER_DATA_LOCAL_VALIDATION_ERROR";
export const LIFE_CUSTOMER_DATA_SEARCH_DATA_API = "LIFE_CUSTOMER_DATA_SEARCH_DATA_API";

export const validateLifeCustomerDataErrorMessage = (error) => {
  return {
    type: LIFE_CUSTOMER_DATA_VALIDATE_ERROR,
    error: error
  }
};

export const changeLifeCustomerData = (attr, value) => {
  return {
    type: LIFE_CUSTOMER_DATA_CHANGE,
    attr: attr,
    value: value
  }
};

export const searchApiLifeCustomer = (url, successCallback) => {
  return {
    type: LIFE_CUSTOMER_DATA_SEARCH_DATA_API,
    request: Request.get(url),
    handleSuccess: successCallback
  }
};

export const localValidationLifeCustomerDataErrorMessage = (errors) => {
  return {
    type: LIFE_CUSTOMER_DATA_LOCAL_VALIDATION_ERROR,
    errors: errors
  }
};