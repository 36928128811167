import MaskService from "react-masked-text/src/mask-service";
import { documentValidator } from "helpers";
import moment from "moment";
import {
  dateFormat,
  dateApiFormat
} from "components/AppConfig/index";
import { replace } from "connected-react-router";

export const currencies = {
  BRA:'R$',
  USD: 'US$',
  EUR: '€'
}

export const gradualValues = {
  frequency: [
    {
      value:'Muito frequentemente',
      key:'VERY_OFTEN'
    },
    {
      value:'Frequentemente',
      key:'OFTEN'
    },
    {
      value:'Ocasionalmente',
      key:'OCCASIONALLY'
    },
    {
      value:'Raramente',
      key:'RARELY'
    },
  ],
}

export const formatPercentage = (value) =>{
  if(value){
    let replaced = JSON.stringify(value).replace('.',',') + "%"
    return replaced
  }
}

export function formatMaskInput(str) {
  if(!str){
    return null;
  }
  if(typeof str === 'number'){
    return Number(str);
  }
  const numbers = str.match(/[0-9]/g);
  return numbers ? numbers.join("") : null;
}

export function formatMoney(value, currency = "BRA") {
  if (value !== null) {
    value = MaskService.toMask(
      "money",
      parseFloat(value).toFixed(2),
      {
        unit: "",
        separator: ",",
        delimiter: "."
      });
  }
  return `${currencies[currency]} ${value}`
}

export const sortSimulations = (simulations) => {
  if(simulations){
    return [...simulations].sort((a, b) => {
      if (a.premium === b.premium) {
        if (a.rate < b.rate) {
          return -1;
        } else if (a.rate > b.rate) {
          return 1;
        } else {
          return 0;
        }
      } else if(a.premium < b.premium){
        return -1;
      } else if(a.premium > b.premium){
        return 1;
      } else if (a.status === 'ERROR') {
        return 1;
      } else if (b.status === 'ERROR') {
        return -1;
      } else if (a.rate === undefined) {
        return 1;
      } else if (b.rate === undefined) {
        return -1;
      } else {
        return 0;
      }
    })
  } else {
    return {}
  }
}

export const formatDocInput = (str,type) => {
  if(documentValidator(str,type)){
    return formatMaskInput(str)
  } else {
    return null
  }
}

export const formatValue = (event, isValue) => {
  let value = event;
  const isDate = moment.isMoment(value);

  if (!isValue) {
    if(isDate){
      value = moment(value, dateFormat).format(dateApiFormat);
    } else if(event.value !== undefined){
      value = event.value;
    } else if (event.target){
      if (event.target.type === "checkbox"){
        value = event.target.checked
      } else {
        value = event.target.value;
      }
    }
    if (typeof value === "object" && !isDate){
      value = event.target.value;
    }
  }

  return value;
}

export const formatNumberDigits = (number, modifier) => {
  let value = number;
  if(modifier !== null){
    value = value + modifier;
  }
  if(value < 10) {
    value = '0' + value;
  }
  return value;
}

export const sortList = (list, attribute) => {
  const attr = attribute || 'value';
  let formatedList;

  if(list){
    formatedList = list.sort((a, b) => a[attr].localeCompare(b[attr]));
  }

  return formatedList
}

export const normalizeCurrency = (num) => {
  if(num){
    num = num.toString();
    const regex = /\d{1,3}(?:\.\d{3})*([,.]\d*|)/,
          numLength = num.length,
          hasSymbol = num.match(/[$€]/),
          hasDot = num.search('\\.'),
          hasComma = num.search(',');
    if(hasSymbol){
      num = (num.match(regex)) ? num.match(regex)[0] : num;
    }
    if(!(hasDot === (numLength - 3) || hasDot === (numLength - 2) || hasComma === -1) || hasSymbol ) {
      num = parseFloat(num.split('.').join('').replace(',','.')).toFixed(2)
    } else {
      num = parseFloat(num).toFixed(2)
    }
    return num
  }
}

export const phoneMask = [ "(", /[1-9]/, /\d/, ")", " ", /\d/, /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/ ];
export const cnpjMask = [ /\d/, /\d/, ".", /\d/, /\d/, /\d/, ".", /\d/, /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/ ];
export const cpfMask = [ /\d/, /\d/, /\d/, ".", /\d/, /\d/, /\d/, ".", /\d/, /\d/, /\d/,"-", /\d/, /\d/ ];

export const getDaysByYears = (years) => {
  const now = moment();
  const year = moment().add(years * 365, "d");
  return year.diff(now, "d");
}

export const sortPayments = (a, b) => {
  if ( a.installment < b.installment ){
    return -1;
  }
  if ( a.installment > b.installment ){
    return 1;
  }
  return 0
}