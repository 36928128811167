import React, { Fragment, lazy } from "react";
// router
import { Route } from "react-router-dom";
// redux
import { Provider } from "react-redux";
import store from "../../store";
// paths
import { PATH_SIMULATOR } from "./paths";

export const renderRoutes = (routes = []) =>
  routes.map((route, index) => {
    const Component = route.component || Fragment;
    const Guard = route.guard || Fragment;
    const Layout = route.layout || Fragment;
    return (
      <Route
        key={index}
        path={route.path}
        exact={route.exact}
        render={(props) => (
          <Provider store={store}>
            <Guard>
              <Layout>
                {route.routes ? (
                  renderRoutes(route.routes)
                ) : (
                  <Component {...props} />
                )}
              </Layout>
            </Guard>
          </Provider>
        )}
      />
    );
  });

export const routes = [
  {
    exact: true,
    path: PATH_SIMULATOR.root,
    component: lazy(() => import("../../containers/Simulator")),
  },
  {
    exact: true,
    path: "/seguro-de-carga",
    component: lazy(() => import("../../containers/Products/RedirectToCargo")),
  },
  {
    exact: true,
    path: "/seguro-de-vida",
    component: lazy(() => import("../../containers/Products/RedirectToLife")),
  },
  {
    exact: true,
    path: "/seguro-garantia",
    component: lazy(() => import("../../containers/Products/RedirectToSurety")),
  },
];
