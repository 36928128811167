export const LIFE_COVERAGES_CHANGE = "LIFE_COVERAGES_CHANGE";
export const LIFE_COVERAGES_VALIDATE_ERROR = "LIFE_COVERAGES_VALIDATE_ERROR";

export const validateLifeCoveragesErrorMessage = (error) => {
  return {
    type: LIFE_COVERAGES_VALIDATE_ERROR,
    error: error
  }
};

export const changeLifeCoverages = (attr, value) => {
  return {
    type: LIFE_COVERAGES_CHANGE,
    attr: attr,
    value: value
  }
};