//Libs
import produce from "immer"
//Action Types
import {
  INITIALIZE_SURETY_DATA,
  SURETY_MINUTE_CHANGE,
  SURETY_UPDATE_API,
  SURETY_MINUTE_ACCEPT,
  SURETY_MINUTE_GET_FILE_ERROR
} from "../../../actions";
//Custom helpers
import { actionTypes, formatActionType } from "../../helpers";
//Constants
const { SUCCESS, ERROR } = actionTypes;

const initialState = {
  updatingPartially: false,
  errors: {},
  chosenInsurer: null,
  data: {
    url: null,
    premium: null,
    proposalAccepted: false,
    acceptedAt: null,
  }
};

//Reducer Function
const Minute = produce((draft, action) => {
  switch (action.type) {
    case INITIALIZE_SURETY_DATA:
    case formatActionType(SURETY_UPDATE_API, SUCCESS): {
      if (action.data.hasOwnProperty("minute")){
        draft.data.url = action.data.minute.url;
        draft.data.premium = action.data.minute.premium;
        draft.data.proposalAccepted = action.data.minute.proposalAccepted;
        draft.data.acceptedAt = action.data.minute.acceptedAt;
      }
      if (action.data.hasOwnProperty("chosenInsurer"))
        draft.chosenInsurer = action.data.chosenInsurer;
      break;
    }
    case formatActionType(SURETY_MINUTE_ACCEPT, SUCCESS): {
      if (action.data.hasOwnProperty("proposalAccepted")){
        draft.data.proposalAccepted = action.data.proposalAccepted;
        draft.data.acceptedAt = action.data.acceptedAt;      
      }
      break;
    }

    case SURETY_MINUTE_GET_FILE_ERROR:
    case formatActionType(SURETY_UPDATE_API, ERROR): {
      draft.errors = action.errors;
      break;
    }

    case SURETY_MINUTE_CHANGE:
      const { attr, value } = action;
      draft.data[attr] = value;
      break;
    default:
      return;
  }
}, initialState);

export default Minute;
