export const LIFE_INSURED_LIVES_CHANGE = "LIFE_INSURED_LIVES_CHANGE";
export const LIFE_INSURED_LIVES_VALIDATE_ERROR = "LIFE_INSURED_LIVES_VALIDATE_ERROR";
export const LIFE_INSURED_LIVES_LOCAL_VALIDATION_ERROR = "LIFE_INSURED_LIVES_LOCAL_VALIDATION_ERROR";

export const validateLifeInsuredLivesErrorMessage = (error) => {
  return {
    type: LIFE_INSURED_LIVES_VALIDATE_ERROR,
    error: error
  }
};

export const changeLifeInsuredLives = (attr, value) => {
  return {
    type: LIFE_INSURED_LIVES_CHANGE,
    attr: attr,
    value: value
  }
};

export const localValidationLifeInsuredLivesErrorMessage = (errors) => {
  return {
    type: LIFE_INSURED_LIVES_LOCAL_VALIDATION_ERROR,
    errors: errors
  }
};