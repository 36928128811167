import Request from "util/Request";

export * from "./initialData";
export * from "./generalData";
export * from "./routesData";
export * from "./coverages";
export * from "./insured";
export * from "./goods";
export * from "./quotations";
export * from "./operational";
export * from "./historic";

//Order Action Types
export const INITIALIZE_OPEN_POLICY_DATA = "INITIALIZE_OPEN_POLICY_DATA";
export const CARGO_OPEN_POLICY_UPDATE_API = "CARGO_OPEN_POLICY_UPDATE_API";

/*Order Actions*/

export const updateApiCargoOpenPolicy = (url, data, successCallback) => {
  return {
    type: CARGO_OPEN_POLICY_UPDATE_API,
    request: Request.put(url, data),
    handleSuccess: successCallback
  }
};

export const initializeOpenPolicyData = (data) => {
  return {
    type: INITIALIZE_OPEN_POLICY_DATA,
    data: data
  }
};

//Order Single Boarding Action Types
export const CARGO_OPEN_POLICY_UPDATE_API_PARTIALLY = "CARGO_OPEN_POLICY_UPDATE_API_PARTIALLY";

export const updateApiCargoOpenPolicyPartially = (code, entity, data) => {
  return {
    entity: entity,
    type: CARGO_OPEN_POLICY_UPDATE_API_PARTIALLY,
    request: Request.patch(`cargo/v1/open-policy/${code}`, {[entity]: data})
  }
};
